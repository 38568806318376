import CoverImage from "../../../images/Abra-Tallulah-Profile-Page.png";
import DividerLine from "../../../images/divider-line.png";
import { useSelector } from "react-redux";
import { Button } from "antd";
import shareIcon from "../../../images/share-line.svg";

import { useHistory } from "react-router-dom";

const ProfileCoverSection = (props: any) => {
  const {
    app: { followerCount },
  } = useSelector((state: any) => state);
  const { follwedId } = props;
  const { influencerUserInfo } = useSelector((state: any) => state?.auth);
  const history = useHistory();

  return (
    <div>
      <div className="cover-image-sec">
        <div className="cover_image_wrapper">
          <img
            src={influencerUserInfo?.coverImage || CoverImage}
            alt="profile"
            className="cover-image-container"
          />
        </div>
        {influencerUserInfo?.email && (
          <div className="profile-image-container">
            {influencerUserInfo?.imageUrl ? (
              <img
                src={influencerUserInfo?.imageUrl}
                alt="profile"
                className=""
              />
            ) : (
              <div className="latter_wrapper">{`${
                influencerUserInfo?.fullName[0]
              } ${
                influencerUserInfo?.fullName?.split(" ")?.length > 1
                  ? influencerUserInfo?.fullName?.split(" ")[
                      influencerUserInfo?.fullName?.split(" ")?.length - 1
                    ][0]
                  : ""
              }`}</div>
            )}
          </div>
        )}
      </div>
      <div className="profile-details-sec">
        <div className="profile-name">
          <h3>{influencerUserInfo?.fullName}</h3>
          <span className="email-text">@{influencerUserInfo?.userName}</span>
          <div className="followers-product-sec">
            <div className="followers-sec">
              <p>{followerCount}</p>
              <span>Followers</span>
            </div>
            <img src={DividerLine} alt="divider" />
            <div className="followers-sec">
              <p>12</p>
              <span>Products</span>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-connect-sec">
        <Button
          className="setting_btn"
          onClick={() => history?.push("/user-profile")}
        >
          {!follwedId ? "Follow" : "Un Follow"}
        </Button>
        <div className="share_wrap">
          <img src={shareIcon} alt="share" />
        </div>
      </div>
    </div>
  );
};

export default ProfileCoverSection;
