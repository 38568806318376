import fetch from "cross-fetch";
import moment from "moment";
import _ from "lodash";

import Axios from "axios";
import { handle401Error } from "../../Scenes/common";
import axios from "axios";

export const get = async function (url) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage?.getItem("token"),
    };
    const response = await Axios.get(url, { headers });
    return response.data;
  } catch (error) {
    throw new Error(
      error?.response?.data?.statusMessages?.[0] ||
        error?.response?.data?.errors?.[0]
    );
  }
};
export const put = async function (url, body = {}) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    };
    const response = await Axios.put(url, body, { headers });
    return response.data;
  } catch (error) {
    throw new Error(
      error?.response?.data?.statusMessages?.[0] ||
        error?.response?.data?.errors?.[0]
    );
  }
};
export const post = async function (url, body = {}, secName = "") {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    };
    const response = await Axios.post(url, body, { headers });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message == "Unauthenticated.") {
      handle401Error();
    }
    throw new Error(
      error?.response?.data?.statusMessages?.[0] ||
        error?.response?.data?.errors?.[0]
    );
  }
};
export const del = async function (url, body = {}) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    };
    const response = await Axios.delete(url, { headers });
    return response.data;
  } catch (error) {
    throw new Error(error?.response?.data?.statusMessages?.[0]);
  }
};

const getToken = () => {
  const dNAme = "user";
  //user
  const accessToken =
    localStorage.getItem(dNAme) &&
    JSON.parse(localStorage.getItem(dNAme)) &&
    JSON.parse(localStorage.getItem(dNAme))?.token
      ? JSON.parse(localStorage.getItem(dNAme)).token
      : undefined;
  return accessToken;
  // return "12855|CpCE51PAwEAdzyufuTonvlSmlGYwz07CW4OA12bN";
};
const getTokenPost = (secName) => {
  const dNAme = secName === "extension" ? "extensionUser" : "user";
  //user
  const accessToken =
    localStorage.getItem(dNAme) &&
    JSON.parse(localStorage.getItem(dNAme)) &&
    JSON.parse(localStorage.getItem(dNAme))?.token
      ? JSON.parse(localStorage.getItem(dNAme)).token
      : undefined;
  return accessToken;
  // return "12855|CpCE51PAwEAdzyufuTonvlSmlGYwz07CW4OA12bN";
};

export const uploadImageToSignedUrl = async (url, img, contentType) => {
  let response = {};
  try {
    response = await axios.put(url, img, {
      headers: {
        "Content-Type": `${contentType}`, // Ensure the correct content type is set
      },
    });
  } catch {
    console.log("Somethings went wrong");
  }
  return response;
};

export const ensureProtocol = (url) => {
  try {
    // Decode URL in case it's encoded
    const decodedUrl = decodeURIComponent(url);

    // Check if it already starts with a valid protocol
    if (/^(https?:\/\/)/.test(decodedUrl)) {
      return decodedUrl;
    }

    // If no protocol, prepend "https://" as default
    return `https://${decodedUrl}`;
  } catch (error) {
    console.error("Invalid URL Encoding", error);
    return url; // Return original if decoding fails
  }
};

export const getUrlSlug = (name = "") => {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9\s-]/g, "") // Remove non-alphanumeric characters
    .trim()
    .replace(/\s+/g, "-"); // Replace spaces with dashes
};
