import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductDetailsList } from "../../redux/slices/app/appService";
import { RootState } from "../../redux/store";
import ProductCard from "../ProductCard/ProductCard";
import { ProductStyled } from "./ProductsStyled.styled";
import { ReactComponent as BackIcon } from "../../images/left-arrow.svg";

import { Pagination, Select } from "antd";
import { useHistory, useParams } from "react-router-dom";
import ProductCardLoader from "./ProductLoader";

const Products = () => {
  const { productDetails, productDetailsCount = 0 } = useSelector(
    (state: RootState) => state.app
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { id }: any = useParams();

  const [pageNo, setPageNo] = useState(1);
  const [loading, setIsLoading] = useState(false);
  const [sortBy, setSortBY] = useState("priceHtoL");

  useEffect(() => {
    handleFetchProductsData();
  }, [pageNo, sortBy]);
  const handleFetchProductsData = async () => {
    await setIsLoading(true);
    await dispatch(
      getProductDetailsList({ id: id, pageNumber: pageNo, sort: sortBy })
    );
    await setIsLoading(false);
  };

  const handlePageChange = (page: any) => {
    setPageNo(page);
  };

  return (
    <ProductStyled>
      <div
        className="back-sec"
        onClick={() => {
          history.goBack();
        }}
      >
        <BackIcon />
        Back
      </div>
      <h2 className="product-deals-heading">All Products Deals</h2>
      <div className="filer-sort-sec">
        {/* <div className="filter-sec">
          <Button>
            Filters
            <FilterIcon />
          </Button>
        </div> */}
        <div className="sort-sec">
          <label>Sort :</label>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="label"
            onChange={(val) => setSortBY(val)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={[
              {
                value: "priceLtoH",
                label: "Price: Low to High",
              },
              {
                value: "priceHtoL",
                label: "Price: Hight to Low",
              },
            ]}
            value={sortBy}
          />
        </div>
      </div>
      {productDetailsCount > 0 ? (
        <>
          {!loading ? (
            <>
              <p className="products-count"> {productDetailsCount} Products</p>

              <div className="product-details-card">
                {productDetails?.map((item: any, index: number) => (
                  <div key={index}>
                    <ProductCard item={item} />
                  </div>
                ))}
              </div>
              <div className="pagination_sec">
                <Pagination
                  onChange={handlePageChange}
                  pageSize={20}
                  // defaultCurrent={6}
                  current={pageNo}
                  // onShowSizeChange={onShowSizeChange}
                  total={productDetailsCount}
                  size={`default`}
                />
              </div>
            </>
          ) : (
            <>
              <ProductCardLoader />
              <ProductCardLoader />
              <ProductCardLoader />
              <ProductCardLoader />
              <ProductCardLoader />
            </>
          )}
        </>
      ) : loading ? (
        <>
          <ProductCardLoader />
          <ProductCardLoader />
          <ProductCardLoader />
          <ProductCardLoader />
          <ProductCardLoader />
        </>
      ) : (
        <div className="no-results">No Results</div>
      )}
    </ProductStyled>
  );
};

export default Products;
