import styled from "styled-components";

import BackGroundImage from "../../images/offers-Pattern.png";

export const ProductDetailsStyled = styled.div`
  .brand-logo-section {
    display: flex;
    width: 300px;
    height: 180px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    border-radius: 8px;
    background: var(--Shade-0, #fff);
    box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px #d6d6d6;
    img {
      max-width: 80%;
      max-height: 75%;
    }
  }
  .shop-now-button {
    color: var(--Videoshops-Purple, #762da0);
    font-family: "AvenirNextMedium";
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.028px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: #762da0;
      }
    }
  }
  .brand-heading {
    color: var(--videoshops-com-black, var(--color-black-solid, #000));
    font-family: "AvenirNextMedium";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;
    background: linear-gradient(90deg, #5f00a3 0.02%, #ff8000 10.09%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .offers-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .offer-card {
    border-radius: 11px;
    background: var(--Purple, #5f00a3);
    padding: 16px;
    background-image: url(${BackGroundImage});
    img {
      background: #fff;
      border-radius: 8px;
      height: 32px;
    }
    .offer-details-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .shop-now-button {
      border-radius: 10px;
      background: var(--color-white-solid, #fff);
      padding: 8px 24px;
      display: flex;
      align-items: center;
      gap: 4px;
      color: var(--Videoshops-Purple, #762da0);
      font-family: "AvenirNextSemiBold";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.024px;
      text-transform: uppercase;
      width: 154px;
      min-width: 154px;
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: #762da0;
        }
      }
    }
  }
  .offer-details {
    h4 {
      color: #fd0;
      font-family: "AvenirNextSemiBold";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 122.222% */
      margin-top: 17px;
    }
    p {
      color: #fff;
      font-family: "AvenirNextRegular";
      font-size: 15.375px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 143.089% */
    }
  }
  .banner-heading {
    color: var(--videoshops-com-black, var(--color-black-solid, #000));
    font-family: "AvenirNextMedium";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;
    background: linear-gradient(90deg, #5f00a3 0.02%, #ff8000 10.09%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .browse-products-button {
    border-radius: 18px;
    background: var(--Videoshops-Purple, #762da0);
    margin-top: 32px;
    color: var(--Shade-0, #fff);
    font-family: "AvenirNextSemiBold";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.032px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 32px;
    cursor: pointer;
  }
`;
