import styled from "styled-components";

export const ProductStyled = styled.div`
  .product-details-card {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 26px;
  }
  .back-sec {
    color: var(--Black, #000);
    font-family: "AvenirNextMedium";
    font-size: 20px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 32px;
    cursor: pointer;
  }
  .product-deals-heading {
    color: var(--www-target-com-mine-shaft, #333);
    font-family: "AvenirNextSemiBold";
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px; /* 134.615% */
    margin-top: 32px;
  }
  .filer-sort-sec {
    display: flex;
    justify-content: end;
    margin-top: 40px;
    margin-bottom: 24px;
  }
  .products-count {
    color: #000;
    font-family: "AvenirNextSemiBold";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
  }
  .pagination_sec {
    display: flex;
    justify-content: center;
    margin: 48px 0 72px;
  }
  .no-results {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-family: "AvenirNextMedium";
    padding: 16px;
    background-color: rgb(242, 242, 242);
    margin: 72px auto;
    max-width: 237px;
    border-radius: 8px;
  }
  .sort-sec {
    border-radius: 36px;
    border: 1px solid var(--Neutral-300, #cfd3d7);
    background: var(--Shade-0, #fff);
    padding: 4px 13px;
    cursor: pointer;
    label {
      color: var(--Gray-3, #828282);
      font-family: "AvenirNextRegular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      margin-bottom: 0px;
    }
    .ant-select-selector {
      border: none;
      box-shadow: none !important;
      border-color: #fff !important;
    }
  }
`;
