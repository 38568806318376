import WishListProductCard from "./WishListProductCard";
import { WishListProductsStyled } from "./WishListProductsStyled.styled";
import { ReactComponent as LeftArrow } from "../../images/left-arrow.svg";

const WishListProducts = ({
  updatedProducts,
  setShoProducts,
  title,
  wishlistId,
}: any) => {
  return (
    <WishListProductsStyled>
      <div>
        <header className="wishlist-name-header">
          <h2 className="wishlist-name" onClick={() => setShoProducts(false)}>
            <LeftArrow />
            {title}
          </h2>
        </header>
        <section>
          {updatedProducts.map((item: any) => (
            <WishListProductCard
              item={item}
              wishlistId={wishlistId}
              setShoProducts={setShoProducts}
            />
          ))}
        </section>
      </div>
    </WishListProductsStyled>
  );
};

export default WishListProducts;
