import styled from "styled-components";

export const LoginModalStyled = styled.div`
  .deal-green-sec {
    display: flex;
    background: #35ab26;
    align-items: center;
    padding: 10px;
    justify-content: center;
    gap: 12px;
    p {
      font-family: "AvenirNextRegular";
      font-size: 25px;
      font-weight: 400;
      line-height: 33px;
      text-align: left;
      margin-bottom: 0px;
      color: #fff;
    }
  }
  .deal-button-sec {
  }
`;
