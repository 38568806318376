import styled from "styled-components";
import DefaultProfileImage from "../../Utills/DefaultProfile";
import { getRelativeTime } from "../../Utills/utils";

const MobileVideoTitle = ({ videoDetails }: any) => {
  return (
    <MobileVideoTitleStyled>
      <div className="mobile-video-tile-sec">
        <div>
          <section>
            <div className="mobile-profile-details-sec">
              {videoDetails?.CreatorDetails?.imageUrl ? (
                <img
                  src={videoDetails?.CreatorDetails?.imageUrl}
                  alt="profile"
                />
              ) : (
                <DefaultProfileImage
                  userName={videoDetails?.CreatorDetails?.userName}
                  fullName={videoDetails?.CreatorDetails?.fullName}
                  email={videoDetails?.CreatorDetails?.email}
                />
              )}

              <p className="proflie-name mobile-profile-name">
                {videoDetails?.CreatorDetails?.Name}
                <div className="posted-time mobile-pasted-time">
                  {getRelativeTime(videoDetails?.CreatedAt)}
                </div>
              </p>
              {/* <button>
            Follow
            <img src={PlusIcon} alt="add-icon" />
          </button> */}
            </div>
          </section>
          <div className="product-header mobile-product-header">
            <h2>{videoDetails?.VideoTitle}</h2>
          </div>
        </div>
      </div>
    </MobileVideoTitleStyled>
  );
};

export default MobileVideoTitle;

export const MobileVideoTitleStyled = styled.div`
  .mobile-video-tile-sec {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 28px;
    width: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6979385504201681) 50%,
      rgba(0, 0, 0, 1) 100%
    );
  }
  .mobile-profile-details-sec img {
    border: 1px solid #ffffff;
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }
  .proflie-name.mobile-profile-name {
    color: #fff;
    font-family: "AvenirNextMedium";
    font-size: 18px;
    font-weight: 450;
    line-height: 22.05px;
    text-align: left;
    margin-bottom: 0px;
  }
  .posted-time.mobile-pasted-time {
    color: #fff;
    font-family: "AvenirNextRegular";
    font-size: 10px;
    font-weight: 400;
    line-height: 12.25px;
    text-align: left;
    padding-top: 4px;
  }
  .product-header.mobile-product-header h2 {
    font-family: "AvenirNextMedium";
    font-size: 14px;
    font-weight: 450;
    line-height: 20px;
    text-align: left;
    color: #fff;
  }
  .mobile-product-header {
    margin-bottom: 80px;
  }
  .mobile-profile-details-sec {
    display: flex;
    gap: 24px;
    margin-bottom: 9px;
  }
`;
