import React, { useEffect, useState } from "react";
import { LoginStyled } from "./LoginStyled";
import { ReactComponent as HomeLogo } from "../../images/OnlyDrops.svg";
import { useHistory, useLocation } from "react-router-dom";
import { loginUser, signUpUser } from "../../redux/slices/auth/authService";
import { useDispatch } from "react-redux";
import GoogleLogo from "../../images/google-color.svg";
import EyeHide from "../../images/eye-hide-icon.svg";
import EyeOpen from "../../images/openEye.svg";
import { Button } from "antd";
const Login = (props: any) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isSignUpPage, setIsSignPage] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [isAccepted, setIsAccepted] = useState(false);
  const [isEnable, setIsEnable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState(""); // For email validation errors

  const history = useHistory();

  const { urlPath, setUrlPath, redirectionUrl, onHide } = props;

  useEffect(() => {
    if (redirectionUrl) {
      if (email && !emailErrorMessage) {
        setIsEnable(true);
      }
    } else {
      if (isSignUpPage) {
        if (email && password && !emailErrorMessage && isAccepted) {
          setIsEnable(true);
        } else {
          setIsEnable(false);
        }
      } else {
        if (email && !emailErrorMessage && password) {
          setIsEnable(true);
        } else {
          setIsEnable(false);
        }
      }
    }
  }, [email, password, isAccepted]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);

    // Validate email on change
    if (!validateEmail(value)) {
      setEmailErrorMessage("Please enter a valid email address.");
    } else {
      setEmailErrorMessage(""); // Clear error message if valid
    }
  };

  const handleLogin = (e: any) => {
    setIsLoading(true);
    e.preventDefault();

    // Prevent form submission if email is invalid
    if (emailErrorMessage) return;

    if (isSignUpPage) {
      handleSignUpUser();
    } else {
      handleSignInUser();
    }
    setIsLoading(false);
  };

  const location = useLocation();
  const currentPath = location.pathname;

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentPath === "/sign-up" || urlPath === "/sign-up") {
      setIsSignPage(true);
    } else {
      setIsSignPage(false);
    }
  }, [currentPath, urlPath]);

  const handleSignInUser = async () => {
    let json = {
      email: email,
      password: password,
    };
    let res: any = await dispatch(loginUser(json));

    if (res.payload) {
      // Handle success response here
      setLoginErrorMessage("");
      history.push("/home");
    } else {
      setLoginErrorMessage(res.error.message);
    }
  };

  const handleSignUpUser = async () => {
    let json = {
      email: email,
      password: password,
      type: "ED",
    };
    let res: any = await dispatch(signUpUser(json));
    if (res.payload.success) {
      setLoginErrorMessage("");
      history.push("/home");
    }
    if (res.payload.error) {
      setLoginErrorMessage(res.payload.error);
    }
  };

  return (
    <LoginStyled>
      <div className="login-container">
        <div className="logo-container">
          <HomeLogo />
        </div>
        {!redirectionUrl && (
          <h2>
            {isSignUpPage
              ? "Let's Get Started – Sign Up in a Snap!"
              : "Welcome Back to OnlyDrop"}
          </h2>
        )}
        {!redirectionUrl && (
          <p>
            {isSignUpPage
              ? "Please fill the required information to Sign Up."
              : "Please enter details to sign in."}
          </p>
        )}
        <form>
          <div className="form-group">
            <label>
              Email Address<span>*</span>
            </label>
            <input
              type="email"
              value={email}
              onChange={handleEmailChange} // Call handleEmailChange here
              placeholder="Enter Email Address"
              required
              autoComplete="off"
            />
            {emailErrorMessage && (
              <span className="email-error-message">{emailErrorMessage}</span>
            )}
          </div>
          {!redirectionUrl && (
            <>
              <div className="form-group">
                <label>
                  Password<span>*</span>
                </label>
                <div className="password-container">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter Password"
                    required
                  />
                  <span
                    className="toggle-password"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? (
                      <img src={EyeOpen} alt="eye-hide" />
                    ) : (
                      <img src={EyeHide} alt="eye-hide" />
                    )}
                  </span>
                </div>
              </div>
              {loginErrorMessage && (
                <div className="sign-up-error">{loginErrorMessage}</div>
              )}
              {isSignUpPage && (
                <div className="terms-container">
                  <input
                    type="checkbox"
                    id="agree"
                    checked={isAccepted}
                    onChange={(e: any) => setIsAccepted(e?.target?.checked)}
                  />
                  <label>
                    By creating an account you agree to the{" "}
                    <a href="#">Terms & Conditions</a> and our{" "}
                    <a href="#">Privacy policy</a>.
                  </label>
                </div>
              )}
              {/* {!isSignUpPage && (
                <div className="forgot-password">
                  <a href="/forgot-password">Forgot Password?</a>
                </div>
              )} */}
            </>
          )}
          <Button
            loading={isLoading}
            onClick={(e: any) => {
              if (redirectionUrl) {
                window.open(redirectionUrl);
                onHide();
              } else {
                handleLogin(e);
              }
            }}
            disabled={!isEnable}
            className="login-button"
          >
            {redirectionUrl
              ? "Submit & Grab Deal"
              : isSignUpPage
              ? "Sign up"
              : "Sign In"}
          </Button>
        </form>
        {!redirectionUrl && (
          <>
            <div className="social-login">
              {/* <div className="logo-container sign-up-text">
                <div className="divider-line"></div>
                <p>{isSignUpPage ? "Or Sign Up with" : "Or Sign In with"}</p>
                <div className="divider-line"></div>
              </div> */}
              <div className="social-icons">
                {/* <button className="social-button google">
                  <img src={GoogleLogo} alt="GoogleLogo" />
                  Google
                </button> */}
                {/* <button className="social-button apple">
              <img src={AppleLogo} alt="AppleLogo" />
              Apple
            </button>
            <button className="social-button facebook">
              <img src={FaceBooklogo} alt="FaceBooklogo" />
              Facebook
            </button> */}
              </div>
            </div>
            <div className="signup-link">
              <div className="logo-container">
                {isSignUpPage ? (
                  <p>
                    Already have an account?{" "}
                    {urlPath ? (
                      <a
                        onClick={() => {
                          setUrlPath("/login");
                        }}
                      >
                        {" "}
                        Sign In
                      </a>
                    ) : (
                      <a href="/login">Sign In</a>
                    )}
                  </p>
                ) : (
                  <p>
                    Don’t have an account?{" "}
                    {urlPath ? (
                      <a
                        onClick={() => {
                          setUrlPath("/sign-up");
                        }}
                      >
                        {" "}
                        Sign up
                      </a>
                    ) : (
                      <a href="/sign-up">Sign up</a>
                    )}
                  </p>
                )}
              </div>
            </div>
          </>
        )}
        {redirectionUrl && (
          <div className="deal-button-sec mt-4">
            <button
              onClick={() => {
                window.open(redirectionUrl);
              }}
            >
              Skip Deal
            </button>
            <p>
              If you skip {!redirectionUrl && "Sign in"}, you will miss the Gift
              Card
            </p>
          </div>
        )}
      </div>
    </LoginStyled>
  );
};

export default Login;
