import styled from "styled-components";

const DefaultProfileImage = ({ className, fullName, userName, email }: any) => {
  const uName = fullName || userName || email;
  const lName =
    fullName?.split(" ")[1] || uName?.split(" ")[1] || email?.split("")[1];
  return (
    <>
      <StyledDefaultProfileImage className="letter_wrap">
        <div className={`first-letter-text ${className}`}>
          {uName?.length > 0 ? uName[0] : ""}
          {""}
          {lName?.length > 0 ? lName[0] : ""}
        </div>
      </StyledDefaultProfileImage>
    </>
  );
};

export default DefaultProfileImage;

export const StyledDefaultProfileImage = styled.div`
  .first-letter-text {
    background: #762da0;
    padding: 12px;
    border-radius: 69px;
    width: 50px;
    height: 50px;
    text-align: center;
    color: #fff;
    font-family: "AvenirNextMedium";
    text-transform: uppercase;
    cursor: pointer;
  }
`;
