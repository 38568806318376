import styled from "styled-components";
import { ReactComponent as BackIcon } from "../../images/left-arrow.svg";
import { useHistory } from "react-router-dom";

const AboutUs = () => {
  const history = useHistory();
  return (
    <AboutUsStyled>
      <header
        className="about-us-header"
        onClick={() => {
          history.goBack();
        }}
      >
        <BackIcon />
        About Us
      </header>
      <section>
        <p>
          Discover a new way to shop with OnlyDrops. Watch authentic product
          reviews, engage with your favorite influencers, and enjoy exclusive
          offers tailored just for you. Whether you’re looking for the latest
          trends or trusted recommendations, OnlyDrops makes shopping fun and
          easy.
        </p>
        <ul>
          <li>
            <span>Shop Smarter:</span> Explore product reviews and
            recommendations from influencers you trust.
          </li>
          <li>
            <span>Exclusive Deals:</span> Access special offers and giveaways
            directly from the videos.
          </li>
          <li>
            <span>Stay Connected:</span> Follow your favorite influencers and
            stay updated on their latest content.
          </li>
        </ul>
        <p>
          With OnlyDrops, you’re not just shopping—you’re discovering products
          through authentic stories and shared experiences.
        </p>
      </section>
    </AboutUsStyled>
  );
};

export default AboutUs;

export const AboutUsStyled = styled.div`
  .about-us-header {
    display: flex;
    margin: 28px 0px 28px;
    gap: 8px;
    align-items: center;
    color: var(--Black, #000);
    font-family: "AvenirNextMedium";
    font-size: 20px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    cursor: pointer;
  }
  section {
    margin-bottom: 120px;
  }
  section p {
    color: var(--www-videoshops-com-black, var(--color-black-solid, #000));
    font-family: "AvenirNextRegular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-bottom: 20px;
  }
  section li span {
    font-family: AvenirNextBold;
  }
  section li,
  section li span {
    line-height: 24px; /* 150% */
    font-size: 16px;
  }
  section ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;
