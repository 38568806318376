import ContentLoader from "react-content-loader";
import { ContentLoaderSectionStyled } from "./ContentLoaderSection.styled";
const ContentLoaderSection = (props: any) => {
  const fromPage = props?.fromPage;
  const heightVal =
    fromPage === "followPage"
      ? 600
      : fromPage === "marketplaceliveauctions"
      ? 500
      : fromPage === "biddinginfo"
      ? 250
      : 775;
  const viewBoxVal =
    fromPage === "followPage"
      ? "0 0"
      : fromPage === "biddinginfo"
      ? "0 0 500 250"
      : "0 0 800 575";
  return (
    <>
      <ContentLoaderSectionStyled>
        <ContentLoader
          width={fromPage === "biddinginfo" ? 500 : 1300}
          height={heightVal}
          viewBox={viewBoxVal}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          className="loadingContent"
        >
          {fromPage === "liveauctions" ? (
            <>
              <rect x="12" y="58" rx="2" ry="2" width="260" height="271" />
              <rect x="285" y="57" rx="2" ry="2" width="260" height="271" />
              <rect x="557" y="56" rx="2" ry="2" width="260" height="271" />
              <rect x="12" y="343" rx="2" ry="2" width="260" height="271" />
              <rect x="285" y="341" rx="2" ry="2" width="260" height="271" />
              <rect x="557" y="349" rx="2" ry="2" width="260" height="271" />
            </>
          ) : fromPage === "marketplaceliveauctions" ? (
            <>
              <rect x="12" y="58" rx="2" ry="2" width="260" height="271" />
              <rect x="285" y="57" rx="2" ry="2" width="260" height="271" />
              <rect x="557" y="56" rx="2" ry="2" width="260" height="271" />
              <rect x="12" y="343" rx="2" ry="2" width="260" height="271" />
              <rect x="285" y="341" rx="2" ry="2" width="260" height="271" />
              <rect x="557" y="349" rx="2" ry="2" width="260" height="271" />
            </>
          ) : fromPage === "biddinginfo" ? (
            <>
              <rect x="50" y="6" rx="4" ry="4" width="443" height="38" />
              <rect x="8" y="6" rx="4" ry="4" width="35" height="38" />
              <rect x="50" y="55" rx="4" ry="4" width="443" height="38" />
              <rect x="8" y="55" rx="4" ry="4" width="35" height="38" />
              <rect x="50" y="104" rx="4" ry="4" width="443" height="38" />
              <rect x="8" y="104" rx="4" ry="4" width="35" height="38" />

              <rect x="50" y="154" rx="4" ry="4" width="443" height="38" />
              <rect x="8" y="154" rx="4" ry="4" width="35" height="38" />
            </>
          ) : fromPage === "pdp" ? (
            <>
              <circle cx="472" cy="159" r="7" />
              <rect x="487" y="154" rx="5" ry="5" width="220" height="10" />
              <circle cx="472" cy="190" r="7" />
              <rect x="487" y="184" rx="5" ry="5" width="220" height="10" />
              <circle cx="472" cy="219" r="7" />
              <rect x="487" y="214" rx="5" ry="5" width="220" height="10" />
              <circle cx="472" cy="249" r="7" />
              <rect x="487" y="244" rx="5" ry="5" width="220" height="10" />
              <rect x="64" y="18" rx="0" ry="0" width="250" height="400" />
              <rect x="229" y="300" rx="0" ry="0" width="0" height="0" />
              <rect x="111" y="340" rx="0" ry="0" width="0" height="0" />
              <rect x="121" y="342" rx="0" ry="0" width="0" height="0" />
              {/* <rect x="10" y="20" rx="0" ry="0" width="40" height="44" />
              <rect x="10" y="80" rx="0" ry="0" width="40" height="44" />
              <rect x="10" y="140" rx="0" ry="0" width="40" height="44" /> */}
              <rect x="194" y="329" rx="0" ry="0" width="0" height="0" />
              <rect x="192" y="323" rx="0" ry="0" width="0" height="0" />
              <rect x="185" y="323" rx="0" ry="0" width="0" height="0" />
              {/* <rect x="10" y="200" rx="0" ry="0" width="40" height="44" /> */}
              <rect x="470" y="18" rx="0" ry="0" width="300" height="25" />
              <rect x="470" y="58" rx="0" ry="0" width="300" height="6" />
              <rect x="470" y="68" rx="0" ry="0" width="300" height="6" />
              <rect x="470" y="78" rx="0" ry="0" width="300" height="6" />
              <rect x="798" y="135" rx="0" ry="0" width="0" height="0" />
              <rect x="731" y="132" rx="0" ry="0" width="0" height="0" />
              <rect x="470" y="99" rx="0" ry="0" width="70" height="30" />
              <rect x="560" y="99" rx="0" ry="0" width="70" height="30" />
            </>
          ) : fromPage === "list" ? (
            <>
              <rect x="27" y="139" rx="4" ry="4" width="20" height="20" />
              <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
              <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
              <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
              <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />
              <rect x="731" y="139" rx="10" ry="10" width="85" height="19" />
              <rect x="852" y="138" rx="10" ry="10" width="85" height="19" />
              <rect x="1424" y="137" rx="10" ry="10" width="68" height="19" />
              <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
              <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
              <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
              <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
              <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
              <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
              <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />
              <circle cx="1456" cy="203" r="12" />
              <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
              <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
              <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
              <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
              <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
              <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
              <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />
              <circle cx="1456" cy="265" r="12" />
              <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
              <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
              <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
              <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
              <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
              <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
              <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />
              <circle cx="1456" cy="323" r="12" />
              <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
              <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
              <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
              <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
              <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />
              <rect x="730" y="379" rx="10" ry="10" width="85" height="19" />
              <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />
              <circle cx="1456" cy="386" r="12" />
              <rect x="978" y="138" rx="10" ry="10" width="169" height="19" />
              <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />
              <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />
              <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />
              <rect x="977" y="378" rx="10" ry="10" width="169" height="19" />
              <rect x="1183" y="139" rx="10" ry="10" width="85" height="19" />
              <rect x="1182" y="196" rx="10" ry="10" width="85" height="19" />
              <rect x="1182" y="258" rx="10" ry="10" width="85" height="19" />
              <rect x="1182" y="316" rx="10" ry="10" width="85" height="19" />
              <rect x="1182" y="379" rx="10" ry="10" width="85" height="19" />
              <rect x="1305" y="137" rx="10" ry="10" width="85" height="19" />
              <rect x="1304" y="194" rx="10" ry="10" width="85" height="19" />
              <rect x="1304" y="256" rx="10" ry="10" width="85" height="19" />
              <rect x="1304" y="314" rx="10" ry="10" width="85" height="19" />
              <rect x="1304" y="377" rx="10" ry="10" width="85" height="19" />
              {/* <circle cx="37" cy="97" r="11" />
              <rect x="26" y="23" rx="5" ry="5" width="153" height="30" />
              <circle cx="1316" cy="88" r="11" />
              <rect x="1337" y="94" rx="0" ry="0" width="134" height="3" />
              <circle cx="77" cy="96" r="11" /> */}
            </>
          ) : fromPage === "followPage" ? (
            <>
              <rect x="120" y="6" rx="4" ry="4" width="1000" height="150" />
              {/* <rect x="8" y="6" rx="4" ry="4" width="35" height="38" /> */}
              <rect x="120" y="167" rx="4" ry="4" width="1000" height="150" />
              {/* <rect x="8" y="55" rx="4" ry="4" width="35" height="38" /> */}
              <rect x="120" y="330" rx="4" ry="4" width="1000" height="150" />
              {/* <rect x="8" y="104" rx="4" ry="4" width="35" height="38" /> */}
            </>
          ) : (
            <>
              <rect x="537" y="9" rx="2" ry="2" width="140" height="10" />
              <rect x="14" y="30" rx="2" ry="2" width="667" height="11" />
              <rect x="12" y="58" rx="2" ry="2" width="211" height="211" />
              <rect x="240" y="57" rx="2" ry="2" width="211" height="211" />
              <rect x="467" y="56" rx="2" ry="2" width="211" height="211" />
              <rect x="12" y="283" rx="2" ry="2" width="211" height="211" />
              <rect x="240" y="281" rx="2" ry="2" width="211" height="211" />
              <rect x="468" y="279" rx="2" ry="2" width="211" height="211" />
              <circle cx="286" cy="536" r="12" />
              <circle cx="319" cy="535" r="12" />
              <circle cx="353" cy="535" r="12" />
              <rect x="378" y="524" rx="0" ry="0" width="52" height="24" />
              <rect x="210" y="523" rx="0" ry="0" width="52" height="24" />
              <circle cx="210" cy="535" r="12" />
              <circle cx="428" cy="536" r="12" />
            </>
          )}
        </ContentLoader>
      </ContentLoaderSectionStyled>
    </>
  );
};

export default ContentLoaderSection;
