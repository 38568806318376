import DefaultImage from "../../images/default-image.svg";
import CameraIcon from "../../images/camera-icon.svg";
import { Button, Col } from "react-bootstrap";
import PrimaryButton from "../../components/Common/PrimaryButton/PrimaryButton";
import ConfirmationModal from "../../components/Common/ConfirmationModal/ConfirmationModal";
import { useEffect, useState } from "react";
import ResetModal from "./ResetModal/ResetModal";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getUserDetailsById,
  updateUserDetails,
} from "../../redux/slices/auth/authService";
import ImageUploadModel from "../../components/Common/ImageUploadModel/ImageUploadModel";
import { S3_BUCKET } from "../../config";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import useIsMobile from "../../Utills/CheckIsMobile";
import { ReactComponent as RightIcon } from "../../images/left-arrow.svg";
import { UserProfileStyled } from "./UserProfileStyled.styled";
import { useHistory } from "react-router-dom";

const AccontSettings = () => {
  const {
    auth: { user },
  } = useSelector((state: any) => state);
  const [isConfirmSaveChanges, setIsConfirmSaveChanges] = useState(false);
  const [isRestModalOpen, setIsResetModalOpen] = useState(false);
  const [values, setValues]: any = useState({}); // State for date of birth
  const [isLoading, setIsLoading] = useState(false);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [startDate, setStartDate]: any = useState(null);

  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const history = useHistory();

  useEffect(() => {
    // Parse the API date and set it in state
    if (user || user?.dob) {
      setValues({
        ...user,
        dob: moment(user?.dob), // Convert ISO string to moment object
      });
    }
  }, []);

  const handleModalClose = () => {
    setIsConfirmSaveChanges(!isConfirmSaveChanges);
  };

  const handleChangeValue = (field: string, value: string) => {
    setValues({ ...values, [field]: value });
  };

  const handleDateChange = (date: any) => {
    handleChangeValue("dob", date);
    setStartDate(date);
  };

  const updateUserDetailsCall = async () => {
    setIsLoading(true);
    const payload = {
      ...values,
      _id: user?._id,
    };
    let res: any = await dispatch(updateUserDetails(payload));
    if (res.payload.success) {
      await dispatch(getUserDetailsById({ id: user?._id }));
    }
    setIsLoading(false);
  };

  const handleCloseImage = () => {
    setShowUploadImage(false);
  };

  const handleImageUploadSuccess = async (imgName: any) => {
    handleCloseImage();
    const prevImage1 = `https://${S3_BUCKET}.s3.amazonaws.com/${imgName}`;
    handleChangeValue("imageUrl", prevImage1);
  };

  return (
    <UserProfileStyled>
      <ImageUploadModel
        show={showUploadImage}
        handleClose={handleCloseImage}
        handleImageUploadSuccess={handleImageUploadSuccess}
      />
      <ConfirmationModal
        modalHeader={"Profile Updated Successfully!"}
        show={isConfirmSaveChanges}
        onHide={handleModalClose}
        description="Your profile changes have been saved Successfully."
        confirmLabel="Okay"
        onConfirmClick={() => {
          handleModalClose();
        }}
      />
      <ResetModal isOpen={isRestModalOpen} />
      {isMobile && (
        <div
          className="mobile-acc-setting-header"
          onClick={() => history.goBack()}
        >
          <RightIcon />
          <h2>Account Settings</h2>
        </div>
      )}
      <div className="account-settings-sec">
        <h2>Account Settings</h2>
        <div className="profile-image-sec">
          <div className="profile-img-div">
            {values?.imageUrl ? (
              <img
                src={values?.imageUrl}
                alt="defaultimage"
                className="profile-image"
              />
            ) : (
              <img
                src={DefaultImage}
                alt="defaultimage"
                className="profile-image"
              />
            )}
            <img
              src={CameraIcon}
              alt="camera"
              className="camera-icon"
              onClick={() => {
                setShowUploadImage(true);
              }}
            />
          </div>
        </div>
        <div className="row account-form">
          <Col md={6} className="input_field">
            <label>Full Name</label>
            <input
              type="text"
              placeholder="Enter Full Name"
              value={values?.fullName}
              maxLength={60}
              onChange={(e: any) =>
                handleChangeValue("fullName", e.target.value)
              }
            ></input>
          </Col>
          <Col md={6} className="input_field">
            <label className="email-input">User Name</label>
            <input
              type="text"
              placeholder="Enter User Name"
              value={values?.userName}
              maxLength={60}
              onChange={(e: any) =>
                handleChangeValue("userName", e.target.value)
              }
            ></input>
          </Col>

          <Col md={6} className="input_field">
            <label className="email-input">Email Address*</label>
            <input
              type="text"
              placeholder="Enter Email Address"
              value={values?.email}
              disabled
            ></input>
          </Col>
          <Col md={6} className="input_field">
            <label>Date of Birth</label>
            <ReactDatePicker
              selected={moment.utc(values.dob).toDate() || startDate || null}
              //@ts-ignore
              value={moment.utc(values.dob).toDate() || startDate || null}
              name="dateOfBirth"
              placeholderText={"Enter Date of Birth"}
              autoComplete="off"
              onChange={handleDateChange}
              // peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              // maxDate={moment().subtract(18, "years")}
              // minDate={moment().subtract(100, "years")}
              dateFormat="MM/dd/yyyy"
              onKeyDown={(e: any) => {
                e.preventDefault();
              }}
              onYearChange={handleDateChange}
              onMonthChange={handleDateChange}
              isClearable={startDate}
              required
            />
          </Col>
          <Col md={6} className="input_field">
            <label>Phone Number</label>
            <input
              type="text"
              placeholder="Enter Phone Number"
              value={values?.phoneNumber}
              maxLength={10}
              onChange={(e: any) => {
                const numericValue = e.target.value.replace(/\D/g, "");
                handleChangeValue("phoneNumber", numericValue);
              }}
            ></input>
          </Col>
        </div>
        <div className="reset-pass-butt">
          <Button
          // onClick={() => {
          //   setIsResetModalOpen(true);
          // }}
          >
            Reset Password
          </Button>
        </div>
        <div className="save-changes-but">
          <PrimaryButton
            label="Save Changes"
            onClick={() => {
              setIsConfirmSaveChanges(true);
              updateUserDetailsCall();
            }}
          ></PrimaryButton>
        </div>
      </div>
    </UserProfileStyled>
  );
};

export default AccontSettings;
