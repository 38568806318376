import React from "react";
import PrimaryButton from "../../components/Common/PrimaryButton/PrimaryButton";
import BannerImage from "../../images/END USER GIF.gif";
import { BannerStyled } from "./BannerStyled.styled";

const Banner: React.FC = () => {
  return (
    <BannerStyled>
      <div className="banner-sec">
        <div className="banner-left-section">
          <h1>Discover Real Reviews from Influencers You Love! </h1>
          <p>
            Explore exclusive deals and trusted reviews from your favorite
            influencers. Discover top-rated products, influencer-curated offers,
            and recommended brands—all in one place.
          </p>
          <div className="banner-button display-hidden">
            <PrimaryButton label="Browse Creators" onClick={() => {}} />
            <PrimaryButton label="Sell" onClick={() => {}} />
          </div>
        </div>
        <div className="banner-right-section">
          <img src={BannerImage} alt="Banner" />
        </div>
      </div>
    </BannerStyled>
  );
};

export default Banner;
