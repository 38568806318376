import React from "react";
import { Tabs } from "antd";
import Videos from "./Videos";
import LiveStreams from "./LiveStreams";

const ProfileTabs = () => {
  const [activeKey, setActiveKey] = React.useState("1");
  const items = [
    {
      key: "1",
      label: "Videos",
      children: <Videos />,
    },
    {
      key: "2",
      label: "Live Streams",
      children: <LiveStreams />,
    },
  ];
  const onChange = (key: any) => {
    setActiveKey(key);
  };
  return (
    <Tabs
      defaultActiveKey={activeKey}
      items={items}
      onChange={(key: any) => onChange(key)}
      className="profile-tabs"
    />
  );
};

export default ProfileTabs;
