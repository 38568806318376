import styled from "styled-components";

export const FAQwrapper = styled.div`
  /* Container Styling */
  .faq-container {
  }

  /* Heading Styling */
  .faq-heading {
    font-family: "AvenirNextMedium";
    font-size: 20px;
    font-weight: 450;
    line-height: 27px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  /* FAQ Items */

  .faq-item {
    border-radius: 12px;
    border: 1px solid var(--Neutral-200, #dee1e3);
    background: #fff;
    padding: 12px 20px;
    margin-bottom: 24px;
  }

  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--Shade-100, #000);
    font-family: "AvenirNextMedium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 187.5% */
    cursor: pointer;
  }
  .active_question {
    border-bottom: 1px solid #dee1e3;
    padding-bottom: 14px;
  }

  .faq-question:hover {
    color: #7c3aed;
  }

  .faq-icon {
    width: 12px;
    height: 12px;
    border-right: 2px solid #333;
    border-bottom: 2px solid #333;
    transform: rotate(45deg);
    transition: transform 0.3s ease;
  }

  .faq-icon.open {
    transform: rotate(-135deg);
  }

  .faq-answer {
    margin-top: 14px;
    color: var(--www-videoshops-com-black, var(--color-black-solid, #000));
    font-family: "AvenirNextRegular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
`;
