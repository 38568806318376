import styled from "styled-components";
import BackGroundImage from "../../images/promotiom-offer.png";

export const VideoPdpStyled = styled.div`
  .react-player {
    max-width: 100%;
    max-height: 90dvh;
    height: auto !important;
    width: auto !important;
    aspect-ratio: 387 / 689;
    border-radius: 8px;
    margin: 0px auto;
  }
  .reel-card-container {
    position: relative;
  }
  .video-pdp-page {
    display: flex;
    gap: 48px;
    margin-top: 24px;
    font-family: Inter;
    margin-bottom: 84px;
  }
  .reel-card-container {
    display: flex;
    gap: 12px;
    align-items: end;
  }
  .reel-button-sec {
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 10px;
    .offers-icon {
      width: 52px;
      height: 52px;
    }
  }
  .reel-button-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    border-radius: 12px;
    border: 1px solid var(--color-grey-92, #ebebeb);
    background: #fff;
    padding: 13px;
    gap: 3px;
    cursor: pointer;
    span {
      color: #212121;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .like-coment-button {
    padding: 7px 13px;
  }
  .back-button-sec {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 4px;
    span {
      color: var(--Black, #000);
      font-family: Inter;
      font-size: 20px;
      font-weight: 500;
    }
  }
  .product-header h2 {
    color: var(--videoshops-com-black, var(--color-black-solid, #000));
    font-family: "AvenirNextSemiBold";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 150% */
    letter-spacing: var(--letter-spacing--0_54, -0.54px);
    margin-bottom: 20px;
  }
  .posted-time {
    font-family: "AvenirNextLight";
    color: var(--Neutral-900, #374047);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .profile-details-sec {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 20px;
    cursor: pointer;
    .proflie-name {
      color: var(--Shade-100, #000);
      font-family: "AvenirNextMedium";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 0px;
    }
    button {
      border-radius: 12px;
      background: var(--Videoshops-Red, #762da0);
      border: none;
      padding: 8px 16px;
      color: var(--Shade-0, #fff);
      font-family: "AvenirNextMedium";
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.024px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 4px;
    }
    img {
      width: 40px;
      height: 40px;
      border-radius: 50px;
      object-fit: cover;
    }
  }
  .promational-sec {
    border-radius: 8px;
    background: #fff;
    border: 1px solid #ffd900;
    padding: 19px;
    margin-top: 20px;
    background-image: url(${BackGroundImage});
    background-repeat: round;
    background-repeat: no-repeat;
    p {
      overflow: hidden;
      color: #181818;
      text-overflow: ellipsis;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      margin-bottom: 20px;
    }
    input {
      color: var(--Neutral-400, #bfc5c9);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      border-radius: 10px 0px 0px 10px;
      border: 1px solid #cfd3d7;
      background: #fff;
      padding: 8px 16px;
    }
    button {
      border-radius: 0px 10px 10px 0px;
      background: var(--Neutral-100, #eceeef);
      border: none;
      height: 42px;
      padding: 8px 24px;
    }
  }
  .product-card-sec {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
  .product-count {
    color: var(--videoshops-com-black, var(--color-black-solid, #000));
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .homepage-comments-filter {
    z-index: 9999;
    background: rgb(255, 255, 255);
    box-shadow: rgb(91, 89, 89) 0px 0px 10px 0px;
    height: 100vh;
    width: 440px !important;
  }
  .browse-products-button {
    border-radius: 18px;
    background: var(--Videoshops-Purple, #762da0);
    margin-top: 32px;
    color: var(--Shade-0, #fff);
    font-family: "AvenirNextSemiBold";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.032px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 32px;
    cursor: pointer;
  }

  .socail-login-profile {
    margin-left: 24px;
    p {
      color: var(--Shade-100, #000);
      font-family: "AvenirNextRegular";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 12px;
    }
    div {
      display: flex;
      align-items: center;
      gap: 4px;
      div {
        padding: 8px;
        border-radius: 6px;
        background: #faf1ff;
        svg {
        }
      }
    }
  }

  @media (max-width: 575.98px) {
    .video-pdp-page {
      display: block;
      margin-top: 0px;
      font-family: Inter;
      margin-bottom: 0px;
      height: calc(100vh - 108px);
    }
    .back-button-sec {
      margin: 12px 0px;
      display: block;
      padding-top: 12px;
      span {
        font-size: 16px;
      }
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
`;
