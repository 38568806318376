import React, { useEffect, useState } from "react";
import ProfileCoverSection from "./ProfileCoverSection/ProfileCoverSection";
import { ProfileStyled } from "./ProfileStyled.styled";
import ProfileTabs from "./Tabs/ProfileTabs";
import { useDispatch, useSelector } from "react-redux";
import { getInfluenserUserDetailsById } from "../../redux/slices/auth/authService";
import { useParams } from "react-router-dom";
import {
  getFollowerCheckApi,
  getFollowercountApi,
} from "../../redux/slices/app/appService";

const Profile: React.FC = () => {
  const {
    auth: { user },
  } = useSelector((state: any) => state);
  const [isFollowed, setIsFollowed] = useState(false);
  const [follwedId, setFollowedId] = useState("");
  const dispatch = useDispatch();
  const { id }: any = useParams();

  useEffect(() => {
    dispatch(getInfluenserUserDetailsById({ id: id }));
    dispatch(getFollowercountApi(id));
  }, []);

  const handleUserFollowedChecked = async () => {
    let json = {
      userId: user?._id,
      followerId: id,
    };
    let res: any = await dispatch(getFollowerCheckApi(json));

    if (res.payload) {
      setIsFollowed(true);
      setFollowedId(res?.payload?.data?._id);
    } else {
      setIsFollowed(false);
      setFollowedId("");
    }
  };

  useEffect(() => {
    if (user?._id && id) {
      handleUserFollowedChecked();
    }
  }, [user, id]);

  return (
    <ProfileStyled>
      <div className="">
        <ProfileCoverSection isFollowed={isFollowed} follwedId={follwedId} />
        <ProfileTabs />
      </div>
    </ProfileStyled>
  );
};

export default Profile;
