import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as RightArrow } from "../../images/arrow-right-s-line.svg";
import { ReactComponent as LikeFill } from "../../images/like-fill.svg";
import {
  getWishListByUserId,
  removeWishList,
} from "../../redux/slices/app/appService";

const WishListProductCard = ({ item, wishlistId, setShoProducts }: any) => {
  const {
    auth: { user },
  } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const handleRemoveWishList = async () => {
    let json = {
      id: wishlistId,
      productId: item?._id,
    };

    await dispatch(removeWishList(json));

    await dispatch(getWishListByUserId(user._id));
    setShoProducts(false);
  };

  return (
    <>
      <div className="product-card">
        <div className="wishlist-product-card">
          <div className="product-image-sec">
            <img src={item?._source?.images?.image[0]?.value} alt="product" />
          </div>
          <div className="product-price-sec">
            <div>
              <p className="price-value">
                {item?._source?.price?.value}
                <span>Sale</span>
              </p>
              <p className="reg-price">
                Reg. Price <span>{item?._source?.originalPrice?.value}</span>
              </p>
              <p className="product-name">{item?._source?.title}</p>
            </div>
            <div className="like-image-sec">
              <LikeFill
                onClick={() => {
                  handleRemoveWishList();
                }}
              />
            </div>
          </div>
        </div>
        <div className="wishlist-button">
          <button
            onClick={() => {
              window.open(`${item?._source?.url?.value}`);
            }}
          >
            GRAB DEAL
            <RightArrow />
          </button>
        </div>
      </div>
    </>
  );
};

export default WishListProductCard;
