import React, { useEffect, useRef, useState } from "react";
import { Progress } from "antd";
import { ReactComponent as Play } from "../../images/play-icon.svg";
import { ReactComponent as Pause } from "../../images/pause-fill.svg";
import { ReactComponent as Expand } from "../../images/expand.svg";
import { ReactComponent as Volume } from "../../images/volume.svg";
import { ReactComponent as EXpndClose } from "../../images/fullscreen-exit-line.svg";
import LikeShare from "./LikeShare";
import { useSelector } from "react-redux";

import styled from "styled-components";
import useIsMobile from "../../Utills/CheckIsMobile";
import MobileVideoTitle from "./MobileVideoTitle";
import FilterCanvas from "./CommentsFilter";
import ShareModal from "../../components/Common/ShareModal/ShareModal";
import { ReactComponent as UnMute } from "../../images/volume-mute-fill 1.svg";
const ReelCard = (props: any) => {
  const {
    app: { commmentsByVideoId },
  } = useSelector((state: any) => state);
  const {
    app: { productDetails },
  } = useSelector((state: any) => state);
  const { videoDetails } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [progress, setProgress] = useState(0);
  const videoRef: any = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const containerRef: any = useRef(null);
  const [videoTimer, setVideoTimer]: any = useState();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [shareExpandModalOpen, setShareExpandModalOpen] = useState(false);
  const [copy, setCopy]: any = useState();
  const [isMuted, setIsMuted] = useState(true);

  const isMobile = useIsMobile();

  const shareModalToggle = () => {
    setShareExpandModalOpen(!shareExpandModalOpen);
  };

  const handleTimeUpdate = () => {
    const video: any = videoRef.current;

    if (video) {
      setVideoTimer(video.duration);
      const percent = (video.currentTime / video.duration) * 100;
      setProgress(percent);
    }
  };

  const togglePlayPause = () => {
    const video = videoRef.current;

    if (video) {
      if (isPlaying) {
        video.pause(); // Pause the video
        setIsPlaying(false);
      } else {
        video.play(); // Play the video
        setIsPlaying(true);
      }
    }
  };

  const toggleFullscreen = () => {
    const container = containerRef.current;

    if (document.fullscreenElement) {
      // Exit fullscreen if already in fullscreen mode
      document.exitFullscreen();
      setIsExpanded(false);
    } else if (container) {
      // Enter fullscreen mode
      container.requestFullscreen();
      setIsExpanded(true);
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (document.fullscreenElement) {
        // Set state to true when entering fullscreen
        setIsExpanded(true);
      } else {
        // Set state to false when exiting fullscreen
        setIsExpanded(false);
      }
    };

    // Listen for the fullscreen change event
    document.addEventListener("fullscreenchange", handleFullscreenChange);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const formatTime = (time: any) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // useEffect(() => {
  //   if (videoRef.current) {
  //     videoRef.current.play().catch((error: any) => {
  //       console.error("Autoplay failed:", error);
  //     });
  //   }
  // }, [videoDetails]);

  // const handlePlayPause = () => {
  //   if (videoRef.current) {
  //     // Unmute the video after user interaction
  //     if (isMuted) {
  //       videoRef.current.muted = false;
  //       setIsMuted(false);
  //     } else {
  //       videoRef.current.muted = true;
  //       setIsMuted(true);
  //     }
  //   }
  // };

  const handleMute = () => {
    console.log("Test");

    setIsMuted(!isMuted);
  };
  console.log(isMuted, "handleMute");

  return (
    <ReelCardStyled>
      <div className="reel-card-container">
        <>
          <div>
            <div
              className={`video-container ${
                !isMobile ? "web-video-container" : ""
              }`}
              ref={containerRef}
              // onClick={() => setIsMuted(false)}
            >
              <video
                ref={videoRef}
                className={isExpanded ? "expand-video" : ""}
                autoPlay
                loop
                playsInline={true}
                muted={isMuted} // Controlled by state
                onPlay={() => {
                  setIsPlaying(true);
                }}
                onPause={() => {
                  setIsPlaying(false);
                }}
                onTimeUpdate={handleTimeUpdate}
              >
                {(videoDetails?.CDNUrl || videoDetails?.VideoUrl) && (
                  <source
                    src={videoDetails?.CDNUrl || videoDetails?.VideoUrl}
                    type="video/mp4"
                  />
                )}
                Your browser does not support the video tag.
              </video>

              <div className="controls-list">
                <div className="play-icon">
                  {isPlaying ? (
                    <Pause onClick={togglePlayPause} />
                  ) : (
                    <Play onClick={togglePlayPause} />
                  )}
                  <p> {formatTime(videoRef.current?.currentTime || 0)}</p>
                </div>
                <div className="expand-volume-sec">
                  <div
                    // onClick={() => {
                    //   handlePlayPause();
                    // }}
                    className="volume-icon"
                  >
                    {isMuted ? (
                      <UnMute onClick={() => handleMute()} />
                    ) : (
                      <Volume onClick={() => handleMute()} />
                    )}
                  </div>
                  <div>
                    {isExpanded ? (
                      <EXpndClose onClick={toggleFullscreen} />
                    ) : (
                      <Expand onClick={toggleFullscreen} />
                    )}
                  </div>
                </div>
              </div>
              <div className="video-progress-bar">
                <Progress percent={progress} showInfo={false} />
              </div>
            </div>
          </div>
        </>

        {isMobile && <MobileVideoTitle videoDetails={videoDetails} />}

        {!isExpanded && (
          <div className="like-share-sec">
            <LikeShare
              commmentsByVideoId={commmentsByVideoId}
              videoDetails={videoDetails}
              productDetails={productDetails}
            />
          </div>
        )}

        {/* {isFullScreen && <div>Full Screen Mode</div>} */}
      </div>
    </ReelCardStyled>
  );
};

export default ReelCard;

export const ReelCardStyled = styled.div`
  /* General styles */
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }

  /* Standard video container */
  .video-container {
    position: relative;
    max-width: 100%;
    max-height: 90dvh;
    border-radius: 8px;
    margin: 0px auto;
  }

  video {
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .expand-btn {
    background-color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
  }

  .bottom-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    position: relative;
  }

  .live-indicator {
    color: red;
    font-weight: bold;
  }

  .viewer-count {
    font-size: 14px;
  }

  .progress-bar {
    flex-grow: 1;
    margin-left: 10px;
    height: 5px;
    background-color: #555;
    position: relative;
  }

  .progress {
    width: 50%;
    height: 100%;
    background-color: red;
  }

  /* Expanded view */
  .expanded-view {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .volume-icon svg path {
    fill: #fff;
  }
  .expanded-view video {
    max-width: 100%;
    max-height: 100%;
    height: 100vh;
  }

  .close-expand-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: white;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
  }
  .controls-list {
    position: absolute;
    bottom: 32px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 24px;
    align-items: center;
    z-index: 2;
  }
  .expand-video {
    width: auto !important;
    margin: 0 auto;
    height: 100%;
  }
  .expand-volume-sec {
    display: flex;
    gap: 24px;
  }
  svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
  .video-progress-bar {
    bottom: 24px;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 10;
    padding: 0px 24px;
    box-sizing: border-box;
  }

  .video-progress-bar .ant-progress-inner {
    background: rgba(255, 255, 255, 0.3);
    height: 4px;
  }
  .video-progress-bar .ant-progress-inner .ant-progress-bg-outer {
    background: #9500ff;
    height: 4px;
    border-radius: 0px !important;
  }
  .play-icon {
    display: flex;
    gap: 8px;
  }
  .play-icon p {
    color: var(--videoshops-com-white, var(--color-white-solid, #fff));
    text-align: right;
    font-family: "AvenirNextRegular";
    font-size: var(--item-spacing-14, 14px);
    font-style: normal;
    font-weight: var(--font-weight-400, 400);
    margin-bottom: 0px;
  }

  .video-container .ant-progress {
    transition: width 0.01s linear; /* Smooth transition for width changes */
  }
  .expanded-product-card {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    width: 90%; /* Adjust width as needed */
    max-width: 500px;
    background: rgba(0, 0, 0, 0.8); /* Optional: Add a background */
    border-radius: 8px;
    padding: 16px;
    color: white;
  }
  .web-video-container {
    aspect-ratio: 387 / 689;
  }
  .like-share-sec {
  }
  .like-share-sec.expanded-share-sec {
    position: absolute;
    top: 30vh;
    right: 48px;
  }
  @media (max-width: 575.98px) {
    .video-container {
      height: calc(100vh - 121px);
      max-height: 100%;
      width: 100vw;
    }
    .product-card-sec {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .product-card {
      grid-template-rows: 0.5fr 1.5fr;
    }
    .product-price-sec {
      padding-bottom: 12px;
    }
    .like-share-sec {
      position: absolute;
      right: 16px;
      top: 38px;
    }
  }
`;
