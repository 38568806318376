import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP, SERVER_IP_URL_BACKEND, SERVER_IP_URL_FRONTEND } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";

// export const getAllMerchantsAPI = createAsyncThunk(
//   "dashboard/getAllMerchantsAPI",
//   async () => await get(`${SERVER_IP}/home/getMerchants`)
// );

export const generateDeepLinkAPI = createAsyncThunk(
  "dashboard/generateDeepLinkAPI",
  async (body: any) => await post(`${SERVER_IP}/home/generateDeepLink`, body)
);

export const getAllProductsAPI = createAsyncThunk(
  "dashboard/getAllProductsAPI",
  async (body: any) => {
    // Initialize the query parameters array
    const queryParams: string[] = [];

    // Iterate over the body object and construct the query string
    for (const key in body) {
      if (body[key] !== undefined && body[key] !== null) {
        // Add the key-value pair to the query parameters array
        queryParams.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`
        );
      }
    }

    // Join all query parameters with '&'
    const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";

    // Send the request with the dynamic query string
    return await get(`${SERVER_IP}/home/getProductslist${queryString}`);
  }
);

export const getAllCategoriesAPI = createAsyncThunk(
  "dashboard/getAllCategoriesAPI",
  async () => await get(`${SERVER_IP}/home/getCategories`)
);

export const getAllCategoriesByBrandsAPI = createAsyncThunk(
  "dashboard/getAllCategoriesByBrandsAPI",
  async (body: any) =>
    await get(`${SERVER_IP}/home/getAllCategories?mids=${body?.ids}`)
);
export const createComments = createAsyncThunk(
  "comments/create",
  async (body: any) =>
    await post(`${SERVER_IP_URL_BACKEND}/comments`,body)
);

export const GetAllComments=createAsyncThunk(
  "getComments",
  async (body:any)=>{
   let response= await get(`${SERVER_IP_URL_BACKEND}/comments/all/${body.id}`)
    return response;
  }
)

export const getVideoDetails=createAsyncThunk(
  "getVideoDetails",
  async (body:any)=>{
   let response= await post(`${SERVER_IP_URL_BACKEND}/video/videosbyuser`,body);
   return response;
  }
)

export const getProductDetailsList=createAsyncThunk(
  "getProductDetailsList",
  async (body:any)=>{
    console.log(body,"getProductDetailsList")
   let response= await get(`https://api.redclubfeed.com/home/getProductslist?q=&mid=${body.id}&pageSize=20&pageNo=${body.pageNumber}&showOfferedProducts=false&sortBy=${body.sort}&vendorId=5029&cpa_products=true`);
   return response;
  }
)
export const getProductByWishList=createAsyncThunk(
  "getProductByWishList",
  async (body:any)=>{
   let response= await get(`https://api.redclubfeed.com/home/getProductslist?q=&mid=&pageSize=20&pageNo=1&showOfferedProducts=false&sortBy=priceHtoL&vendorId=${body?.vendorId}&af_placement_id=${body?.id}&productIds=${body?.productIds}`);
   return response;
  }
)


export const getAllVideosAPI=createAsyncThunk(
  "getAllVideos",
  async (body:any)=>{
   let response= await get(`${SERVER_IP_URL_BACKEND}/video`);
   return response;
  }
);


export const createWishlist=createAsyncThunk(
  "createWishlist",
  async (body:any)=>{
   let response= await post(`${SERVER_IP_URL_BACKEND}/wishlist`,body);
   return response;
  }
);
export const updateWishList=createAsyncThunk(
  "createWishlist",
  async (body:any)=>{
   let response= await post(`${SERVER_IP_URL_BACKEND}/wishlist/update`,body);
   return response;
  }
);
export const getWishListByUserId=createAsyncThunk(
  "createWishlist",
  async (body:any)=>{
   let response= await get(`${SERVER_IP_URL_BACKEND}/wishlist/user/${body}`);
   return response;
  }
);
export const removeWishList=createAsyncThunk(
  "createWishlist",
  async (body:any)=>{
   let response= await post(`${SERVER_IP_URL_BACKEND}/wishlist/delete`,body);
   return response;
  }
);
export const videoLikeApi=createAsyncThunk(
  "videoLikeApi",
  async (body:any)=>{
   let response= await post(`${SERVER_IP_URL_BACKEND}/video/like`,body);
   return response;
  }
);
export const createFollowerApi=createAsyncThunk(
  "createFollower",
  async (body:any)=>{
   let response= await post(`${SERVER_IP_URL_BACKEND}/followers`,body);
   return response;
  }
);
export const updateFollowerApi=createAsyncThunk(
  "updateFollowerApi",
  async (body:any)=>{
   let response= await put(`${SERVER_IP_URL_BACKEND}/followers/${body}`);
   return response;
  }
);
export const getFollowerCheckApi=createAsyncThunk(
  "getFollowerCheckApi",
  async (body:any)=>{
   let response= await get(`${SERVER_IP_URL_BACKEND}/followers/${body?.userId}/${body?.followerId}`);
   return response;
  }
);
export const getFollowercountApi=createAsyncThunk(
  "getFollowercountApi",
  async (body:any)=>{
   let response= await get(`${SERVER_IP_URL_BACKEND}/followers/${body}`);
   return response;
  }
);
export const getAllMerchantsApi=createAsyncThunk(
  "getAllMerchantsApi",
  async (body:any)=>{
   let response= await get(`${SERVER_IP_URL_FRONTEND}/home/getCPAMerchants`);
   return response;
  }
);
export const genarateDeepLink=createAsyncThunk(
  "genarateDeepLink",
  async (body:any)=>{
   let response= await post(`${SERVER_IP_URL_FRONTEND}/home/generateDeepLink`,body);
   return response;
  }
);
