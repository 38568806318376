import styled from "styled-components";

export const ExploreByBrandStyled = styled.div`
  .explore-brand-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 24px;
    row-gap: 16px;
    margin-bottom: 72px;
  }
  .explore-brand-header {
    margin-top: 144px;
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 32px;
    h2 {
      color: var(--secondary-colour-300-main, #0b0b0b);
      font-family: "AvenirNextSemiBold";
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 46px; /* 115% */
    }
    span {
      color: var(--secondary-colour-300-main, #0b0b0b);
      text-align: right;
      font-family: "AvenirNextSemiBold";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 125% */
      cursor: pointer;
    }
  }
  .explore-brand-cards {
    .category-card {
      box-shadow: 0px 0px 36px 0px rgba(33, 33, 33, 0.08),
        0px 0px 0px 1px #d6d6d6;
      border-radius: 16px;
      height: 173px;
      padding: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      img {
        max-width: 80%;
        max-height: 75%;
      }
      .browse-button {
        color: #762da0;
        font-family: "AvenirNextSemiBold";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 171.429% */
        letter-spacing: 0.028px;
        text-transform: uppercase;
        display: flex;
        gap: 8px;
        padding: 8px 24px;
        border-radius: 90px;
        flex-direction: row;
        box-shadow: none;
        cursor: pointer;
        align-items: center;
        svg path {
          fill: #762da0;
        }
      }
    }
  }
  .back-sec {
    color: var(--Black, #000);
    font-family: "AvenirNextMedium";
    font-size: 20px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 32px;
    cursor: pointer;
  }
  @media (max-width: 575.98px) {
    .explore-brand-header {
      margin-top: 56px;
    }
    .explore-brand-header h2 {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0px;
    }
    .explore-brand-header span {
      font-size: 14px;
      line-height: 20px;
    }

    .explore-brand-cards {
      display: grid;
      grid-template-rows: repeat(2, auto); /* Two rows */
      grid-auto-flow: column; /* Horizontal scrolling */
      gap: 16px;
      overflow-x: auto; /* Enable horizontal scrolling */
      scroll-snap-type: x mandatory;
      padding: 16px;
      margin-bottom: 56px;

      /* Hide scrollbar for mobile browsers */
      scrollbar-width: none; /* Firefox */
    }

    .explore-brand-cards::-webkit-scrollbar {
      display: none; /* Chrome, Safari, and Edge */
    }

    .explore-brand-cards .category-card {
      scroll-snap-align: start; /* Ensure smooth snapping */
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 16px 12px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      text-align: center;
      width: 188px; /* Fixed width for each card */
      display: grid;
      grid-template-rows: 50% 50%;
    }

    .category-card .category-image {
      width: 100%;
    }

    .explore-brand-cards .category-card .browse-button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Inter;
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.002em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      cursor: pointer;
      margin-top: 8px;
      width: 100%;
      padding: 8px 12px;
    }
    .explore-brand-header {
      margin-bottom: 0px;
    }
  }
`;
