import styled from "styled-components";

export const FooterStyled = styled.div`
  footer {
    background: #faf1ff;
  }
  .footer-sec {
    display: flex;
    justify-content: space-between;
    padding: 40px 64px;
  }
  .footer-menu {
    display: flex;
    gap: 100px;
    h3 {
      color: #212121;
      font-family: "AvenirNextBold";
      font-size: 15.375px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 143.089% */
      margin-bottom: 16px;
    }
    ul {
      list-style-type: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    li {
      color: #212121;
      font-family: "AvenirNextMedium";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      cursor: pointer;
    }
  }
  .follow-us-header h3 {
    color: #212121;
    font-family: "AvenirNextBold";
    font-size: 15.375px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 143.089% */
  }
  .copy-right-sec {
    display: flex;
    justify-content: space-between;
    padding: 24px 64px;
    border-top: 1px solid #ad9065;
    p {
      color: #212121;
      text-align: center;
      font-family: "AvenirNextMedium";
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
  }
  .footer-logo svg path {
    fill: #762da0;
  }

  @media (max-width: 575.98px) {
    .footer-sec {
      flex-direction: column;
      padding: 40px 16px;
      gap: 12px;
    }
    .footer-menu {
      flex-direction: column;
      gap: 32px;
    }
    .footer-logo img {
      margin-bottom: 20px;
      color: #762da0;
    }
    .copy-right-sec {
      flex-direction: column;
      gap: 12px;
    }
  }
`;
