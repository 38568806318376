import React, { useEffect } from "react";
import Banner from "../Banner/Banner";
import ExploreByCategory from "../ExploreByCategory/ExploreByCategory";
import ExploreByBrand from "../ExploreByBrands/ExploreByBrand";
import FAQ from "../FAQ/FAQ";
import TrendingNow from "../TrendingSlides/TrendingNow/TrendingNow";
import ClothingSlider from "../TrendingSlides/ClothingSlider/ClothingsSlider";
import BeautyCareSlider from "../TrendingSlides/Beauty&Care/BeautyCareSlider";
import { useDispatch } from "react-redux";
import { getAllVideosAPI } from "../../redux/slices/app/appService";
import TrendingOffers from "../TrendingOffers/TrendingOffers";
import useIsMobile from "../../Utills/CheckIsMobile";

const HomePage: React.FC = () => {
  const dispatch = useDispatch();

  const isMobile = useIsMobile();

  useEffect(() => {
    handleGetAllVideos();
  }, []);

  const handleGetAllVideos = async () => {
    await dispatch(getAllVideosAPI({}));
  };

  console.log(isMobile, "isMobile");
  return (
    <div>
      <Banner />
      {/* <TrendingNow /> */}
      {/* <ExploreByCategory /> */}
      <BeautyCareSlider />
      {/* <TrendingOffers /> */}
      <ExploreByBrand />

      {/* <ClothingSlider /> */}
      <FAQ />
    </div>
  );
};

export default HomePage;
