import React from "react";
import VideoCard from "../../TrendingSlides/VideoCard";
import Image1 from "../../../images/slides-1/1.png";
import Image2 from "../../../images/slides-1/1.png";
import Image3 from "../../../images/slides-1/1.png";
import Image4 from "../../../images/slides-1/1.png";
import Image5 from "../../../images/slides-1/1.png";
import Profile from "../../../images/profiles/profile2.png.png";
import styled from "styled-components";

const LiveStreams: React.FC = () => {
  const videoData = [
    {
      image: Image1,
      profile: Profile,
      name: "Meredith",
      description: "Description 1",
    },
    {
      image: Image2,
      profile: Profile,
      name: "Meredith",
      description: "Description 1",
    },
    {
      image: Image3,
      profile: Profile,
      name: "Meredith",
      description: "Description 1",
    },
    {
      image: Image4,
      profile: Profile,
      name: "Meredith",
      description: "Description 1",
    },
    {
      image: Image5,
      profile: Profile,
      name: "Meredith",
      description: "Description 1",
    },
    {
      image: Image1,
      profile: Profile,
      name: "Meredith",
      description: "Description 1",
    },
    {
      image: Image1,
      profile: Profile,
      name: "Meredith",
      description: "Description 1",
    },
    {
      image: Image1,
      profile: Profile,
      name: "Meredith",
      description: "Description 1",
    },
    {
      image: Image1,
      profile: Profile,
      name: "Meredith",
      description: "Description 1",
    },
    {
      image: Image1,
      profile: Profile,
      name: "Meredith",
      description: "Description 1",
    },
  ];
  return (
    <VideosStyled>
      <div className="profile-videos-sec">
        {videoData.map((video, index) => (
          <VideoCard
            key={index}
            video={video}
            profileVideo={true}
            livestream={true}
          />
        ))}
      </div>
    </VideosStyled>
  );
};

export default LiveStreams;

export const VideosStyled = styled.div`
  .profile-videos-sec {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 24px;
    margin-bottom: 112px;
  }
`;
