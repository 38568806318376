import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP, SERVER_IP_URL_BACKEND } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  // async (body:any) => {
  //   return await post(`${SERVER_IP}/auth/login`, body);
  // }
  async (body: any) => await post(`${SERVER_IP_URL_BACKEND}/auth/signin`, body)
);

export const signUpUser = createAsyncThunk(
  "auth/signUpUser",
  async (body: any) => await post(`${SERVER_IP_URL_BACKEND}/auth/signup`, body)
);
export const updateUserDetails = createAsyncThunk(
  "auth/updateUserDetails",
  async (body: any) => await put(`${SERVER_IP_URL_BACKEND}/auth/update/${body?._id}`, body)
);

export const getUserDetailsById = createAsyncThunk(
  "auth/getUserDetailsById",
  async (body: any) => await get(`${SERVER_IP_URL_BACKEND}/auth/${body?.id}`)
);

export const getInfluenserUserDetailsById = createAsyncThunk(
  "auth/getInfluenserUserDetailsById",
  async (body: any) => await get(`${SERVER_IP_URL_BACKEND}/auth/${body?.id}`)
);

export const updateUserEvents = createAsyncThunk(
  "auth/updateUserEvents",
  async (body: any) => await put(`${SERVER_IP}/event/updateEvent`, body)
);

export const saveUserEvents = createAsyncThunk(
  "auth/saveUserEvents",
  async (body: any) => await post(`${SERVER_IP}/event/create`, body)
);

export const getAllEvents = createAsyncThunk(
  "auth/getAllEvents",
  async () => await get(`${SERVER_IP}/event/all`)
);
export const getEventsById = createAsyncThunk(
  "auth/getEventsById",
  async (body: any) => await post(`${SERVER_IP}/event/getEventsById`, body)
);

export const saveUserProdCasts = createAsyncThunk(
  "auth/saveUserProdCasts",
  async (body: any) => await post(`${SERVER_IP}/prodcast/create`, body)
);

export const getAllProdCasts = createAsyncThunk(
  "auth/getAllProdCasts",
  async () => await get(`${SERVER_IP}/prodcast/all`)
);
export const getProdcastById = createAsyncThunk(
  "auth/getProdcastById",
  async (body: any) => await post(`${SERVER_IP}/prodcast/getProdcastById`, body)
);

export const updateUserPodcast = createAsyncThunk(
  "auth/updateUserPodcast",
  async (body: any) => await put(`${SERVER_IP}/prodcast/updatePodcast`, body)
);

export const saveUserDailyQuotes = createAsyncThunk(
  "auth/saveUserDailyQuotes",
  async (body: any) => await post(`${SERVER_IP}/dailyQuote/create`, body)
);

export const getAllDailyQuotes = createAsyncThunk(
  "auth/getAllDailyQuotes",
  async () => await get(`${SERVER_IP}/dailyQuote/all`)
);

export const updateUserDailyQuotes = createAsyncThunk(
  "auth/updateUserDailyQuotes",
  async (body: any) => await put(`${SERVER_IP}/dailyQuote/update`, body)
);
export const getDailyQuoteById = createAsyncThunk(
  "auth/getDailyQuoteById",
  async (body: any) =>
    await post(`${SERVER_IP}/dailyQuote/getDailyQuoteById`, body)
);
export const getSignedUrlApi = createAsyncThunk(
  "auth/getSignedUrlApi",
  async () => await get(`${SERVER_IP}/prodcast/getSignedUrl`)
);

export const createUserClicksApi1 = createAsyncThunk(
  "auth/createUserClicksApi1",
  async (body: any) =>
    await post(`https://mms.amos-service.net/api/auth/login`, body)
);
export const createUserClicksApi2 = createAsyncThunk(
  "auth/createUserClicksApi2",
  async () =>
    await get(`https://mms.amos-service.net/api/users/accounts/191/trackings`)
);
export const getAllPriceLineFeedsApi = createAsyncThunk(
  "auth/getAllPriceLineFeedsApi",
  async () => await get(`${SERVER_IP}/getAllPriceLineFeeds`)
);
export const getAllYahooFeedsApi = createAsyncThunk(
  "auth/getAllYahooFeedsApi",
  async () => await get(`${SERVER_IP}/getAllYahooFeeds`)
);
export const getAllPricelineSales = createAsyncThunk(
  "auth/getAllPricelineSales",
  async () => await get(`${SERVER_IP}/getAllPricelineSales`)
);

export const getMediaAuthTokenApi = createAsyncThunk(
  "auth/getMediaAuthTokenApi",
  async (body: any) =>
    await post(`https://api-pubconsole.media.net/v2/login`, body)
);

export const changePasswordAPi = createAsyncThunk(
  "CHANGE_PASSWORD",
  async (body: any) => await post(`${SERVER_IP_URL_BACKEND}/auth/changePassword`, body)
);

// export const getTRackingsApi = createAsyncThunk(
//   "auth/getTRackingsApi",
//   async (body: any) => await post(`https://core.amos-service.net/api/auth/login`, body)
// );

// fetch("https://core.amos-service.net/api/users/accounts/185/trackings", requestOptions)
//   .then(response => response.text())
//   .then(result => console.log(result))
//   .catch(error => console.log('error', error));

//
