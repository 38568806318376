import React from "react";
import { PrimaryButtonStyled } from "./PrimaryButtonStyled.styled";

interface PrimaryButtonProps {
  label: string;
  onClick: () => void;
  icon?: any;
  iconPosition?: string;
  className?: string;
  disabled?: boolean;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  label,
  onClick,
  icon,
  iconPosition,
  className,
  disabled,
}) => {
  return (
    <PrimaryButtonStyled>
      <button
        className={`primary-button ${iconPosition} ${className}`}
        onClick={onClick}
        disabled={disabled}
      >
        {icon && icon}
        {label}
      </button>
    </PrimaryButtonStyled>
  );
};

export default PrimaryButton;
