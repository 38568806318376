import PrimaryButton from "../../components/Common/PrimaryButton/PrimaryButton";

const HelpSupport = () => {
  return (
    <>
      <div>
        <header>
          <h2>Help & Support</h2>
        </header>
        {/* <div>
          <PrimaryButton label="Ticket History" onClick={() => {}} />
        </div>
        <div>
          <h3>Tell Us what went wrong</h3>
          <div>
            <label>Select Category</label>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default HelpSupport;
