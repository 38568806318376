import { useState } from "react";
import { Modal } from "react-bootstrap";
import PrimaryButton from "../../../components/Common/PrimaryButton/PrimaryButton";
import styled from "styled-components";
import {
  createWishlist,
  getWishListByUserId,
} from "../../../redux/slices/app/appService";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../../../components/Common/ConfirmationModal/ConfirmationModal";

type AddWishListProps = {
  show: boolean;
  hide: () => void;
  setShowWishListModal?: any;
};

const CreateWishListModal: React.FC<AddWishListProps> = ({
  show,
  hide,
  setShowWishListModal,
}) => {
  const {
    auth: { user },
  } = useSelector((state: any) => state);

  const dispatch = useDispatch();
  const [wishListName, setWishListName] = useState("");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const handleCreateWishlist = async () => {
    let payload = {
      wishlistName: wishListName,
      userId: user?._id,
    };

    let res: any = await dispatch(createWishlist(payload));

    hide();

    if (res.payload) {
      setConfirmModalOpen(true);
      await dispatch(getWishListByUserId(user._id));
    }
  };

  const handleModalClose = () => {
    setConfirmModalOpen(false);
  };

  return (
    <>
      <ConfirmationModal
        modalHeader={"Wishlist Created Successfully"}
        show={confirmModalOpen}
        onHide={handleModalClose}
        description="Your wishlist is ready! Start adding your favorite items and shop them when you're ready."
        confirmLabel="Okay"
        onConfirmClick={() => {
          handleModalClose();
          setShowWishListModal(true);
        }}
      />
      <Modal show={show} onHide={hide} centered>
        <CreateWishListModalStyled>
          <Modal.Body>
            <div>
              <header className="wishlist-header">
                <h2>Create Wishlist</h2>
              </header>
              <p className="wishlist-text">
                Organize your favorites effortlessly. Create a wishlist to save
                and shop your top picks anytime.
              </p>
              <div className="wishlist-input">
                <label>Wishlist Name</label>
                <input
                  type="text"
                  name="fname"
                  value={wishListName}
                  onChange={(e: any) => {
                    setWishListName(e.target.value);
                  }}
                />
              </div>
              <div className="create-wish-footer">
                <button className="cancel-button" onClick={hide}>
                  Cancel
                </button>
                <PrimaryButton
                  disabled={!wishListName}
                  label="Create"
                  onClick={() => {
                    handleCreateWishlist();
                  }}
                />
              </div>
            </div>
          </Modal.Body>
        </CreateWishListModalStyled>
      </Modal>
    </>
  );
};

export default CreateWishListModal;

export const CreateWishListModalStyled = styled.div`
  .modal-body {
    padding: 20px;
  }
  .wishlist-header h2 {
    font-family: "AvenirNextMedium";
    font-size: 18px;
    font-weight: 450;
    line-height: 32px;
    text-align: left;
  }
  .wishlist-text {
    font-family: "AvenirNextRegular";
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
  }
  .wishlist-input {
    display: flex;
    flex-direction: column;
  }
  .wishlist-input label {
    font-family: "AvenirNextRegular";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  .wishlist-input input {
    padding: 8px 16px;
    border-radius: 10px;
    border: 1px solid #cfd3d7;
  }
  .create-wish-footer {
    display: flex;
    justify-content: end;
    gap: 16px;
    margin-top: 20px;
  }
  .cancel-button {
    font-family: "AvenirNextBold";
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.002em;
    text-align: center;
    padding: 8px 24px;
    min-width: 147px;
    background: #fff;
    border-radius: 10px;
  }
  .create-wish-footer .primary-button {
    min-width: 147px;
    padding: 9px 24px;
    justify-content: center;
    border-radius: 10px;
  }
  .create-wish-footer .primary-button:disabled {
    background: #eceeef;
    color: #bfc5c9;
  }
`;
