import styled from "styled-components";

export const ProductCardStyled = styled.div`
  height: 100%;
  .product-image-sec {
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .product-card {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #f6f6f6;
    background: #fff;
    height: 100%;
    box-shadow: 1px 3px 16px 0px rgba(32, 32, 32, 0.12);
    gap: 12px;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    gap: 4%;

    /* Drop shadow/Medium */
    box-shadow: 1px 3px 16px 0px rgba(32, 32, 32, 0.12);
  }
  .like-button {
    position: absolute;
    top: 8px;
    right: 8px;
    background: #fff;
    border-radius: 10px;
    padding: 8px;
    cursor: pointer;
  }
  .product-price-sec {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .brand {
      margin-bottom: 16px;
    }
    .price-value {
      color: var(--FRAGRANCENET-COLORS-Mojo, #bd3d44);
      font-family: Inter;
      font-size: 22px;
      font-weight: 500;
      line-height: 22px; /* 100% */
      span {
        color: var(--FRAGRANCENET-COLORS-Mojo, #bd3d44);
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
      }
    }
    .reg-price {
      color: var(--FRAGRANCENET-COLORS-Emperor, var(--color-grey-33, #535353));
      font-family: Inter;
      font-size: 14px;
      font-weight: 300;
      line-height: 22px; /* 157.143% */
      span {
        color: var(
          --FRAGRANCENET-COLORS-Emperor,
          var(--color-grey-33, #535353)
        );
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
    .product-name {
      color: #181818;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 137.5% */
    }
    button {
      border-radius: 34px;
      background: var(--Videoshops-Red, #762da0);
      color: var(--FRAGRANCENET-COLORS-White, var(--color-white-solid, #fff));
      font-family: "AvenirNextMedium";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.024px;
      text-transform: uppercase;
      border: none;
      width: 100%;
      padding: 8px;
    }
  }
  @media (max-width: 575.98px) {
    .product-card {
      grid-template-rows: 0.5fr 1.5fr;
    }
    .product-price-sec {
      padding-bottom: 12px;
    }
  }
`;
