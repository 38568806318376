import { useHistory } from "react-router-dom";
import Logos from "../../images/Social-links.svg";
import { ReactComponent as HomeLogo } from "../../images/OnlyDrops.svg";
import { FooterStyled } from "./FooterStyled.styled";

const Footer = () => {
  const history = useHistory();
  return (
    <FooterStyled>
      <footer>
        <div className="footer-sec">
          <div className="footer-logo">
            <HomeLogo />
          </div>
          <div className="footer-menu">
            <div>
              <h3>About OnlyDrops</h3>
              <ul>
                <li
                  onClick={() => {
                    history.push("/about-us");
                  }}
                >
                  About Us
                </li>
                <li
                  onClick={() => {
                    history.push("/contact-us");
                  }}
                >
                  Contact Us
                </li>
                <li
                  onClick={() => {
                    history.push("/privacy-policy");
                  }}
                >
                  Your Privacy Rights
                </li>
                <li onClick={() => {}}>Terms of Use</li>
                <li>Cookie Policy</li>
              </ul>
            </div>
            {/* <div>
              <h3>Top Brands</h3>
              <ul>
                <li>Skechers</li>
                <li>Boston Proper</li>
                <li>Brooks Brothers</li>
                <li>Lucky Brand</li>
                <li>Crocs</li>
              </ul>
            </div> */}
            <div className="display-hidden">
              <h3>Top Categories</h3>
              <ul>
                <li>Computers & Software</li>
                <li>Pet Supplies</li>
                <li>Clothing & Accessories</li>
                <li>Sports Equipment & Outdoor Gear</li>
                <li>Toys</li>
              </ul>
            </div>
          </div>
          <div className="follow-us-header">
            <h3>Follow us</h3>
            <img src={Logos} alt="footer-logos" />
          </div>
        </div>
        <div className="copy-right-sec">
          <p>Made with ♥️︎ by the Redclub team</p>
          <p>Copyright © 2024 OnlyDrop</p>
        </div>
      </footer>
    </FooterStyled>
  );
};

export default Footer;
