import styled from "styled-components";

export const PrimaryButtonStyled = styled.div`
  .primary-button {
    color: var(--Shade-0, #fff);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: 0.032px;
    // text-transform: uppercase;
    padding: 14px 24px;
    background: #762da0;
    border-radius: 90px;
    border: none;
    display: flex;
    gap: 8px;
    align-items: center;
  }
  .primary-button.create-wishlist-button {
    padding: 8px 24px;
    color: var(--Shade-0, #fff);
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.028px;
  }
`;
