import { Modal } from "react-bootstrap";
import Login from "../Login/Login";
import { ReactComponent as CheckIcon } from "../../images/checkbox-circle-fill.svg";
import "./login.css";
import { LoginModalStyled } from "./LoginModalStyled.styled";

const LoginModal = ({
  show,
  onHide,
  urlPath,
  setUrlPath,
  redirectionUrl,
}: any) => {
  return (
    <Modal className="login-modal" show={show} onHide={onHide} centered>
      <LoginModalStyled>
        <div className="deal-green-sec">
          <CheckIcon /> <p>Activate Your Deal Now</p>
        </div>
        <Modal.Body>
          <Login
            urlPath={urlPath}
            setUrlPath={setUrlPath}
            redirectionUrl={redirectionUrl}
            onHide={onHide}
          />
        </Modal.Body>
      </LoginModalStyled>
    </Modal>
  );
};

export default LoginModal;
