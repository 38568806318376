import React, { useState } from "react";
import { FAQwrapper } from "./FAQ.styled";
import { ReactComponent as RightIcon } from "../../images/left-arrow.svg";
import { useHistory } from "react-router-dom";
import useIsMobile from "../../Utills/CheckIsMobile";
import { UserProfileStyled } from "./UserProfileStyled.styled";

const FAQS = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "How do I update my profile information?",
      answer:
        "Go to 'Account Settings' in your profile, where you can update your personal details, email, and preferences. Don’t forget to save your changes!",
    },
    {
      question: "Can I shop directly from a video?",
      answer:
        "Yes! Click on the product link in the video to view details and make a purchase directly from the brand’s site.",
    },
    {
      question: "How can I like or comment on a video?",
      answer:
        "To interact with a video, you need to be logged in. Once logged in, click the like button or type your comment below the video.",
    },
    {
      question: "How do I manage my notifications?",
      answer:
        "Go to 'Settings' and click on 'Notifications' to choose what updates you want to receive.",
    },
  ];

  return (
    <UserProfileStyled>
      <FAQwrapper>
        <div className="faq-container">
          {isMobile && (
            <div
              className="mobile-acc-setting-header"
              onClick={() => history.goBack()}
            >
              <RightIcon />
              <h2>FAQ’s</h2>
            </div>
          )}
          <h1 className="faq-heading">FAQ’s</h1>
          <div className="faq-items">
            {faqs.map((faq, index) => (
              <div key={index} className="faq-item">
                <div
                  className={`faq-question ${
                    activeIndex === index ? "active_question" : ""
                  }`}
                  onClick={() => toggleFAQ(index)}
                >
                  <span>{faq.question}</span>
                  <span
                    className={`faq-icon ${
                      activeIndex === index ? "open" : ""
                    }`}
                  ></span>
                </div>
                {activeIndex === index && (
                  <p className="faq-answer">{faq.answer}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </FAQwrapper>
    </UserProfileStyled>
  );
};

export default FAQS;
