import { useDispatch, useSelector } from "react-redux";
import VideoCard from "../TrendingSlides/VideoCard";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getAllVideosAPI } from "../../redux/slices/app/appService";
import { ReactComponent as BackIcon } from "../../images/left-arrow.svg";

const BrandsCategory = () => {
  const { getAllVideos } = useSelector((state: any) => state.app);

  const [brandVideosArray, setBrandVideoArray]: any = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const { brand, id }: any = useParams();

  useEffect(() => {
    handleGetAllVideos();
  }, []);

  useEffect(() => {
    let brandVideos = getAllVideos.filter((item: any) => item?.brand.id === id);
    setBrandVideoArray(brandVideos);
  }, [getAllVideos, id]);

  const handleGetAllVideos = async () => {
    await dispatch(getAllVideosAPI({}));
  };

  return (
    <BrandsCategoryStyled>
      <div
        className="back-sec"
        onClick={() => {
          history.goBack();
        }}
      >
        <BackIcon />
        Back
      </div>
      <h2 className="brand-catogery-heading">
        {brandVideosArray?.length > 0 ? brandVideosArray[0]?.brand.name : null}
      </h2>
      <div className="video-category-cards">
        {brandVideosArray?.map((video: any, index: number) => (
          <VideoCard key={index} video={video} />
        ))}
      </div>
    </BrandsCategoryStyled>
  );
};
export default BrandsCategory;

export const BrandsCategoryStyled = styled.div`
  .video-category-cards {
    display: grid;
    column-gap: 1.25%;
    row-gap: 24px;
    grid-template-columns: 19% 19% 19% 19% 19%;
    margin-bottom: 72px;
  }
  .back-sec {
    color: var(--Black, #000);
    font-family: "AvenirNextMedium";
    font-size: 20px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    display: flex;
    align-items: center;
    margin-top: 32px;
    cursor: pointer;
    p {
      margin-bottom: 0px;
    }
  }
  .brand-catogery-heading {
    color: var(--secondary-colour-300-main, #0b0b0b);
    font-family: "AvenirNextSemiBold";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px; /* 115% */
    margin-top: 40px;
    margin-bottom: 32px;
  }
  @media (max-width: 575.98px) {
    .video-category-cards {
      column-gap: 4%;
      grid-template-columns: 48% 48%;
      row-gap: 16px;
    }
    .video-logo img {
      height: 16px;
    }
  }
`;
