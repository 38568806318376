export function getRelativeTime(timestamp: any) {
  const now: any = new Date();
  const time: any = new Date(timestamp);
  const diffInSeconds = Math.floor((now - time) / 1000);

  const intervals = [
    { label: "year", seconds: 31536000 },
    { label: "month", seconds: 2592000 },
    { label: "week", seconds: 604800 },
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 },
  ];

  for (const interval of intervals) {
    const count = Math.floor(diffInSeconds / interval.seconds);
    if (count >= 1) {
      return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
    }
  }
  return "just now";
}

const merchantLogs: any = {
  326283:
    "https://redclub-websites-offer-images.s3.ap-south-1.amazonaws.com/brandlogos/shein.png",
  321128:
    "https://redclub-websites-offer-images.s3.ap-south-1.amazonaws.com/brandlogos/tj-maxx-logo.png",
  28463:
    "https://redclub-websites-offer-images.s3.ap-south-1.amazonaws.com/brandlogos/dell.png",
  316963:
    "https://redclub-websites-offer-images.s3.ap-south-1.amazonaws.com/brandlogos/chewy.png",
  25533:
    "https://redclub-websites-offer-images.s3.ap-south-1.amazonaws.com/brandlogos/LuckyBrandLogo.png",
  23777: "",
  31949:
    "https://redclub-websites-offer-images.s3.ap-south-1.amazonaws.com/brandlogos/Reebok_2019_logo.png",
  199042:
    "https://redclub-websites-offer-images.s3.ap-south-1.amazonaws.com/brandlogos/crocs-wordmark+1.png",
  316972:
    "https://redclub-websites-offer-images.s3.ap-south-1.amazonaws.com/brandlogos/samsung-8+1.png",
  327286:
    "https://redclub-websites-offer-images.s3.ap-south-1.amazonaws.com/brandlogos/Aloyoga.png",
  29914:
    "https://redclub-websites-offer-images.s3.ap-south-1.amazonaws.com/brandlogos/petco.png",
  337142:
    "https://redclub-websites-offer-images.s3.ap-south-1.amazonaws.com/1743230924408_istock-corporate-logo.png",
};

export const getBrandLogoUrl = (id: any, url: any) => {
  return merchantLogs[id] || url;
};
