import React from "react";
import { Button, Carousel } from "antd";
import VideoCard from "./VideoCard";
import "./trending.css";
import LeftArrow from "../../images/left.svg";
import RightArrow from "../../images/right.svg";
import { TrendingVideosStyled } from "./TrendingVideosStyled.styled";
import useIsMobile from "../../Utills/CheckIsMobile";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const TrendingVideos: React.FC = () => {
  const {
    app: { getAllVideos },
  } = useSelector((state: any) => state);
  const carouselRef: any = React.createRef();
  const isMobile = useIsMobile();
  const history = useHistory();

  const HomePageCount = isMobile ? 4 : 10;

  console.log(isMobile, "isMobile");

  return (
    <TrendingVideosStyled>
      <div className="carousel-wrap">
        {/* {!isMobile && (
          <Button
            className="left_arrow_btn"
            onClick={() => {
              carouselRef.current.prev();
            }}
          >
            <img src={LeftArrow} alt="arrow" />
          </Button>
        )} */}

        {/* {!isMobile && (
          <Button
            className=" right_arrow_btn"
            onClick={() => {
              carouselRef.current.next();
            }}
          >
            <img src={RightArrow} alt="arrow" />
          </Button>
        )} */}

        {/* {!isMobile && (
          <Carousel
            slidesPerRow={5}
            infinite={true} // Loop through slides
            // @ts-ignore
            arrowOffset={10}
            slidesToScroll={1} // Scroll one slide at a time
            // slidesToShow={5}
            arrowSize={30}
            dotWidth={20}
            // @ts-ignore
            ref={carouselRef}
            slickGoTo={1}
            dots={false}
          > */}
        {!isMobile && (
          <div className="homepage-slider-cards">
            {getAllVideos
              ?.slice(0, HomePageCount)
              ?.map((video: any, index: number) => (
                <VideoCard key={index} video={video} />
              ))}
          </div>
        )}
        {!isMobile && (
          <div className="view-all-button">
            <div
              className="button"
              onClick={() => history.push("/video-libray")}
            >
              View All
            </div>
          </div>
        )}
        {/* </Carousel>
        )} */}
        {isMobile && (
          <div
            style={{
              display: "flex",
              overflowX: "scroll",
              scrollSnapType: "x mandatory",
              gap: "16px",
            }}
          >
            <>
              {getAllVideos?.length > 10 &&
                getAllVideos.slice(0, 10).map((video: any, index: number) => (
                  <div
                    key={index}
                    style={{
                      flex: "0 0 50%",
                      scrollSnapAlign: "start",
                    }}
                  >
                    <VideoCard video={video} />
                  </div>
                ))}
            </>
          </div>
        )}
      </div>
    </TrendingVideosStyled>
  );
};

export default TrendingVideos;
