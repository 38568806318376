import styled from "styled-components";

export const PrivacyPolicyWrapper = styled.div`
  /* Container Styling */
  .policy-container {
  }

  /* Heading Styling */
  .policy-heading {
    font-family: "AvenirNextMedium";
    font-size: 20px;
    font-weight: 450;
    line-height: 27px;
    text-align: left;
    margin-top: 24px;
  }

  /* Introduction Text */
  .policy-intro {
    margin-bottom: 16px;
    font-family: "AvenirNextRegular";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  /* List Styling */
  .policy-list {
    list-style-type: disc;
    margin-left: 0px;
    margin-bottom: 16px;
    font-family: "AvenirNextRegular";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  .policy-list li {
    margin-bottom: 12px;
    font-family: "AvenirNextRegular";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  /* Footer Text */
  .policy-footer {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 48px;
    font-family: "AvenirNextRegular";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
`;
