import styled from "styled-components";

export const ComingSoonStyled = styled.div`
  .coming-banner {
    height: 100vh;
    display: flex;
    width: 100%;
    .coming-soon-left-sec {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .logo-body {
        margin-top: 40px;
      }
      .user-suscribe-sec {
        h1 {
          color: var(
            --www-videoshops-com-black,
            var(--color-black-solid, #000)
          );
          font-family: "AvenirNextSemiBold";
          font-size: 42px;
          font-style: normal;
          font-weight: 750;
          line-height: normal;
          letter-spacing: var(--letter-spacing--1, -1px);
          margin-bottom: 22px;
        }
        p {
          color: var(
            --www-videoshops-com-black,
            var(--color-black-solid, #000)
          );
          font-family: "AvenirNextMedium";
          font-size: 16px;
          font-style: normal;
          font-weight: 450;
          margin-bottom: 40px;
          line-height: 22px; /* 137.5% */
        }
        h2 {
          color: var(--Black, #000);

          /* Poppins / M / Bold */
          font-family: "AvenirNextSemiBold";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.2px;
          margin-bottom: 16px;
        }
        div {
          display: flex;
          flex-direction: column;
          margin-bottom: 24px;
        }
        label {
          color: var(--Shade-100, #000);
          font-family: "AvenirNextRegular";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 125% */
        }
        input {
          padding: 14px 16px;
          color: var(--Neutral-400, #000);
          font-family: "AvenirNextRegular";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }
        button {
          border-radius: 18px;
          background: var(--Videoshops-Purple, #762da0);
          width: 100%;
          color: var(--Shade-0, #fff);
          font-family: "AvenirNextSemiBold";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
          letter-spacing: 0.032px;
          text-transform: uppercase;
          padding: 14px 32px;
        }
      }
    }
    .coming-soon-right-sec {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .footer-logo-sec {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 40px;
    p {
      color: var(--Gray-3, #828282);
      text-align: center;

      /* Poppins / S / Regular */
      font-family: "AvenirNextRegular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.14px;
    }
  }
  .coming-banner .coming-soon-left-sec .user-suscribe-sec .email-error-messgae {
    color: red;
    font-size: 14px;
    margin-top: 4px;
  }
  .coming-banner
    .coming-soon-left-sec
    .user-suscribe-sec
    .email-success-messgae {
    color: "3B8F46" !important;
    font-size: 14px;
    margin-top: 4px;
  }
`;
