import { Modal } from "react-bootstrap";
import "./DropDownWrapModal.css";
import { DropDownModalContainer } from "./DropDownWrapModal.styled";

const DropDownWrapModal = ({
  cancelLabel,
  onHide,
  confirmLabel,
  onConfirmClick,
  rest,
  modalBody,
  show,
  className,
  isButtonDisable,
  header,
  clearFunc,
  contentClass,
}: any) => {
  const handleHide = () => {
    onHide();
    transformFunc();
  };
  const transformFunc = () => {
    setTimeout(() => {
      const modalDialog = document.querySelector(
        ".dfs_down-filter-sec .modal-dialog"
      );
      const modalContent = document.querySelector(
        ".dfs_down-filter-sec .modal-content"
      );

      if (modalDialog && modalContent) {
        modalDialog.classList.add("closing");
        modalContent.classList.add("closing");
      }
    }, 50);
  };

  const confirmFuncCall = () => {
    onConfirmClick();
    transformFunc();
  };
  return (
    <DropDownModalContainer
      show={show}
      {...rest}
      className={`${className} dfs_down-filter-sec`}
      onHide={handleHide}
    >
      <div className={`modal-content ${contentClass}`}>
        {header && (
          <Modal.Header className="DDWM-header">
            <div className="header-bar">
              <div className="header-inner-bar"></div>
            </div>
            <div className="d-flex justify-content-between w-100">
              <div className="ddwm-headerText">{header}</div>
            </div>
          </Modal.Header>
        )}
        {modalBody && <Modal.Body>{modalBody}</Modal.Body>}
      </div>
    </DropDownModalContainer>
  );
};

export default DropDownWrapModal;
