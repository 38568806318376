import { PrivacyPolicyWrapper } from "./PrivacyPolicy.styled";
import { ReactComponent as RightIcon } from "../../images/left-arrow.svg";
import { useHistory } from "react-router-dom";
import useIsMobile from "../../Utills/CheckIsMobile";
import { UserProfileStyled } from "./UserProfileStyled.styled";

const PrivacyPolicy = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  return (
    <UserProfileStyled>
      <PrivacyPolicyWrapper>
        <div className="policy-container">
          {isMobile && (
            <div
              className="mobile-acc-setting-header"
              onClick={() => history.goBack()}
            >
              <RightIcon />
              <h2>My Purchases</h2>
            </div>
          )}
          <h1 className="policy-heading">Privacy Policy</h1>
          <p className="policy-intro">
            At OnlyDrops, we prioritize your privacy and ensure your data is
            used responsibly.
          </p>
          <ul className="policy-list">
            <li>
              <strong>Information We Collect:</strong> We collect basic account
              information, usage data, and interactions on our platform for
              account setup, analytics, and personalized content
              recommendations.
            </li>
            <li>
              <strong>Data Usage:</strong> Information gathered helps enhance
              your experience, manage transactions, and deliver relevant offers.
              We do not sell your data to third parties.
            </li>
            <li>
              <strong>Cookies:</strong> We use cookies to improve navigation and
              track site engagement. You may adjust your cookie preferences in
              browser settings.
            </li>
            <li>
              <strong>Third-Party Links:</strong> OnlyDrops may link to
              third-party sites. We are not responsible for their privacy
              practices.
            </li>
            <li>
              <strong>Your Data Rights:</strong> Users have the right to access,
              modify, or delete personal data. Contact support for data
              inquiries or adjustments.
            </li>
          </ul>
          <p className="policy-footer">
            Our full Privacy Policy provides additional details. Please review
            it for a complete understanding of your data use and protection.
          </p>
        </div>
      </PrivacyPolicyWrapper>
    </UserProfileStyled>
  );
};

export default PrivacyPolicy;
