import styled from "styled-components";

export const LoginStyled = styled.div`
  .login-container {
    max-width: 600px;
    margin: auto;
    padding: 12px 0px;
    border-radius: 8px;
    font-family: Inter;
  }
  .logo-container {
    margin-bottom: 32px;
    text-align: center;
    svg path {
      fill: var(--Videoshops-Purple, #762da0);
    }
  }
  h2 {
    color: var(--Shade-100, #000);
    font-family: "AvenirNextSemiBold";
    font-size: 25px;
    font-style: normal;
    // font-weight: 700;
    line-height: 33px; /* 132% */
    margin-bottom: 12px;
  }
  p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    margin-bottom: 30px;
    font-family: "AvenirNextRegular";
  }

  .form-group {
    margin-bottom: 1.5rem;
    text-align: left;
  }

  label {
    color: var(--Shade-100, #000);
    font-family: "AvenirNextRegular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    display: block;
  }

  label span {
    color: red;
  }

  input {
    width: 100%;
    padding: 14px 16px;
    border: 1px solid #ccc;
    border-radius: 16px;
    font-family: "AvenirNextRegular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }

  .password-container {
    position: relative;
  }

  .toggle-password {
    position: absolute;
    right: 24px;
    top: 14px;
    cursor: pointer;
    color: #555;
  }

  .forgot-password {
    color: var(--Shade-100, #000);
    font-family: "AvenirNextRegular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
    text-align: right;
    margin-bottom: 30px;
  }

  .forgot-password a {
    text-decoration: none;
    color: var(--Shade-100, #000);
    font-size: 16px;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
  }

  .forgot-password a:hover {
    text-decoration: underline;
  }

  .login-button {
    width: 100%;
    padding: 14px 16px;
    color: var(--Shade-0, #fff);
    font-family: "AvenirNextMedium";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.032px;
    border: none;
    border-radius: 18px;
    height: 50px;
    cursor: pointer;
    background: var(--Videoshops-Purple, #762da0);
  }
  .login-button:hover {
    background: var(--Videoshops-Red, #762da0) !important;
    color: var(--Shade-0, #fff) !important;
  }
  .login-button:disabled {
    color: var(--Neutral-400, #bfc5c9);
    background: var(--Neutral-100, #eceeef) !important;
  }
  .social-login {
    margin-top: 30px;
  }

  .social-login p {
    margin-bottom: 0.5rem;
  }

  .social-icons {
    display: flex;
    gap: 20px;
    justify-content: center;
  }

  .social-button {
    display: flex;
    border-radius: 8px;
    border: 1px solid rgba(32, 32, 32, 0.12);
    background: #fff;
    width: 240px;
    cursor: pointer;
    height: 56px;
    padding: 0px;
    padding-right: 24px;
    align-items: center;
    color: #000;
    gap: 40px;
    text-align: center;
    font-family: "AvenirNextMedium";
    font-size: 18px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    letter-spacing: 0.15px;
    img {
      height: inherit;
      border-radius: 8px;
      border: 1px solid rgba(32, 32, 32, 0.12);
      background: #fff;
      padding: 15px;
      border-left: none;
    }
  }

  .signup-link {
    margin-top: 1rem;
    font-size: 0.9rem;
  }

  .signup-link a {
    text-decoration: none;
    color: var(--Videoshops-Purple, #762da0);
    font-family: "AvenirNextRegular";
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
  }

  .signup-link a:hover {
    text-decoration: underline;
  }
  .sign-up-error {
    color: red;
    margin-bottom: 24px;
  }
  .sign-up-text {
    display: flex;
    justify-content: center;
    p {
      margin-bottom: 0px;
    }
  }
  .sign-up-text {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 720px;
  }
  .divider-line {
    flex: 1;
    height: 1px;
    background-color: #ccc;
  }
  .sign-up-text p {
    margin: 0 10px;
    font-size: 14px;
    color: #666;
    white-space: nowrap;
  }
  .dotted-line {
    border-right: 1px dotted #666;
    height: 1px;
  }
  .email-error-message {
    margin-top: 8px;
    display: block;
    color: red;
    font-family: "AvenirNextMedium";
  }
  .terms-container {
    display: flex;
    align-items: start;
    gap: 24px;
    margin-bottom: 20px;
    input {
      width: 20px;
      height: 20px;
      margin-top: 6px;
    }
    label {
      color: var(--Shade-100, #000);
      font-family: "AvenirNextRegular";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      a {
        color: var(--Videoshops-Red, #a83b1a);
        font-family: "AvenirNextRegular";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
      }
    }
  }
  .deal-button-sec {
    button {
      font-family: "AvenirNextSemiBold";
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.002em;
      text-align: center;
      border: 1px solid #762da0;
      background: #fff;
      border-radius: 18px;
      margin-bottom: 16px;
      width: 100%;
      padding: 14px 32px;
    }
    p {
      font-family: "AvenirNextRegular";
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      text-align: center;
    }
  }
`;
