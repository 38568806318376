import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../images/OnlyDrops.svg";
import { ReactComponent as Search } from "../../images/search-2-line.svg";
import { Dropdown, FormControl } from "react-bootstrap";
import BlackButton from "../../components/Common/BlackButton/BalckButton";
import { TopHeaderStyled } from "./TopHeaderStyled.styled";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Notificaton } from "../../images/notification.svg";
import { ReactComponent as WishList } from "../../images/wishlist.svg";
import useIsMobile from "../../Utills/CheckIsMobile";
import DefaultProfileImage from "../../Utills/DefaultProfile";
import FilterCanvas from "./WishListSlider";
import { getWishListByUserId } from "../../redux/slices/app/appService";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { ReactComponent as PersonalIcon } from "../../images/personal-icon.svg";
import { ReactComponent as PurchaseIcon } from "../../images/purchase-icon.svg";
import { ReactComponent as Help } from "../../images/help-support.svg";
import { ReactComponent as FAQ } from "../../images/faq.svg";
import { ReactComponent as TermsConditions } from "../../images/terms-conditions.svg";
import { ReactComponent as PrivacyPolicy } from "../../images/privacy-policy.svg";
import { ReactComponent as Delete } from "../../images/delete.svg";
import { ReactComponent as Logout } from "../../images/log-out.svg";
import { ReactComponent as RightIcon } from "../../images/right-black-arrow.svg";

const TopHeader: React.FC = () => {
  const history = useHistory();
  const {
    auth: { user },
    app: { getWishList },
  } = useSelector((state: any) => state);

  const [isSignPage, setIsSignPage] = useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  console.log(isProfileDropdownOpen, "isProfileDropdownOpen");

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const location = useLocation();
  const currentPath = location.pathname;
  console.log(user, "useridddd");

  useEffect(() => {
    if (currentPath === "/login" || currentPath === "/sign-up") {
      setIsSignPage(true);
    } else {
      setIsSignPage(false);
    }
  }, [currentPath]);

  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user._id) {
      handleWishListData();
    }
  }, []);

  const handleWishListData = async () => {
    await dispatch(getWishListByUserId(user._id));
  };

  const handleLogout = () => {
    history.push("/home");
    localStorage.clear();
    window.location.reload();
  };

  const menuList = [
    {
      icon: <PersonalIcon />,
      label: "Account Settings",
      redirect: "/account-setting",
    },
    {
      icon: <PurchaseIcon />,
      label: "My Purchases",
      redirect: "/my-purchases",
    },
    { icon: <Help />, label: "Help & Support" },
    { icon: <FAQ />, label: "FAQ’s", redirect: "/faq" },
    {
      icon: <TermsConditions />,
      label: "Terms & Conditions",
      redirect: "/terms-conditions",
    },
    {
      icon: <PrivacyPolicy />,
      label: "Privacy & Policy",
      redirect: "/privacypolicy",
    },
    // { icon: <Delete />, label: "Delete Account" },
    { icon: <Logout />, label: "Logout" },
  ];
  return (
    <TopHeaderStyled>
      <FilterCanvas show={isFilterOpen} setFilterShow={setIsFilterOpen} />

      <header className="header-sec">
        <div className="logo-section">
          <Logo
            onClick={() => {
              history.push("/");
            }}
          />
          {!isMobile && <span className="display-hidden">Live</span>}
        </div>
        {!isSignPage && (
          <div
            className={`search-section  ${
              user.vendorId ? "profile-icons" : ""
            }`}
          >
            {!isMobile && (
              <div className="display-hidden search-input-wrapper">
                <FormControl
                  autoComplete="off"
                  type="text"
                  placeholder="Search influencers, Videos"
                  className="explore-search-input"
                  name="search"
                  onChange={(e: any) => {}}
                />
                <Search />
              </div>
            )}
            {isMobile && <Search className="mobile-search-icon" />}
            {user.vendorId ? (
              <>
                <div className="profile-icons-img">
                  <WishList
                    onClick={() => {
                      setIsFilterOpen(true);
                    }}
                  />
                </div>
                <div className="profile-icons-img">
                  <Notificaton />
                </div>
                <div
                  className="profile-icons-img"
                  onClick={() => {
                    if (isMobile) {
                      setIsProfileDropdownOpen(true);
                    } else {
                      history.push("/user-profile");
                    }
                  }}
                >
                  {isMobile ? (
                    <Dropdown
                      show={isProfileDropdownOpen}
                      onToggle={() => {
                        setIsProfileDropdownOpen(!isProfileDropdownOpen);
                      }}
                    >
                      <DropdownToggle
                        className="header-dropdown"
                        id="header-toggle-btn"
                      >
                        {" "}
                        {user?.imageUrl ? (
                          <img src={user?.imageUrl} alt="profile" />
                        ) : (
                          <DefaultProfileImage
                            userName={user?.userName}
                            fullName={user?.fullName}
                            email={user?.email}
                          />
                        )}
                      </DropdownToggle>
                      <DropdownMenu
                        id="hamburger-menu"
                        className="profile-icon-drp-menu show hamburger-menu"
                      >
                        <h2>My Profile</h2>
                        <p>
                          Update your details, manage settings, and access key
                          account information in one place.
                        </p>
                        {menuList.map((item: any, index: number) => (
                          <div
                            className="menulist-item"
                            onClick={async (e: any) => {
                              if (index === 6) {
                                handleLogout();
                              } else {
                                e.stopPropagation();
                                await setIsProfileDropdownOpen(false);
                                window.location.href = item?.redirect;
                              }
                            }}
                          >
                            <div className="menulist">
                              {" "}
                              {item.icon}
                              {item?.label}
                            </div>
                            {index !== menuList.length - 1 &&
                              index !== menuList.length - 2 && <RightIcon />}
                          </div>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  ) : (
                    <>
                      {user?.imageUrl ? (
                        <img src={user?.imageUrl} alt="profile" />
                      ) : (
                        <DefaultProfileImage
                          userName={user?.userName}
                          fullName={user?.fullName}
                          email={user?.email}
                        />
                      )}
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                {!isMobile && (
                  <span
                    className="sigin-up-text"
                    onClick={() => {
                      history.push("/sign-up");
                    }}
                  >
                    Sign up
                  </span>
                )}
                <BlackButton
                  text="Sign In"
                  onClick={() => {
                    history.push("/login");
                  }}
                />{" "}
              </>
            )}
          </div>
        )}
      </header>
    </TopHeaderStyled>
  );
};

export default TopHeader;
