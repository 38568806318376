import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { VideoContainer } from "./styled";
interface VideoPlayerProps {
  key?: string;
  url: string;
  thumb?: string;
  className?: string;
  playerStyle?: any;
  playOnHover?: boolean;
  mouseOver?: boolean;
  opacity?: number;
  autoPlay?: boolean;
  showControls?: boolean;
}
const VideoPlayer: React.FC<VideoPlayerProps> = ({
  key,
  url,
  thumb,
  className,
  playerStyle,
  playOnHover = false,
  mouseOver = false,
  opacity = 0,
  autoPlay = true,
  showControls = false,
  ...rest
}) => {
  const [playing, setPlaying] = useState(autoPlay);
  const [duration, setDuration] = useState(0);
  const [loader, setLoader] = useState(false);
  const playerRef = useRef<any>(null);
  const onMouseOver = () => {
    if (!playOnHover) return;
    setLoader(duration === 0);
    setPlaying(true);
  };
  const onMouseOut = () => {
    if (!playOnHover) return;
    setPlaying(false);
    setLoader(false);
  };
  const handleProgress = (state: any) => {
    if (state.playedSeconds >= 3) {
      playerRef.current?.seekTo(0); // Restart from the beginning
    }
  };
  useEffect(() => {
    if (mouseOver) onMouseOver();
    else onMouseOut();
  }, [mouseOver]);
  return (
    <VideoContainer
      key={key}
      className={className}
      onMouseOver={onMouseOver}
      // onMouseOut={onMouseOut}
    >
      <ReactPlayer
        playsinline
        ref={playerRef}
        config={{ file: { attributes: { controlsList: "nodownload" } } }}
        className="react-player"
        style={playerStyle}
        url={url}
        light={false}
        playing={playing}
        loop={playOnHover}
        controls={showControls ? showControls : !playOnHover && playing}
        progressInterval={100}
        onDuration={(d: number) => setDuration(d)}
        onProgress={handleProgress}
        previewTabIndex={playOnHover ? 1 : 0}
        muted={!!playOnHover || !autoPlay}
        autoPlay={!!playOnHover}
        onReady={() => setLoader(false)}
        onBuffer={() => setLoader(true)}
        onBufferEnd={() => setLoader(false)}
        // playIcon={false}
        {...rest}
      />
      {/* {opacity > 0 && <BROpacity opacity={opacity * 100} />} */}
      {/* {!playing && !thumb && !loader && (
        <PlayIcon className="play-icon" onClick={() => setPlaying(true)} />
      )} */}
      {/* {playOnHover && playing && duration > 0 && (
        <ProgressBar variant="danger" now={Math.round((playSec / duration) * 100)} />
      )} */}
      {/* {playOnHover && <Spinner isVisible={loader} />} */}
    </VideoContainer>
  );
};

export default VideoPlayer;
