import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as BackIcon } from "../../images/left-arrow.svg";
import { ReactComponent as Location } from "../../images/location.svg";
import { ReactComponent as Mail } from "../../images/mail-icon.svg";
import { ReactComponent as Call } from "../../images/call.svg";

const ContactUs = () => {
  const history = useHistory();
  return (
    <ContactUsStyled>
      <header
        className="contact-us-header"
        onClick={() => {
          history.goBack();
        }}
      >
        <BackIcon />
        Contact Us
      </header>
      <div className="contact-us-body">
        <section>
          <h2>Contact Us</h2>
          <p>
            {" "}
            <Location />
            641 Lexington Avenue, 15th Floor New York, NY 10022
          </p>
          <p>
            <Mail />
            support@onlydrops.com
          </p>
          <p>
            <Call />
            (406) 555-0120
          </p>
        </section>
      </div>
    </ContactUsStyled>
  );
};

export default ContactUs;

export const ContactUsStyled = styled.div`
  .contact-us-header {
    display: flex;
    margin: 28px 0px 28px;
    gap: 8px;
    align-items: center;
    color: var(--Black, #000);
    font-family: "AvenirNextMedium";
    font-size: 20px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    cursor: pointer;
  }
  section {
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
    padding: 24px;
    p {
      color: #000;
      font-family: "AvenirNextRegular";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 155.556% */
      display: flex;
      gap: 12px;
    }
    h2 {
      color: #000;
      font-family: "AvenirNextBold";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 24px;
      text-align: center;
    }
  }
  .contact-us-body {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 48px 0 84px;
  }
`;
