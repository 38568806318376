import React from "react";
import { Button, Modal } from "react-bootstrap";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import styled from "styled-components";
import "./confirmationmodal.css";

type ConfirmModalProps = {
  modalHeader?: string;
  description?: string;
  confirmLabel: string;
  cancelLabel?: string;
  onConfirmClick: any;
  show: boolean;
  onHide: any;
};

const ConfirmationModal: React.FC<ConfirmModalProps> = ({
  description,
  modalHeader,
  confirmLabel,
  cancelLabel,
  onConfirmClick,
  onHide,
  show,
}) => {
  return (
    <>
      <Modal show={show} centered className="confirmation-modal">
        <ConfirmationModalStyled>
          <Modal.Body className="confirm-modal-body">
            <h3>{modalHeader}</h3>
            <p>{description}</p>
          </Modal.Body>
          <Modal.Footer className="confirm-modal-footer">
            {cancelLabel && (
              <Button className="outline-button" onClick={onHide}>
                {cancelLabel}
              </Button>
            )}
            {confirmLabel && (
              <PrimaryButton label={confirmLabel} onClick={onConfirmClick} />
            )}
          </Modal.Footer>
        </ConfirmationModalStyled>
      </Modal>
    </>
  );
};

export default ConfirmationModal;

export const ConfirmationModalStyled = styled.div`
  .confirm-modal-body {
    padding: 20px;
    h3 {
      color: var(--Shade-100, #000);
      font-family: "AvenirNextMedium";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 177.778% */
    }
    p {
      color: var(--Shade-100, #000);
      font-family: "AvenirNextRegular";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px; /* 156.25% */
    }
  }
  .confirm-modal-footer {
    border-top: none;
    .outline-button {
      border-radius: 10px;
      border: 1px solid #000;
      background: #fff;
      color: var(--Shade-100, #000);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
      letter-spacing: 0.028px;
      min-width: 129px;
      padding: 8px 24px;
    }
    .outline-button:active,
    .outline-button:focus {
      background: #fff;
      outline: none;
      box-shadow: none;
    }
    .primary-button {
      padding: 8px 24px;
      min-width: 129px;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
      letter-spacing: 0.028px;
      border-radius: 10px;
      background: var(--Videoshops-Red, #762da0);
    }
  }
`;
