import React from "react";
import { TermsConditionWrapper } from "./TermsCondition.styled";
import { UserProfileStyled } from "./UserProfileStyled.styled";
import { ReactComponent as RightIcon } from "../../images/left-arrow.svg";
import { useHistory } from "react-router-dom";
import useIsMobile from "../../Utills/CheckIsMobile";

const TermsAndConditions = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  return (
    <UserProfileStyled>
      <TermsConditionWrapper>
        <div className="terms-container">
          {isMobile && (
            <div
              className="mobile-acc-setting-header"
              onClick={() => history.goBack()}
            >
              <RightIcon />
              <h2>My Purchases</h2>
            </div>
          )}
          <h1 className="terms-heading">Terms & Conditions</h1>
          <p className="terms-intro">
            Welcome to OnlyDrops! By accessing or using our platform, you agree
            to these Terms and Conditions.
          </p>
          <ul className="terms-list">
            <li>
              <strong>Eligibility:</strong> You must be at least 18 years old or
              have parental consent to use OnlyDrops. By signing up, you confirm
              that the information you provide is accurate and complete.
            </li>
            <li>
              <strong>Platform Use:</strong>
              <span>
                - You may browse videos, interact with influencers, and access
                deals.
              </span>
              <span>
                - You agree not to misuse the platform by engaging in
                fraudulent, harmful, or abusive behavior.
              </span>
            </li>
            <li>
              <strong>Notifications and Communication:</strong> By signing up,
              you agree to receive notifications about deals, videos, and
              updates.
            </li>
            <li>
              <strong>Privacy and Data Security:</strong> Your data is handled
              as per our Privacy Policy. Please ensure you read and understand
              it.
            </li>
            <li>
              <strong>Changes to Terms: </strong> OnlyDrops may update these
              Terms from time to time. We will notify you of significant
              changes, but it’s your responsibility to review the updated terms.
            </li>
          </ul>
          <p className="terms-footer">
            For questions, reach out to our support team.{" "}
            <a href="mailto:support@onlydrops.com" className="terms-link">
              support@onlydrops.com
            </a>
          </p>
        </div>
      </TermsConditionWrapper>
    </UserProfileStyled>
  );
};

export default TermsAndConditions;
