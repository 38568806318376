import { ReactComponent as PersonalIcon } from "../../images/personal-icon.svg";
import { ReactComponent as PurchaseIcon } from "../../images/purchase-icon.svg";
import { ReactComponent as Help } from "../../images/help-support.svg";
import { ReactComponent as FAQ } from "../../images/faq.svg";
import { ReactComponent as TermsConditions } from "../../images/terms-conditions.svg";
import { ReactComponent as PrivacyPolicy } from "../../images/privacy-policy.svg";
import { ReactComponent as Delete } from "../../images/delete.svg";
import { ReactComponent as Logout } from "../../images/log-out.svg";
import { ReactComponent as RightIcon } from "../../images/right-black-arrow.svg";
import { ReactComponent as LeftIcon } from "../../images/left-arrow.svg";
import { UserProfileStyled } from "./UserProfileStyled.styled";
import { useState } from "react";
import AccontSettings from "./AccountSettings";
import MyPurchases from "./MyPurchases";
import HelpSupport from "./Help&Support";
import FAQS from "./FAQ";
import TermsANDConditions from "./TermsandConditions";
import PrivacyPolicys from "./PrivacyPolicy";
import { useHistory } from "react-router-dom";

const UserProfile = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0);
  const menuList = [
    { icon: <PersonalIcon />, label: "Account Settings" },
    { icon: <PurchaseIcon />, label: "My Purchases" },
    { icon: <Help />, label: "Help & Support" },
    { icon: <FAQ />, label: "FAQ’s" },
    { icon: <TermsConditions />, label: "Terms & Conditions" },
    { icon: <PrivacyPolicy />, label: "Privacy & Policy" },
    // { icon: <Delete />, label: "Delete Account" },
    { icon: <Logout />, label: "Logout" },
  ];

  const MainComponent =
    activeTab == 0 ? (
      <AccontSettings />
    ) : activeTab == 1 ? (
      <MyPurchases />
    ) : activeTab == 2 ? (
      <HelpSupport />
    ) : activeTab == 3 ? (
      <FAQS />
    ) : activeTab == 4 ? (
      <TermsANDConditions />
    ) : activeTab == 5 ? (
      <PrivacyPolicys />
    ) : null;

  const handleLogout = () => {
    history.push("/home");
    localStorage.clear();
    window.location.reload();
  };

  return (
    <UserProfileStyled>
      <div>
        <div className="profile-back-arrow" onClick={() => history.goBack()}>
          <LeftIcon />
          My Profile
        </div>
        <div className="profile-header">
          <h2>My Profile</h2>
          <p>
            Update your details, manage settings, and access key account
            information in one place.
          </p>
        </div>
      </div>
      <div className="user-profile-container">
        <div className="account-setting-tab-sec">
          {menuList.map((item: any, index: any) => (
            <div
              className={`acount-setting-tabs ${
                activeTab === index ? "active" : ""
              }`}
              onClick={() => {
                if (index === 6) {
                  handleLogout();
                } else {
                  setActiveTab(index);
                }
              }}
            >
              <div className="acc-set-label">
                {item.icon}
                {item.label}
              </div>
              {index !== menuList.length - 1 &&
                index !== menuList.length - 2 && <RightIcon />}
            </div>
          ))}
        </div>
        <div className="account-sett-right-sec">{MainComponent}</div>
      </div>
    </UserProfileStyled>
  );
};

export default UserProfile;
