import { useDispatch, useSelector } from "react-redux";
import { BrandsCategoryStyled } from "../BrandsCategory/BrandsCategory";
import VideoCard from "../TrendingSlides/VideoCard";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../images/left-arrow.svg";
import { getAllVideosAPI } from "../../redux/slices/app/appService";

const AllVideos = () => {
  const { getAllVideos } = useSelector((state: any) => state.app);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    handleGetAllVideos();
  }, []);

  const handleGetAllVideos = async () => {
    await dispatch(getAllVideosAPI({}));
  };
  return (
    <BrandsCategoryStyled>
      <div
        className="back-sec"
        onClick={() => {
          history.goBack();
        }}
      >
        <BackIcon />
        Back
      </div>
      <h2 className="brand-catogery-heading">All Videos</h2>
      <div className="video-category-cards">
        {getAllVideos?.map((video: any, index: number) => (
          <VideoCard key={index} video={video} />
        ))}
      </div>
    </BrandsCategoryStyled>
  );
};

export default AllVideos;
