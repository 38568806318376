import styled from "styled-components";

export const WishListProductsStyled = styled.div`
  .product-card {
    box-shadow: 1px 3px 16px 0px #2020201f;
    margin: 16px 12px;
    padding: 12px;
  }
  .wishlist-header {
    background: #faf1ff;
    padding: 20px 24px;
    h2 {
      font-family: "AvenirNextMedium";
      font-size: 18px;
      font-weight: 700;
      line-height: 22.05px;
      text-align: left;
      margin-bottom: 0px;
    }
  }
  .wishlist-product-card {
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 4%;
    align-items: center;
    .product-image-sec {
      img {
        width: 100%;
        aspect-ratio: 5/3;
        object-fit: cover;
      }
    }
    .product-price-sec {
      position: relative;
      .price-value {
        font-family: "AvenirNextMedium";
        font-size: 14px;
        font-weight: 450;
        line-height: 22px;
        text-align: left;
        color: #bd3d44;
        margin-bottom: 0px;
        span {
          font-family: "AvenirNextMedium";
          font-size: 14px;
          font-weight: 450;
          line-height: 22px;
          text-align: left;
          color: #bd3d44;
        }
      }
    }
    .reg-price {
      font-family: "AvenirNextLight";
      font-size: 12px;
      font-weight: 300;
      line-height: 22px;
      text-align: left;
      margin-bottom: 0px;
      span {
        font-family: "AvenirNextMedium";
        font-size: 12px;
        font-weight: 450;
        line-height: 22px;
        text-align: left;
      }
    }
    .product-name {
      font-family: "AvenirNextMedium";
      font-size: 12px;
      font-weight: 450;
      line-height: 22px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 150px;
      width: 150px;
      overflow: hidden;
    }
  }

  .wishlist-button {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    button {
      background: #762da0;
      width: 90%;
      font-family: "AvenirNextMedium";
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.002em;
      text-align: left;
      padding: 8px 24px;
      border: none;
      border-radius: 12px;
      color: #fff;
      text-align: center;
    }
  }
  .like-image-sec {
    position: absolute;
    top: -2px;
    right: 0px;
  }
  .wishlist-folder-section {
    padding: 24px 12px;
  }
  .wishlist-list {
    cursor: pointer;
  }
  .wishlist-name-header {
    display: flex;
    align-items: center;
    padding: 12px;
    .wishlist-name {
      font-family: "AvenirNextSemiBold";
      font-size: 16px;
      font-weight: 450;
      line-height: 19.6px;
      text-align: left;
      margin-bottom: 0px;
      cursor: pointer;
    }
  }
  .no-wishlist-content {
    display: flex;
    height: 80vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 24px;
    p {
      font-family: "AvenirNextRegular";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
`;
