export const API_BASE = "/api";
export const GRAPH_BASE = "https://graph.facebook.com/v6.0";

let SERVER_IP_URL = "https://api.redclubfeed.com";
// if (typeof window !== "undefined") {
//   if (window.location.hostname === "localhost") {
//     // SERVER_IP_URL  = "http://localhost:8000/api";
//     // SERVER_IP_URL = "http://13.233.1.1:4000/home";
SERVER_IP_URL = "http://localhost:4000";
//     // SERVER_IP_URL = "https://api.redclubfeed.com";
//   } else {
//     SERVER_IP_URL = "https://api.redclubfeed.com";
//   }
// }
export const REGION = "ap-south-1";
export const S3_BUCKET = "redclub-websites-offer-images";

export const a_accessKeyId = "AKIAQ3EGVROD7JASZIC7";
export const a_secretAccessKey = "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";

export let SERVER_IP_URL_BACKEND = "https://api.onlydrops.com";
// export let SERVER_IP_URL_BACKEND = "http://13.235.235.190/api";
export const SERVER_IP_URL_FRONTEND = "https://api.redclubfeed.com";

export const SERVER_IP = SERVER_IP_URL;
