import styled from "styled-components";

export const TermsConditionWrapper = styled.div`
  .terms-container {
  }

  .terms-heading {
    color: var(--Shade-100, #000);
    font-family: "AvenirNextMedium";
    font-size: 20px;
    font-style: normal;
    font-weight: 450;
    line-height: 27px; /* 135% */
    margin-bottom: 24px;
  }

  .terms-intro {
    color: var(--www-videoshops-com-black, var(--color-black-solid, #000));
    font-family: "AvenirNextRegular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-bottom: 24px;
  }

  .terms-list {
    list-style-type: disc;
    margin-left: 0px;
    margin-bottom: 24px;
  }

  .terms-list li {
    margin-bottom: 20px;
    strong {
      color: var(--www-videoshops-com-black, var(--color-black-solid, #000));
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
    }
    color: var(--www-videoshops-com-black, var(--color-black-solid, #000));
    font-family: "AvenirNextRegular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .terms-footer {
    color: var(--www-videoshops-com-black, var(--color-black-solid, #000));
    font-family: "AvenirNextRegular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-top: 20px;
    a {
      color: var(--Videoshops-Purple, #762da0);
      font-family: "AvenirNextRegular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .terms-link {
    color: #7c3aed; /* Match the link color */
    text-decoration: none;
  }

  .terms-link:hover {
    text-decoration: underline;
  }
`;
