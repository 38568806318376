import ReelCard from "./ReelCard";
import { VideoPdpStyled } from "./VideoPdpStyled.styled";
import { ReactComponent as LeftIcon } from "../../images/left-arrow.svg";
import ProductDetails from "./ProductDetails";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllComments,
  getAllVideosAPI,
  getProductDetailsList,
  getVideoDetails,
  getWishListByUserId,
} from "../../redux/slices/app/appService";
import ContentLoaderSection from "../../components/Common/ContentLoaderSection/ContentLoaderSection";
import useIsMobile from "../../Utills/CheckIsMobile";
import { ensureProtocol, getUrlSlug } from "../../library/Requests/helpers";

const VideoPdp = () => {
  const {
    app: { videoDetails, productDetails, getAllVideos = [] },
    auth: { user },
  } = useSelector((state: any) => state);
  const isMobile = useIsMobile();
  const { name, id = null }: any = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const extractedUrl = queryParams.get("url");

    if (extractedUrl) {
      window.location.href = ensureProtocol(extractedUrl);
    }
    dispatch(getAllVideosAPI({}));
  }, []);
  useEffect(() => {
    if (id) {
      handleFetchData(id);
      handlegetAllComments(id);
    } else {
      dispatch(getAllVideosAPI({}));
    }
    handleWishListData();
  }, []);
  console.log({ name, id }, "name, id");
  useEffect(() => {
    console.log(getAllVideos, "getAllVideos");
    if (!id && getAllVideos?.length > 0) {
      let brandVideos = getAllVideos.filter(
        (item: any) => getUrlSlug(item?.VideoTitle) === name
      );
      if (brandVideos.length > 0 && videoDetails?._id !== brandVideos[0]._id) {
        handleFetchData(brandVideos[0]._id);
        handlegetAllComments(brandVideos[0]._id);
      }
    }
  }, [getAllVideos]);

  const handleFetchData = async (id: string) => {
    setIsLoading(true);

    let videoData = {
      videoId: id,
      loginUserId: user._id || 0,
    };
    let videoRes: any = await dispatch(getVideoDetails(videoData));

    if (videoRes.payload) {
      await dispatch(
        getProductDetailsList({
          id: videoRes.payload[0].brand.id,
          pageNumber: 1,
          sort: "priceLtoH",
        })
      );
    }

    setIsLoading(false);
  };

  const handlegetAllComments = async (id: string) => {
    await dispatch(
      GetAllComments({
        id,
      })
    );
  };

  const handleWishListData = async () => {
    await dispatch(getWishListByUserId(user._id));
  };

  console.log(productDetails, "productDetails");
  console.log(videoDetails, "videoDetails");
  return (
    <VideoPdpStyled>
      {isLoading ? (
        <ContentLoaderSection fromPage="pdp" />
      ) : (
        <div className={`video-pdp-page ${!isMobile ? "row" : ""}`}>
          <div className={`${!isMobile ? "col-1" : ""}`}>
            <div className="back-button-sec" onClick={() => history.goBack()}>
              <LeftIcon />
              <span>Back</span>
            </div>
          </div>
          <div className={`${!isMobile ? "col-4" : ""}`}>
            <ReelCard videoDetails={videoDetails} isMobile={isMobile} />
            {!isMobile && productDetails.length > 0 && (
              <div
                className="browse-products-button"
                onClick={() => {
                  history.push(`/products/${videoDetails?.brand.id}`);
                }}
              >
                Browse Products
              </div>
            )}
          </div>
          {!isMobile && (
            <ProductDetails
              videoDetails={videoDetails}
              productDetails={productDetails}
            />
          )}
        </div>
      )}
    </VideoPdpStyled>
  );
};

export default VideoPdp;
