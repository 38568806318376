import { UserProfileStyled } from "./UserProfileStyled.styled";
import { ReactComponent as RightIcon } from "../../images/left-arrow.svg";
import { useHistory } from "react-router-dom";
import useIsMobile from "../../Utills/CheckIsMobile";

const MyPurchases = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const purchasesData = [
    {
      productName: "Duochrome Liquid Eyeshadow",
      date: "Nov 20th, 2024",
      price: "$35.99",
      earning: "$5.40",
    },
    {
      productName: "Duochrome Liquid Eyeshadow",
      date: "Nov 20th, 2024",
      price: "$35.99",
      earning: "$5.40",
    },
    {
      productName: "Duochrome Liquid Eyeshadow",
      date: "Nov 20th, 2024",
      price: "$35.99",
      earning: "$5.40",
    },
    {
      productName: "Duochrome Liquid Eyeshadow",
      date: "Nov 20th, 2024",
      price: "$35.99",
      earning: "$5.40",
    },
  ];
  return (
    <UserProfileStyled>
      <div className="purchases-body">
        {isMobile && (
          <div
            className="mobile-acc-setting-header"
            onClick={() => history.goBack()}
          >
            <RightIcon />
            <h2>My Purchases</h2>
          </div>
        )}
        <header>
          <h2 className="purchases-header mt-4">My Purchases</h2>
        </header>
        {true && (
          <section className="no-purchases-body">
            <p>
              It looks like you haven’t made any purchases yet. Start shopping
              today and enjoy exclusive cashback rewards!
            </p>
          </section>
        )}
        {false && (
          <section>
            <p className="purchases-results-text">
              Showing 1-5 of 20 Purchases
            </p>
            <div className="purchases-table">
              <p>Product Name</p>
              <p>Purchased On</p>
              <p>Sale Price</p>
              <p>Earnings</p>
            </div>
            {purchasesData.map((item: any, index: any) => (
              <div className="purchases-data">
                <p className="product-name">{item?.productName}</p>
                <p>{item?.date}</p>
                <p>{item?.price}</p>
                <p>{item?.earning}</p>
              </div>
            ))}
          </section>
        )}
      </div>
    </UserProfileStyled>
  );
};

export default MyPurchases;
