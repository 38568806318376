import styled from "styled-components";
import { ReactComponent as BackIcon } from "../../images/left-arrow.svg";
import { useHistory } from "react-router-dom";
const PrivacyPoicy = () => {
  const history = useHistory();
  return (
    <PrivacyPolicy>
      <div>
        <header
          className="privacy-poicy-header"
          onClick={() => {
            history.goBack();
          }}
        >
          <BackIcon />
          Privacy Policy
        </header>
        <section>
          <p>
            OnlyDrops values your privacy. This Privacy Policy outlines how we
            collect, use, and protect your data.
          </p>
          <div className="section">
            <div className="section-title">Data We Collect:</div>
            <ul>
              <li>
                <strong>Personal Information:</strong> Name, email, and profile
                details provided during account creation.
              </li>
              <li>
                <strong>Activity Data:</strong> Videos you watch, interactions,
                and deal preferences.
              </li>
              <li>
                <strong>Technical Information:</strong> Device type, browser,
                and IP address for analytics.
              </li>
            </ul>
          </div>

          <div className="section">
            <div className="section-title">How We Use Your Data:</div>
            <ul>
              <li>
                To personalize your experience and recommend relevant content.
              </li>
              <li>
                To send notifications about deals, updates, and platform
                changes.
              </li>
              <li>To improve platform performance and troubleshoot issues.</li>
            </ul>
          </div>

          <div className="section">
            <div className="section-title">Sharing Your Data:</div>
            <ul>
              <li>
                <strong>With Brands:</strong> Only when you interact with their
                deals.
              </li>
              <li>
                <strong>With Third Parties:</strong> For analytics and
                advertising, under strict confidentiality.
              </li>
              <li>
                <strong>With Law Enforcement:</strong> Only when required by
                law.
              </li>
            </ul>
          </div>

          <div className="section">
            <div className="section-title">Cookies and Tracking:</div>
            <p>
              We use cookies to enhance your experience. You can manage cookie
              preferences in your browser settings.
            </p>
          </div>

          <div className="section">
            <div className="section-title">Data Security:</div>
            <p>
              Your data is stored securely, and we implement measures to protect
              it from unauthorized access.
            </p>
          </div>

          <div className="section">
            <div className="section-title">Your Rights:</div>
            <ul>
              <li>You can update or delete your account at any time.</li>
              <li>You can request access to the data we store about you.</li>
            </ul>
          </div>

          <div className="section">
            <div className="section-title">Changes to the Privacy Policy:</div>
            <ul>
              <li>You can update or delete your account at any time.</li>
              <li>You can request access to the data we store about you.</li>
            </ul>
          </div>
          <p className="eamil-us-sec">
            If you have concerns about your privacy, email us at
            <a>support@onlydrops.com</a>
          </p>
        </section>
      </div>
    </PrivacyPolicy>
  );
};

export default PrivacyPoicy;

export const PrivacyPolicy = styled.div`
  .privacy-poicy-header {
    display: flex;
    margin: 28px 0px 28px;
    gap: 8px;
    align-items: center;
    color: var(--Black, #000);
    font-family: "AvenirNextMedium";
    font-size: 20px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    cursor: pointer;
  }
  section {
    margin-bottom: 75px;
  }
  .section-title {
    color: var(--www-videoshops-com-black, var(--color-black-solid, #000));
    font-family: "AvenirNextBold";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
  }
  .section li {
    color: var(--www-videoshops-com-black, var(--color-black-solid, #000));
    font-family: "AvenirNextRegular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .section bold {
    font-family: "AvenirNextMedium";
  }
  p {
    color: var(--www-videoshops-com-black, var(--color-black-solid, #000));
    font-family: "AvenirNextRegular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-bottom: 24px;
  }
  .eamil-us-sec a {
    color: var(--Videoshops-Purple, #762da0);
    font-family: "AvenirNextRegular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;
