import { Modal } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "../../images/close-icon.svg";
import "./wishlist.css";
import { AddWishListModalStyled } from "./AddWishlistModalStyled.styled";
import PrimaryButton from "../../components/Common/PrimaryButton/PrimaryButton";
import { ReactComponent as AddIcon } from "../../images/add-icon.svg";
import CreateWishListModal from "./CreateWishListModal/CreateWishListModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as AddBlackIcon } from "../../images/add-black-icon.svg";
import { ReactComponent as DefaultImage } from "../../images/default-img.svg";
import {
  createWishlist,
  getWishListByUserId,
  updateWishList,
} from "../../redux/slices/app/appService";
import ConfirmationModal from "../../components/Common/ConfirmationModal/ConfirmationModal";

type WishListModalProps = {
  showWishlistModal: boolean;
  handleClose: any;
  setShowWishListModal?: any;
  productId?: any;
  setProductId?: any;
  videoDetails?: any;
};
const AddWishListModal: React.FC<WishListModalProps> = ({
  showWishlistModal,
  handleClose,
  setShowWishListModal,
  productId,
  setProductId,
  videoDetails,
}) => {
  const {
    app: { getWishList },
    auth: { user },
  } = useSelector((state: any) => state);

  const dispatch = useDispatch();

  const [showCreatewishListModal, setShowCreatewishListModal] = useState(false);
  const [selectedWishList, setSelectedWishList]: any = useState<any[]>([]);
  const [confirmModalOpen, setIsConfirmModalOpen] = useState(false);

  useEffect(() => {
    if (showWishlistModal) {
      // Ensure getWishList is an array before calling filter
      const preSelectedItems =
        (getWishList?.length &&
          getWishList?.filter((wishlistItem: any) =>
            wishlistItem?.productIds?.some(
              (product: any) => product?.productId === productId
            )
          )) ||
        []; // Default to an empty array if getWishList is null/undefined

      setSelectedWishList(preSelectedItems);
    }
  }, [showWishlistModal, getWishList, productId]);

  const handleCloseModal = () => {
    setShowCreatewishListModal(!showCreatewishListModal);
  };

  const handleCheckboxChange = (item: any) => {
    let obj = { ...item, videoId: videoDetails?._id };
    if (
      selectedWishList.some(
        (selectedItem: any) => selectedItem._id === item._id
      )
    ) {
      setSelectedWishList(
        selectedWishList.filter(
          (selectedItem: any) => selectedItem._id !== item._id
        )
      );
    } else {
      setSelectedWishList([...selectedWishList, obj]);
    }
  };

  const handleAddProductToWishList = async () => {
    let array: any = [];

    selectedWishList.map((item: any) => {
      array.push(item?._id);
    });
    let json = {
      productIds: {
        productId: productId,
        videoId: videoDetails?._id,
      },
      wishListIds: array,
      userId: user._id,
    };

    let res: any = await dispatch(updateWishList(json));

    if (res.payload) {
      await dispatch(getWishListByUserId(user._id));
    }
    setShowWishListModal(false);
    setIsConfirmModalOpen(true);
  };

  const handleModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  return (
    <>
      <CreateWishListModal
        show={showCreatewishListModal}
        setShowWishListModal={setShowWishListModal}
        hide={handleCloseModal}
      />
      <ConfirmationModal
        modalHeader={"Product Added to Wishlist!"}
        show={confirmModalOpen}
        onHide={handleModalClose}
        description="Your favorite item has been saved to your wishlist. Access it anytime from the Wishlist section."
        confirmLabel="Okay"
        onConfirmClick={() => {
          handleModalClose();
          setSelectedWishList([]);
        }}
      />
      <Modal show={showWishlistModal} centered className="add-wishlist-modal">
        <AddWishListModalStyled>
          <Modal.Header className="wishlist-header">
            <h2>Add to Wishlist</h2>
            <CloseIcon onClick={() => handleClose()} />
          </Modal.Header>
          <Modal.Body
            className={`wishlist-body ${
              getWishList?.length ? "wishlist-items-body" : ""
            }`}
          >
            {getWishList?.length ? (
              <>
                <div
                  className="create-sec"
                  onClick={() => {
                    setShowWishListModal(false);
                    setShowCreatewishListModal(true);
                  }}
                >
                  <div className="image-container">
                    <AddBlackIcon />
                  </div>
                  <p>Create Wishlist</p>
                </div>
                <div className="w-100">
                  {getWishList.map((item: any, index: number) => (
                    <div key={index} className="wishlist-list">
                      <div className="wishlist-image-col">
                        <div className="image-container">
                          <DefaultImage />
                        </div>
                        <div className="wishlist-folder-sec">
                          <p>{item?.wishlistName}</p>
                          <span>{item?.productIds?.length} Products</span>
                        </div>
                      </div>
                      <div className="wishlist-input-col">
                        <input
                          type="checkbox"
                          checked={selectedWishList.some(
                            (selectedItem: any) => selectedItem._id === item._id
                          )}
                          onChange={() => handleCheckboxChange(item)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="add-button">
                  <PrimaryButton
                    label="Add"
                    onClick={() => {
                      handleAddProductToWishList();
                    }}
                    disabled={!selectedWishList.length}
                  />
                </div>
              </>
            ) : (
              <>
                <p>
                  Save the products you love for later! Add items to your
                  wishlist and access them here anytime.
                </p>
                <PrimaryButton
                  label="Create Wishlist"
                  icon={<AddIcon />}
                  iconPosition={"left"}
                  onClick={() => {
                    setShowWishListModal(false);
                    setShowCreatewishListModal(true);
                  }}
                  className="create-wishlist-button"
                ></PrimaryButton>
              </>
            )}
          </Modal.Body>
        </AddWishListModalStyled>
      </Modal>
    </>
  );
};

export default AddWishListModal;
