import ContentLoader from "react-content-loader";

const ProductCardLoader = () => (
  <div className="grid grid-cols-4 gap-4">
    {[...Array(4)].map((_, index) => (
      <ContentLoader
        key={index}
        speed={2}
        width={250}
        height={450}
        viewBox="0 0 250 450"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        {/* Image Placeholder */}
        <rect x="20" y="20" rx="10" ry="10" width="210" height="230" />

        {/* Price Placeholder */}
        <rect x="20" y="270" rx="4" ry="4" width="80" height="20" />
        <rect x="120" y="270" rx="4" ry="4" width="100" height="16" />

        {/* Description Placeholder */}
        <rect x="20" y="310" rx="4" ry="4" width="180" height="14" />
        <rect x="20" y="335" rx="4" ry="4" width="200" height="14" />

        {/* Button Placeholder */}
        <rect x="20" y="380" rx="20" ry="20" width="200" height="40" />
      </ContentLoader>
    ))}
  </div>
);

export default ProductCardLoader;
