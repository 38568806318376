import Comments from "./Comments";
import { useEffect } from "react";
import "./filtercanvas.css";
import styled from "styled-components";
import { Drawer } from "antd";

const FilterCanvas = ({ show, setFilterShow }: any) => {
  useEffect(() => {
    // Disable scrolling on the body when the menu is open
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    // Clean up on component unmount
    return () => {
      document.body.style.overflow = "";
    };
  }, [show]);

  return (
    <CommentsModal>
      {show && (
        <div
          className="offcanvas-backdrop"
          onClick={() => setFilterShow(false)}
        ></div>
      )}
      <Drawer
        placement={"right"}
        width={500}
        onClose={() => {
          setFilterShow(!show);
        }}
        open={show}
        closeIcon={null}
        className="pro_filter_canvas"
      >
        <CommentsModal>
          <div className="fl_heading_sec">
            <div className="first_heading_wrap">
              <p className="fl_heading_h1">Comments</p>
            </div>
          </div>
          <Comments />
        </CommentsModal>

        {/* </OffCanvasMenu> */}
      </Drawer>
    </CommentsModal>
  );
};

export default FilterCanvas;

export const CommentsModal = styled.div`
  .fl_heading_sec {
    padding: 24px;
    padding-bottom: 0px;
  }
  .fl_heading_h1 {
    color: var(--Shade-100, #000);
    font-family: "AvenirNextBold";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 108.333% */
    border-bottom: 1px solid #eceeef;
    padding-bottom: 24px;
  }
  .comments-send-section {
    display: flex;
    padding: 16px;
    gap: 12px;
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 500px;
    input {
      width: 100%;
      padding: 10px 16px;
      border-radius: 10px;
      border: 1px solid #cfd3d7;
      background: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
    }
    input::placeholder {
      color: var(--Neutral-400, #bfc5c9);
    }
    .send-icon {
      padding: 10px 24px;
      border-radius: 9px;
      cursor: pointer;
      background: var(--Videoshops-Red, #762da0);
    }
  }
  .ant-drawer-body {
    padding: 0px;
  }
  .comments-section {
    margin-bottom: 32px;
    .comment-profile {
      display: flex;
      gap: 12px;
      margin-bottom: 16px;

      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 24px;
      }
      .profile-name {
        display: flex;
        flex-direction: column;
        gap: 4px;
        p {
          margin-bottom: 0px;
          color: #202020;
          font-family: "AvenirNextMedium";
          font-size: 14px;
          font-style: normal;
          font-weight: 450;
          line-height: normal;
        }
        span {
          color: #202020;
          font-family: "AvenirNextRegular";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
  .comment-text {
    color: #202020;
    font-family: "AvenirNextMedium";
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 20px; /* 142.857% */
  }
  .comment-profile .first-letter-text {
    width: 40px;
    height: 40px;
  }
  .comments-body {
    padding: 24px;
    max-height: 70vh;
    overflow-y: scroll;
    scrollbar-width: none;
  }
  .reply-text {
    color: #212121;
    font-family: "AvenirNextMedium";
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
  }

  .no-comments-sec {
    height: 80vh;
    display: flex;
    align-items: center;
    padding: 80px;
    p {
      color: #4b5157;
      text-align: center;
      font-feature-settings: "liga" off, "clig" off;
      font-family: "AvenirNextRegular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
  .user_name_pop {
    position: absolute;
    left: 28px;
    color: var(--Videoshops-Red, #762da0);
    top: 24px;
    font-family: "AvenirNextMedium";
    font-size: 14px;
    font-weight: 450;
    line-height: 17.15px;
    text-align: left;
  }
  .comments-section .comment-profile .profile-name .influencer-text {
    font-family: "AvenirNextMedium";
    font-size: 11px;
    font-weight: 450;
    line-height: 12px;
    color: #fff;
    background: #762da0;
    padding: 4px 16px;
    border-radius: 6px;
    margin-left: 12px;
  }
  .reply-to-text {
    font-family: "AvenirNextMedium";
    font-size: 14px;
    font-weight: 450;
    line-height: 20px;
    text-align: left;
    color: #762da0;
    margin-right: 8px;
  }
  .reply-comment {
    margin-left: 54px;
    margin-top: 26px;
  }

  @media (max-width: 575.98px) {
    .comments-send-section {
      position: static;
      width: 100%;
    }
  }
`;
