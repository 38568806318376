import { useDispatch, useSelector } from "react-redux";
import { ExploreByBrandStyled } from "../ExploreByBrands/ExploreByBrandStyled.styled";
import { ReactComponent as RightArrow } from "../../images/arrow-right-s-line.svg";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getAllMerchantsApi,
  getAllVideosAPI,
} from "../../redux/slices/app/appService";
import { ReactComponent as BackIcon } from "../../images/left-arrow.svg";
import ImageCardLoader from "./BrandsLoader";
import { getBrandLogoUrl } from "../../Utills/utils";
import { getUrlSlug } from "../../library/Requests/helpers";

const Brands = () => {
  const { merchantByCategory, getAllVideos } = useSelector(
    (state: any) => state.app
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [merchants, setMerchants] = useState([]);

  useEffect(() => {
    handleExploreBrands();
  }, []);

  useEffect(() => {
    handleGetAllVideos();
  }, []);

  const handleGetAllVideos = async () => {
    await dispatch(getAllVideosAPI({}));
  };

  const handleExploreBrands = async () => {
    await setLoading(true);
    await dispatch(getAllMerchantsApi({}));
    await setLoading(false);
  };

  useEffect(() => {
    const videoMerchants = getAllVideos.reduce((acc: any[], video: any) => {
      if (!acc.includes(video.brand.id)) {
        acc.push(video.brand.id);
      }
      return acc;
    }, []);
    let array: any = [];
    merchantByCategory.map((item: any, index: any) => {
      if (videoMerchants.includes(item?.merchantId)) {
        array.push(item);
      }
    });
    setMerchants(array);
  }, [merchantByCategory, getAllVideos]);

  function hasDuplicateBrand(id: any) {
    let brandArray = getAllVideos.flatMap((item: any) => item?.brand || []);

    const count = brandArray.filter(
      (merchant: any) => merchant.id === id.merchantId
    ).length;

    if (count > 1) {
      const formattedName = getUrlSlug(id.merchantInfo?.name);
      history.push(`/brands-category/${formattedName}/${id.merchantId}`);
    } else {
      let [video] = getAllVideos.filter(
        (item: any) => item?.brand.id === id.merchantId
      );
      const formattedName = getUrlSlug(video?.CreatorDetails?.Name); // Replace spaces with "-"
      const formattedTitle = getUrlSlug(video?.VideoTitle); // Replace spaces with "-"
      history.push(`/${formattedName}/${formattedTitle}`);
      // history.push(`/video/${formattedName}/${formattedTitle}/${video?._id}`);
    }
  }

  console.log(merchants, "merchants");
  return (
    <ExploreByBrandStyled>
      <div
        className="back-sec"
        onClick={() => {
          history.goBack();
        }}
      >
        <BackIcon />
        Back
      </div>
      <div className="explore-brand-header mt-4">
        <h2>All Brands</h2>
      </div>
      {!loading ? (
        <div className="explore-brand-cards">
          {merchants?.map((category: any) => (
            <div className="category-card" key={category.name}>
              <div>
                <img
                  src={getBrandLogoUrl(
                    category?.merchantId,
                    category.merchantInfo?.logoUrl
                  )}
                  alt="background"
                  className="category-image"
                />
              </div>
              <div
                className="browse-button"
                onClick={() => {
                  hasDuplicateBrand(category);
                }}
              >
                Browse Creators <RightArrow />{" "}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <ImageCardLoader />
      )}
    </ExploreByBrandStyled>
  );
};

export default Brands;
