import { Modal } from "antd";
import { ReactComponent as CloseIcon } from "../../../images/close-icon.svg";
import React from "react";
import styled from "styled-components";
import "./resetModal.css";
import { Col } from "react-bootstrap";
import PrimaryButton from "../../../components/Common/PrimaryButton/PrimaryButton";

type ResetModalProps = {
  isOpen: boolean;
};

const ResetModal: React.FC<ResetModalProps> = ({ isOpen }) => {
  return (
    <Modal open={isOpen} closeIcon={null} footer={null} className="reset-modal">
      <ResetModalStyled>
        <div>
          <header className="reset-header">
            <h3>Reset password</h3>
            <CloseIcon />
          </header>
          <section className="password-input-sec">
            <Col md={12} className="input_field mb-3">
              <label>Old Password</label>
              <input
                type="text"
                placeholder="Enter Old Password"
                // value={values?.fullName}
                maxLength={60}
                // onChange={(e: any) =>
                // //   handleChangeValue("fullName", e.target.value)
                // }
              ></input>
            </Col>

            <Col md={12} className="input_field mb-3">
              <label>New Password</label>
              <input
                type="text"
                placeholder="Enter New Password*"
                // value={values?.fullName}
                maxLength={60}
                // onChange={(e: any) =>
                // //   handleChangeValue("fullName", e.target.value)
                // }
              ></input>
            </Col>
            <Col md={12} className="input_field">
              <label>Confirm Password</label>
              <input
                type="text"
                placeholder="Enter Password*"
                // value={values?.fullName}
                maxLength={60}
                // onChange={(e: any) =>
                // //   handleChangeValue("fullName", e.target.value)
                // }
              ></input>
            </Col>
          </section>
          <div className="reset-pass-btn">
            <PrimaryButton
              label="Reset Password"
              onClick={() => {}}
            ></PrimaryButton>
          </div>
        </div>
      </ResetModalStyled>
    </Modal>
  );
};

export default ResetModal;

export const ResetModalStyled = styled.div`
  .reset-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 16px 20px;
    background: #fff;
    box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.25);
    h3 {
      color: #000;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 0px;
    }
  }
  .password-input-sec {
    padding: 24px;
    .input_field {
      display: flex;
      flex-direction: column;
      padding: 0px label {
        color: var(--Shade-100, #000);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
      }
      input {
        border-radius: 10px;
        border: 1px solid #cfd3d7;
        background: #fff;
        padding: 14px 16px;
      }
    }
  }
  .password-input-sec .input_field label::after {
    content: "*";
    color: red;
    margin-left: 4px;
  }
  .reset-pass-btn {
    width: 100%;
    padding: 24px;
    padding-top: 0px;
    button {
      width: 100%;
      justify-content: center;
    }
  }
`;
