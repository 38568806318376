import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  generateDeepLinkAPI,
  getAllCategoriesAPI,
  getAllCategoriesByBrandsAPI,
  GetAllComments,
  getAllMerchantsApi,
  getAllProductsAPI,
  getAllVideosAPI,
  getFollowercountApi,
  getProductByWishList,
  getProductDetailsList,
  getVideoDetails,
  getWishListByUserId,
} from "./appService";

export interface AppState {
  loading: boolean;
  showBackButton: boolean;
  allMerchantsList: any[];
  error: any;
  productList: any[];
  categoriesList?: any[];
  categoriesListByBrands?: any[];
  commmentsByVideoId?:any[];
  videoDetails?:any[];
  productDetails?:any[];
  productDetailsCount?:number;
  getAllVideos?:any[];
  getWishList?:any[];
  getWishListProducts?:any[];
  followerCount?:any;
  merchantByCategory?:any;
}

const initialState: AppState = {
  loading: false,
  showBackButton: false,
  allMerchantsList: [],
  error: {},
  productList: [],
  categoriesList: [],
  categoriesListByBrands: [],
  commmentsByVideoId:["","",""],
  videoDetails:[],
  productDetails:[],
  getAllVideos:[],
  getWishList:[],
  getWishListProducts:[],
  followerCount:0,
  productDetailsCount:0,
  merchantByCategory:[],
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state: AppState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setShowBackButton: (state: AppState, action: PayloadAction<boolean>) => {
      state.showBackButton = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    // All brands
    // builder.addCase(getAllMerchantsAPI.pending, (state: any) => {
    //   state.loading = true;
    //   state.error = null;
    // });
    // builder.addCase(
    //   getAllMerchantsAPI.fulfilled,
    //   (state: AppState, action: PayloadAction<any>) => {
    //     state.error = null;
    //     state.loading = false;
    //     state.allMerchantsList = action.payload?.data;
    //   }
    // );
    // builder.addCase(
    //   getAllMerchantsAPI.rejected,
    //   (state: AppState, action: any) => {
    //     state.loading = false;
    //   }
    // );

    // Deep Links
    builder.addCase(generateDeepLinkAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      generateDeepLinkAPI.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
      }
    );
    builder.addCase(
      generateDeepLinkAPI.rejected,
      (state: AppState, action: any) => {
        state.loading = false;
      }
    );

    // All Products
    builder.addCase(getAllProductsAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllProductsAPI.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.productList = action.payload?.products;
      }
    );
    builder.addCase(
      getAllProductsAPI.rejected,
      (state: AppState, action: any) => {
        state.loading = false;
      }
    );

    // All Categories
    builder.addCase(getAllCategoriesAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllCategoriesAPI.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.categoriesList = action?.payload?.data[0]?.category[0]?.children?.category;
      }
    );
    builder.addCase(
      getAllCategoriesAPI.rejected,
      (state: AppState, action: any) => {
        state.loading = false;
      }
    );

    // All Categories by brands
    builder.addCase(getAllCategoriesByBrandsAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllCategoriesByBrandsAPI.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.categoriesListByBrands = action?.payload?.categories;
      }
    );
    builder.addCase(
      getAllCategoriesByBrandsAPI.rejected,
      (state: AppState, action: any) => {
        state.loading = false;
      }
    );


    // get All comments

    builder.addCase(GetAllComments.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      GetAllComments.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.commmentsByVideoId = action?.payload;
      }
    );
    builder.addCase(
      GetAllComments.rejected,
      (state: AppState, action: any) => {
        state.loading = false;
        state.commmentsByVideoId=[]
      }
    );

    //get video details

    builder.addCase(getVideoDetails.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getVideoDetails.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.videoDetails = action.payload[0]
        
      }
    );
    builder.addCase(
      getVideoDetails.rejected,
      (state: AppState, action: any) => {
        state.loading = false;
      }
    );

    builder.addCase(getProductDetailsList.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getProductDetailsList.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.productDetails = action.payload?.products;
        state.productDetailsCount=action.payload.count;
      }
    );
    builder.addCase(
      getProductDetailsList.rejected,
      (state: AppState, action: any) => {
        state.loading = false;
      }
    );


    // get all videos
    builder.addCase(getAllVideosAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllVideosAPI.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.getAllVideos = action.payload;
        
      }
    );
    builder.addCase(
      getAllVideosAPI.rejected,
      (state: AppState, action: any) => {
        state.loading = false;
      }
    );


    // getAll WishList 

        builder.addCase(getWishListByUserId.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getWishListByUserId.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.getWishList = action.payload;
        
      }
    );
    builder.addCase(
      getWishListByUserId.rejected,
      (state: AppState, action: any) => {
        state.loading = false;
      }
    );
    // getAll WishList products

        builder.addCase(getProductByWishList.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getProductByWishList.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.getWishListProducts = action.payload;
        
      }
    );
    builder.addCase(
      getProductByWishList.rejected,
      (state: AppState, action: any) => {
        state.loading = false;
      }
    );

    // get follower count

        builder.addCase(getFollowercountApi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getFollowercountApi.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.followerCount = action.payload?.data;
      }
    );
    builder.addCase(
      getFollowercountApi.rejected,
      (state: AppState, action: any) => {
        state.loading = false;
      }
    );

     builder.addCase(getAllMerchantsApi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllMerchantsApi.fulfilled,
      (state: AppState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        
         state.merchantByCategory= action.payload?.list;
        
      }

    );
    builder.addCase(
      getAllMerchantsApi.rejected,
      (state: AppState, action: any) => {
        state.loading = false;
      }
    );
  },
});

export const { setLoading, setShowBackButton } = appSlice.actions;

export default appSlice.reducer;
