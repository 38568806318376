import React from "react";
import TrendingVideos from "../TrendingVideos";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useIsMobile from "../../../Utills/CheckIsMobile";

const BeautyCareSlider: React.FC = () => {
  const {
    app: { getAllVideos },
  } = useSelector((state: any) => state);
  const history = useHistory();
  const isMobile = useIsMobile();
  return (
    <BeautyCareSliderStyled>
      {getAllVideos?.length > 0 && (
        <div className="clothing-slider-sec">
          <div className="clothing-header">
            <h1>Trending Now</h1>
            {isMobile && (
              <span onClick={() => history.push("/video-libray")}>
                View all
              </span>
            )}
          </div>
          <TrendingVideos />
        </div>
      )}
    </BeautyCareSliderStyled>
  );
};

export default BeautyCareSlider;

export const BeautyCareSliderStyled = styled.div`
  .clothing-slider-sec {
    margin-top: 72px;
    .clothing-header {
      display: flex;
      justify-content: space-between;
      align-items: end;
      margin-bottom: 24px;
      span {
        font-family: "AvenirNextMedium";
        font-size: 16px;
        font-weight: 500;
        line-height: var(--lineheight20);
        text-align: right;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }
    h1 {
      margin-bottom: 0px;
      font-family: "AvenirNextSemiBold";
      font-size: 40px;
      line-height: var(--lineheight46);
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
  @media (max-width: 575.98px) {
    .clothing-slider-sec {
      margin-top: 56px;
      margin-bottom: 88px;
    }
    .clothing-slider-sec h1 {
      font-size: 20px;
      line-height: 24px;
    }
    .clothing-slider-sec .clothing-header span {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
  }
`;
