import { useState, useLayoutEffect } from "react";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(() => {
    return typeof window !== "undefined" && window.innerWidth < 769;
  });

  useLayoutEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth < 769);
    };

    // Initial check
    updateIsMobile();

    window.addEventListener("resize", updateIsMobile);
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;
