import React, { useEffect, useState } from "react";
import TopHeader from "../../pages/TopHeader/TopHeader";
// import Footer from "../Footer";
import "./layout.css";
import Footer from "../../pages/Footer/Footer";
import { useLocation } from "react-router-dom";
import useIsMobile from "../../Utills/CheckIsMobile";
const Layout = (props) => {
  const location = useLocation();

  const [hideFooter, setHideFooter] = useState(false);
  const [isVideoPage, setIsVideoPage] = useState(false);
  const currentPath = location.pathname;

  const isMobile = useIsMobile();

  useEffect(() => {
    if (currentPath) {
      let finalPathname = currentPath?.split("/");

      let [filterFinalPath] = finalPathname.filter((item) => item === "video");

      if (filterFinalPath === "video") {
        setHideFooter(true);
        setIsVideoPage(true);
      } else {
        setHideFooter(false);
        setIsVideoPage(false);
      }
    }
  }, [currentPath]);
  return (
    <div className={"wrapper-hide-header"}>
      <div>
        <div className="top-header">
          <TopHeader />
        </div>
      </div>
      <div className="layout-container full-content-sec-page-design1">
        <div
          className={`my-account-sec-bottom ${
            isMobile && isVideoPage ? "mobile-video-padding" : ""
          }`}
        >
          <div className="right-menu-side-content1">{props.children}</div>
        </div>
      </div>
      {!(hideFooter && isMobile) && <Footer />}
    </div>
  );
};

export default Layout;
