import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  InstapaperShareButton,
  WhatsappShareButton,
} from "react-share";
import { Modal } from "react-bootstrap";
import { ReactComponent as Facebook } from "../../../images/share-icons/facebook.svg";
import { ReactComponent as Twitter } from "../../../images/twitter-x-fill-share.svg";
import { ReactComponent as Whatsapp } from "../../../images/whatsapp.svg";
import { ReactComponent as Insta } from "../../../images/insta.svg";
import { ReactComponent as CopyLink } from "../../../images/copy-link.svg";
import { ReactComponent as Close } from "../../../images/close-icon.svg";
import "./sharemodal.css";
const ShareModal = ({
  shareModalOpen,
  setShareModalOpen,
  shareModalToggle,
  url,
  setCopy,
  copy,
}: any) => {
  const handleCopyLink = () => {
    const el = document.createElement("textarea");
    el.value = `${window.location.origin}${url}`;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    shareModalToggle();
    setCopy(1);
    setTimeout(() => {
      setCopy(0);
    }, 5000);
  };

  return (
    <>
      <Modal
        show={shareModalOpen}
        className="invite-modal share-modal"
        onHide={() => {
          setShareModalOpen(false);
        }}
        centered
      >
        <div className="modal-content">
          <Modal.Header className="invite-modal-header">
            Share on{" "}
            <Close
              onClick={() => {
                setShareModalOpen(false);
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <div className="share-icons-body">
              <div className="share-icons-sec">
                <FacebookShareButton
                  title={"BeRides Event"}
                  url={`${window.location.origin}${url}`}
                  hashtag={"BeRides"}
                >
                  <Facebook />
                  <p>Facebook</p>
                </FacebookShareButton>
              </div>
              <div className="share-icons-sec">
                <TwitterShareButton
                  title={"BeRides Event"}
                  url={`${window.location.origin}${url}`}
                  hashtags={["BeRides", "BeRides"]}
                >
                  <Twitter />
                  <p>Twitter</p>
                </TwitterShareButton>
              </div>
              <div className="share-icons-sec">
                <InstapaperShareButton
                  title={"BeRides Event"}
                  url={`${window.location.origin}${url}`}
                >
                  <Insta />
                  <p>Instagram</p>
                </InstapaperShareButton>
              </div>
              <div className="share-icons-sec">
                <WhatsappShareButton
                  title={"BeRides Event"}
                  url={`${window.location.origin}${url}`}
                >
                  <Whatsapp />
                  <p>WhatsApp</p>
                </WhatsappShareButton>
              </div>
              <div
                className="share-icons-sec"
                onClick={() => {
                  handleCopyLink();
                }}
              >
                <CopyLink />
                <p>Copy Link</p>
                {copy && <p>Copied</p>}
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
export default ShareModal;
