// components/Statcounter.js
import { useEffect } from "react";

declare global {
  interface Window {
    sc_project: number;
    sc_invisible: number;
    sc_security: string;
  }
}

const Statcounter = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.statcounter.com/counter/counter.js";
    script.async = true;
    document.body.appendChild(script);

    window.sc_project = 13106638;
    window.sc_invisible = 1;
    window.sc_security = "23f522c4";

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <noscript>
      <div className="statcounter">
        <a
          href="https://statcounter.com/"
          target="_blank"
          title="Web Analytics"
        >
          <img
            src="https://c.statcounter.com/13106638/0/23f522c4/1/"
            alt="Web Analytics"
            className="statcounter"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </a>
      </div>
    </noscript>
  );
};

export default Statcounter;
