import styled from "styled-components";

export const FAQStyled = styled.div`
  .faq-header {
    display: grid;
    align-items: center;
    justify-content: center;
    margin: 40px 0px;
    grid-template-columns: 50% 50%;
    padding: 0px 130px;
  }
  .faq-header-sec h2 {
    color: var(--www-videoshops-com-black, var(--color-black-solid, #000));
    font-family: "AvenirNextBold";
    font-size: var(--font-size-80, 80px);
    font-style: normal;
    font-weight: var(--font-weight-600, 600);
    line-height: var(--font-size-80, 80px); /* 100% */
    letter-spacing: var(--letter-spacing--1, -1px);
  }
  .faq-header-sec p {
    color: var(--www-videoshops-com-black, var(--color-black-solid, #000));
    font-family: "AvenirNextRegular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .faq-questions {
    ul {
      list-style-type: none;
    }
    li {
      color: var(--www-videoshops-com-black, var(--color-black-solid, #000));
      font-family: "AvenirNextMedium";
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      margin-top: 16px;

      line-height: var(--line-height-20, 20px); /* 133.333% */
      cursor: pointer;
    }
  }
  .accordian-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    border-bottom: var(--stroke-weight-1, 1px) solid
      var(--color-grey-85, #d8d8d8);
    padding-bottom: 12px;
  }
  @media (max-width: 575.98px) {
    .faq-questions {
      width: 100%;
      padding: 0px 12px;
    }
    .faq-header {
      gap: 0px;
      flex-direction: column;
      display: flex;
      padding: 0px;
    }
    .faq-questions ul {
      padding: 0px;
      /* margin-left: -24px; */
    }
    .faq-header-sec h2 {
      font-size: 32px;
      font-weight: 600;
      line-height: 47.74px;
    }
    .faq-header-sec p {
      font-size: 15px;
    }
  }
`;
