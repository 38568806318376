import React from "react";
import BsSpinner from "react-bootstrap/Spinner";

type SpinnerProps = {
  isVisible: boolean;
  size?: any;
};
const Spinner: React.FC<SpinnerProps> = ({ isVisible, size }) => {
  return isVisible ? <BsSpinner animation="border" size={size} /> : null;
};

export default Spinner;
