import { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../images/OnlyDrops.svg";
import Logos from "../images/Social links-comingsoon.png";
import CommingSoon from "../images/Cominsoon-Right side image.png";
import { ComingSoonStyled } from "./ComingSoonStyled.styled";

const ComingSoon = () => {
  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [sucessMessage, setSucessMessage] = useState("");

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (val: string) => {
    setEmail(val);
    // Validate email on change
    if (!validateEmail(val)) {
      setEmailErrorMessage("Please enter a valid email address");
    } else {
      setEmailErrorMessage(""); // Clear error message if valid
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSucessMessage("");
    }, 8000);
  }, [sucessMessage]);

  return (
    <ComingSoonStyled>
      <div className="coming-banner">
        <div className="coming-soon-left-sec">
          <Logo className="logo-body" />
          <div className="user-suscribe-sec">
            <h1>🚀 Something Exciting is Coming!</h1>
            <p>We’re working on something amazing. Stay tuned!</p>
            <h2>Get notified when we get live!</h2>
            <div>
              <label>Email Address</label>
              <input
                type="email"
                placeholder="Enter Email Address"
                onChange={(e: any) => handleEmailChange(e.target.value)}
                value={email}
              />
              {emailErrorMessage && (
                <p className="email-error-messgae">{emailErrorMessage}</p>
              )}
              {sucessMessage && (
                <p className="email-success-messgae">{sucessMessage}</p>
              )}
            </div>
            <button
              disabled={emailErrorMessage ? true : false}
              onClick={() => {
                setEmail("");
                setSucessMessage(
                  "Thanks for joining us! You will be notified when we are live."
                );
              }}
            >
              Subscribe
            </button>
          </div>
          <div className="footer-logo-sec">
            <img src={Logos} alt="social links" />
            <p>Copyright © 2025 onlyDrops</p>
          </div>
        </div>
        <div className="coming-soon-right-sec">
          <img src={CommingSoon} alt="Coming Soon" />
        </div>
      </div>
    </ComingSoonStyled>
  );
};

export default ComingSoon;
