import styled from "styled-components";

export const TrendingVideosStyled = styled.div`
  .slick-slide > div,
  .homepage-slider-cards {
    display: grid;
    column-gap: 1.25%;
    row-gap: 2.5%;
    grid-template-columns: 19% 19% 19% 19% 19%;
  }
  .carousel-wrap {
    position: relative;
  }
  .left_arrow_btn {
    position: absolute;
    top: 50%;
    left: -24px;
    transform: translateY(-50%);
    background: #fff;
    padding: 10px;
    border-radius: 16px;
    cursor: pointer;
    width: 48px;
    height: 48px;
    z-index: 99;
    border: none;
    box-shadow: 0px 0px 36px 0px #00000021;
  }
  .right_arrow_btn {
    position: absolute;
    top: 50%;
    right: -24px;
    left: auto;
    transform: translateY(-50%);
    background: #fff;
    padding: 10px;
    border-radius: 16px;
    cursor: pointer;
    width: 48px;
    height: 48px;
    z-index: 99;
    border: none;
    box-shadow: 0px 0px 36px 0px #00000021;
  }
  .view-all-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    cursor: pointer;
    .button {
      background: #762da0;
      color: #fff;
      font-family: "AvenirNextSemiBold";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
      letter-spacing: 0.028px;
      text-transform: uppercase;
      display: flex;
      gap: 8px;
      padding: 8px 24px;
      border-radius: 8px;
      flex-direction: row;
      box-shadow: none;
      margin-top: 48px;
    }
  }
  @media (max-width: 575.98px) {
    .homepage-slider-cards {
      display: grid;
      column-gap: 3%;
      grid-template-columns: 48.5% 48.5%;
    }
  }
`;
