import { useHistory } from "react-router-dom";
import styled from "styled-components";
import EyeIcon from "../../images/Reel-logos/eye-icon.svg";
import { getBrandLogoUrl, getRelativeTime } from "../../Utills/utils";
import DefaultProfileImage from "../../Utills/DefaultProfile";
import VideoPlayer from "../../components/VideoPlayer";
import { useEffect, useRef } from "react";
import { getUrlSlug } from "../../library/Requests/helpers";

const VideoCard = (props: any) => {
  const { video, profileVideo, livestream } = props;
  const history = useHistory();

  const navigatetoVideo = (name: any, title: any) => {
    const formattedName = getUrlSlug(name); // Replace spaces with "-"
    const formattedTitle = getUrlSlug(title); // Replace spaces with "-"
    history.push(`/${formattedName}/${formattedTitle}`);
    // history.push(`/video/${formattedName}/${formattedTitle}/${video?._id}`);
  };

  const videoRef: any = useRef();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current
        .play()
        .catch((error: any) => console.error("Autoplay failed:", error));
    }
  }, []);

  return (
    <VideoCardStyled>
      <div
        className="video-card"
        onClick={() =>
          navigatetoVideo(video?.CreatorDetails?.Name, video?.VideoTitle)
        }
      >
        <VideoPlayer
          className="card-video"
          playOnHover
          mouseOver={true}
          url={video?.CDNUrl}
          thumb={video?.ThumbnailImageUrl}
        />

        {/* <video
          ref={videoRef}
          src={video?.CDNUrl}
          muted
          playsInline
          loop
          autoPlay
          style={{ width: "100%", cursor: "pointer" }}
        /> */}
        {/* <video
          src={video.VideoUrl}
          onClick={() =>
            history.push(`/video/${video?.VideoTitle}/${video?._id}`)
          }
        /> */}
        {/* <div className="profile-des">
          {video?.CreatorDetails?.imageUrl ? (
            <img src={video?.CreatorDetails?.imageUrl} alt="profile" />
          ) : (
            <DefaultProfileImage
              userName={video?.CreatorDetails?.userName}
              fullName={video?.CreatorDetails?.Name}
              email={video?.CreatorDetails?.Email}
            />
          )}
          <div className="video-name">
            <h3>{video?.CreatorDetails?.Name}</h3>
            <span>{getRelativeTime(video?.CreatedAt)}</span>
          </div>
        </div> */}
        {profileVideo && (
          <div className="eye-icon-sec">
            {livestream && <div className="timer-sec">01:20:10</div>}
            <div className="icon-sec">
              <img src={EyeIcon} alt="eye" />
              <p>1M</p>
            </div>
          </div>
        )}
      </div>
      <div className="video-logo">
        <img
          src={getBrandLogoUrl(video?.brand?.id, video?.brand.logo)}
          alt="logo"
        />
      </div>
    </VideoCardStyled>
  );
};

export default VideoCard;

export const VideoCardStyled = styled.div`
  .video-card {
    width: 100%;
    position: relative;
    cursor: pointer;
    aspect-ratio: 9/16;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .profile-des {
      position: absolute;
      bottom: 0px;
      width: 100%;
      display: flex;
      gap: 10px;
      color: white;
      background-color: rgba(0, 0, 0, 0.3);
      padding: 5px 10px;
      border-radius: 5px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        object-fit: cover;
      }

      .video-name {
        color: #fff;
        h3 {
          font-family: "AvenirNextRegular";
          font-size: 16px;
          font-weight: 500;
          line-height: var(--lineheight20);
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          margin: 0;
        }
        span {
          font-family: "AvenirNextLight";
          font-size: 12px;
          font-weight: 400;
          line-height: var(--lineheight20);
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
      }
    }
  }
  .eye-icon-sec {
    position: absolute;
    top: 16px;
    display: flex;
    align-items: center;
    right: 16px;
    gap: 8px;
    width: 85%;
    justify-content: space-between;
    .timer-sec {
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0.002em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #fff;
      backdrop-filter: blur(52px);
      background: #21212129;
      border-radius: 40px;
      padding: 5px 12px;
    }
    .icon-sec {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    p {
      margin-bottom: 0px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #fff;
    }
  }
  .video-logo img {
    width: 100%;
    height: 24px;
    object-fit: contain;
  }
  @media (max-width: 575.98px) {
    .video-card .profile-des img {
      width: 24px;
      height: 24px;
    }
    .video-card .profile-des .video-name {
      display: flex;
      flex-direction: column;
    }
    .video-card .profile-des .video-name h3 {
      font-size: 11px;
    }
    .video-card .profile-des .video-name span {
      font-size: 8px;
    }
  }
`;
