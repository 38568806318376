import React, { useEffect, useState } from "react";
import { ProductCardStyled } from "./ProductCardStyled.styled";
import { ReactComponent as Like } from "../../images/Heart-line.svg";
import { ReactComponent as Liked } from "../../images/Heart-fill.svg";
import { ReactComponent as RightArrow } from "../../images/arrow-right-s-line.svg";
import { ReactComponent as ELF } from "../../images/elf-image.svg";
import AddWishListModal from "../WishList/AddWishListModal";
import { useSelector } from "react-redux";
import LoginModal from "../TopHeader/LoginModal";

type ProductProps = {
  item?: {};
  videoDetails?: any;
};

const ProductCard: React.FC<ProductProps> = (props: any) => {
  const {
    app: { getWishList },
    auth: { user },
  } = useSelector((state: any) => state);
  const { item, videoDetails } = props;
  const [showWishListModal, setShowWishListModal]: any = useState(false);
  const [productId, setProductId] = useState("");
  const [productExists, setproductExists] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [urlPath, setUrlPath] = useState("");
  const [redirectionUrl, setRedirectionUrl] = useState("");

  const handleClose = () => {
    setShowWishListModal(!showWishListModal);
  };

  useEffect(() => {
    if (getWishList?.length) {
      const productIds = getWishList?.flatMap((item: any) =>
        item.productIds.map((product: any) => product.productId)
      );

      // Step 2: Check if the target productId exists in the array
      setproductExists(productIds.includes(item?._id));
    }
  }, [getWishList]);

  return (
    <ProductCardStyled>
      <LoginModal
        show={loginModal}
        onHide={() => {
          setLoginModal(false);
        }}
        urlPath={urlPath}
        setUrlPath={setUrlPath}
        isDealDisplay={false}
        redirectionUrl={redirectionUrl}
      />
      <AddWishListModal
        showWishlistModal={showWishListModal}
        handleClose={handleClose}
        setShowWishListModal={setShowWishListModal}
        productId={productId}
        setProductId={setProductId}
        videoDetails={videoDetails}
      />
      <div className="product-card">
        <div className="product-image-sec">
          <img src={item?._source?.images?.image[0]?.value} alt="product" />
          <div
            className="like-button"
            onClick={() => {
              if (user?._id) {
                setShowWishListModal(true);
                setProductId(item?._id);
              }
            }}
          >
            {!productExists ? <Like /> : <Liked />}
          </div>
        </div>
        <div className="product-price-sec">
          <div>
            <p className="price-value">
              {item?._source?.price?.value}
              <span>Sale</span>
            </p>
            <p className="reg-price">
              Reg. Price <span>{item?._source?.originalPrice?.value}</span>
            </p>
            <p className="product-name">{item?._source?.title}</p>
          </div>
          <button
            onClick={() => {
              if (user?._id) {
                window.open(`${item?._source?.url?.value}`);
              } else {
                setUrlPath("/login");
                setLoginModal(true);
                setRedirectionUrl(`${item?._source?.url?.value}`);
              }
            }}
          >
            GRAB DEAL
            <RightArrow />
          </button>
        </div>
      </div>
    </ProductCardStyled>
  );
};

export default ProductCard;
