import React, { useState } from "react";
import { ReactComponent as Like } from "../../images/Reel-logos/like.svg";
import { ReactComponent as Liked } from "../../images/Heart-fill.svg";
import { ReactComponent as Comment } from "../../images/Reel-logos/comment.svg";
import { ReactComponent as Share } from "../../images/Reel-logos/share.svg";
import { ReactComponent as Report } from "../../images/Reel-logos/report.svg";
import { ReactComponent as BannerIcon } from "../../images/Brand&Offers Icon.svg";
import { ReactComponent as Facebook } from "../../images/share-icons/facebook.svg";
import { ReactComponent as Twitter } from "../../images/twitter-x-fill-share.svg";
import { ReactComponent as Whatsapp } from "../../images/whatsapp.svg";
import { ReactComponent as Insta } from "../../images/insta.svg";
import { ReactComponent as CopyLink } from "../../images/copy-link.svg";
import FilterCanvas, { CommentsModal } from "./CommentsFilter";
import ShareModal from "../../components/Common/ShareModal/ShareModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getVideoDetails,
  videoLikeApi,
} from "../../redux/slices/app/appService";
import useIsMobile from "../../Utills/CheckIsMobile";
import DropDownWrapModal from "../../components/Common/Mobile/MobileModel/DropDownWrapModal";
import {
  FacebookShareButton,
  InstapaperShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import Comments from "./Comments";
import ProductCard from "../ProductCard/ProductCard";
import { VideoPdpStyled } from "./VideoPdpStyled.styled";
import ConfirmationModal from "../../components/Common/ConfirmationModal/ConfirmationModal";
import LoginModal from "../TopHeader/LoginModal";
import MobileOffers from "./MobileOffers";
import { getUrlSlug } from "../../library/Requests/helpers";

type LikeShareProps = {
  commmentsByVideoId?: [];
  videoDetails?: any;
  productDetails?: any;
  shareExpandModalOpen?: any;
  setShareExpandModalOpen?: any;
  isExpanded?: any;
};

const LikeShare: React.FC<LikeShareProps> = ({
  commmentsByVideoId,
  videoDetails,
  productDetails,
  setShareExpandModalOpen,
  shareExpandModalOpen,
  isExpanded,
}) => {
  const {
    auth: { user },
  } = useSelector((state: any) => state);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [isMobileShareModalOpne, setIsMobileShareModalOpne] = useState(false);
  const [isMobileCommentsScreen, setIsMobileCommentsScreen] = useState(false);
  const [isMobileOffersScreen, setIsMobileOffersScreen] = useState(false);
  const [isMobileProductsScreen, setIsMobileProductsScreen] = useState(false);
  const [loginMessageModal, setLoginMessageModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [copy, setCopy]: any = useState();
  const [loading, setLoading] = useState(false);
  const [urlPath, setUrlPath] = useState("");

  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const shareModalToggle = () => {
    setShareModalOpen(!shareModalOpen);
  };

  const handleVideoLike = async () => {
    if (user?._id) {
      setLoading(true);
      let json = {
        userId: user?._id,
        isLiked: !videoDetails?.iLiked,
        id: videoDetails?._id,
      };
      await dispatch(videoLikeApi(json));
      let videoData = {
        videoId: videoDetails?._id,
        loginUserId: user._id || 0,
      };
      await dispatch(getVideoDetails(videoData));
      setLoading(false);
    } else {
      setLoginMessageModal(true);
    }
  };

  let copyUrl = `/${getUrlSlug(
    videoDetails?.CreatorDetails?.Name
  )}/${getUrlSlug(videoDetails?.VideoTitle)}`;
  // let copyUrl = `/video/${videoDetails?.CreatorDetails?.Name}/${videoDetails?.VideoTitle}/${videoDetails?._id}`;

  const handleCopyLink = () => {
    const el = document.createElement("textarea");
    el.value = `${window.location.origin}${copyUrl}`;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    shareModalToggle();
    setCopy(1);
    setTimeout(() => {
      setCopy(0);
    }, 5000);
  };

  return (
    <>
      <LoginModal
        show={loginModal}
        onHide={() => {
          setLoginModal(false);
        }}
        urlPath={urlPath}
        setUrlPath={setUrlPath}
        isDealDisplay={false}
      />
      <FilterCanvas show={isFilterOpen} setFilterShow={setIsFilterOpen} />
      <ShareModal
        shareModalOpen={shareModalOpen}
        shareModalToggle={shareModalToggle}
        setShareModalOpen={setShareModalOpen}
        setCopy={setCopy}
        copy={copy}
        url={`/${getUrlSlug(videoDetails?.CreatorDetails?.Name)}/${getUrlSlug(
          videoDetails?.VideoTitle
        )}`}
        // url={`/video/${videoDetails?.CreatorDetails?.Name}/${videoDetails?.VideoTitle}/${videoDetails?._id}`}
      />
      <ConfirmationModal
        modalHeader={"Log In or Sign Up to Engage!"}
        show={loginMessageModal}
        onHide={() => {
          setLoginMessageModal(false);
        }}
        description="Liking and commenting on videos is only available for registered users. Log in to your account or sign up to join the conversation and share your thoughts!"
        confirmLabel="Sign In"
        cancelLabel="Skip"
        onConfirmClick={() => {
          setLoginMessageModal(false);
          setUrlPath("/login");
          setLoginModal(true);
        }}
      />
      <div className="reel-button-sec">
        {/* {isMobile && <OfferIcon className="offers-icon" />} */}
        {isMobile && (
          <BannerIcon
            onClick={() => {
              setIsMobileOffersScreen(true);
            }}
            className="offers-icon"
          />
        )}

        {/* {isMobile && (
          <div className="reel-button-card">
            <ProductIcon onClick={() => setIsMobileProductsScreen(true)} />
          </div>
        )} */}
        <div className="reel-button-card like-coment-button">
          {!videoDetails?.iLiked ? (
            <Like
              onClick={() => {
                !loading && handleVideoLike();
              }}
            />
          ) : (
            <Liked
              onClick={() => {
                !loading && handleVideoLike();
              }}
            />
          )}
          <span>{videoDetails?.likes || 0}</span>
        </div>
        <div
          className="reel-button-card like-coment-button"
          onClick={() => {
            if (user?._id) {
              if (isMobile) {
                setIsMobileCommentsScreen(true);
              } else {
                setIsFilterOpen(!isFilterOpen);
              }
            } else {
              setLoginMessageModal(true);
            }
          }}
        >
          <Comment />
          <span>{commmentsByVideoId?.length}</span>
        </div>
        <div
          className="reel-button-card"
          onClick={() => {
            if (isExpanded) {
              setShareExpandModalOpen(!shareExpandModalOpen);
            } else if (!isMobile) {
              setShareModalOpen(true);
            } else {
              setIsMobileShareModalOpne(true);
            }
          }}
        >
          <Share />
        </div>
        <div className="reel-button-card">
          <Report />
        </div>
      </div>
      <DropDownWrapModal
        show={isMobileShareModalOpne}
        header={"Share on"}
        onHide={() => setIsMobileShareModalOpne(false)}
        modalBody={
          <>
            <div className="share-icons-body">
              <div className="share-icons-sec">
                <FacebookShareButton
                  title={"BeRides Event"}
                  url={`${window.location.origin}${copyUrl}`}
                  hashtag={"BeRides"}
                >
                  <Facebook />
                  <p>Facebook</p>
                </FacebookShareButton>
              </div>
              <div className="share-icons-sec">
                <TwitterShareButton
                  title={"BeRides Event"}
                  url={`${window.location.origin}${copyUrl}`}
                  hashtags={["BeRides", "BeRides"]}
                >
                  <Twitter />
                  <p>Twitter</p>
                </TwitterShareButton>
              </div>
              <div className="share-icons-sec">
                <InstapaperShareButton
                  title={"BeRides Event"}
                  url={`${window.location.origin}${copyUrl}`}
                >
                  <Insta />
                  <p>Instagram</p>
                </InstapaperShareButton>
              </div>
              <div className="share-icons-sec">
                <WhatsappShareButton
                  title={"BeRides Event"}
                  url={`${window.location.origin}${copyUrl}`}
                >
                  <Whatsapp />
                  <p>WhatsApp</p>
                </WhatsappShareButton>
              </div>
              <div
                className="share-icons-sec"
                onClick={() => {
                  handleCopyLink();
                }}
              >
                <CopyLink />
                <p>Copy Link</p>
                {copy && <p>Copied</p>}
              </div>
            </div>
          </>
        }
      />
      <DropDownWrapModal
        show={isMobileCommentsScreen}
        header={"Comments"}
        onHide={() => setIsMobileCommentsScreen(false)}
        modalBody={
          <CommentsModal>
            <Comments />
          </CommentsModal>
        }
      />
      <DropDownWrapModal
        show={isMobileOffersScreen}
        header={"Brand & Offers"}
        className={"offers-brand-dropdown"}
        onHide={() => setIsMobileOffersScreen(false)}
        modalBody={<MobileOffers />}
      />
      <DropDownWrapModal
        show={isMobileProductsScreen}
        header={
          productDetails?.length
            ? `Products (${productDetails.length})`
            : "Products"
        }
        onHide={() => setIsMobileProductsScreen(false)}
        modalBody={
          <VideoPdpStyled>
            <section className="product-card-sec">
              {productDetails?.map((item: any, index: number) => (
                <div key={index}>
                  <ProductCard item={item} videoDetails={videoDetails} />
                </div>
              ))}
            </section>
          </VideoPdpStyled>
        }
      />
    </>
  );
};

export default LikeShare;
