import { ExploreByBrandStyled } from "./ExploreByBrandStyled.styled";
import Brand1 from "../../images/Brands/Brooks_Brothers_logo.svg";
import Brand2 from "../../images/Brands/Lucky_Brand_Logo.svg";
import Brand3 from "../../images/Brands/Boston_Proper_brand_logo_-_Current 1.svg";
import Brand4 from "../../images/Brands/skechers-seeklogo.svg";
import Brand5 from "../../images/Brands/epson-2.svg";
import Brand6 from "../../images/Brands/crocs-seeklogo.svg";
import Brand7 from "../../images/Brands/adam-eve-seeklogo.svg";
import Brand8 from "../../images/Brands/brylane-home-logo-vector-2022.svg";
import { ReactComponent as RightArrow } from "../../images/arrow-right-s-line.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAllMerchantsApi } from "../../redux/slices/app/appService";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ImageCardLoader from "../Brands/BrandsLoader";
import { getBrandLogoUrl } from "../../Utills/utils";
import useIsMobile from "../../Utills/CheckIsMobile";
import { getUrlSlug } from "../../library/Requests/helpers";

const ExploreByBrand = () => {
  const { merchantByCategory, getAllVideos } = useSelector(
    (state: any) => state.app
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(false);
  const [merchants, setMerchants] = useState([]);
  useEffect(() => {
    handleExploreBrands();
  }, []);

  const handleExploreBrands = async () => {
    await setLoading(true);
    await dispatch(getAllMerchantsApi({}));
    await setLoading(false);
  };

  useEffect(() => {
    const videoMerchants = getAllVideos.reduce((acc: any[], video: any) => {
      if (!acc.includes(video.brand.id)) {
        acc.push(video.brand.id);
      }
      return acc;
    }, []);
    let array: any = [];
    merchantByCategory.map((item: any, index: any) => {
      if (videoMerchants.includes(item?.merchantId)) {
        array.push(item);
      }
    });
    setMerchants(array);
  }, [merchantByCategory, getAllVideos]);

  function hasDuplicateBrand(id: any) {
    let brandArray = getAllVideos.flatMap((item: any) => item?.brand || []);

    const count = brandArray.filter(
      (merchant: any) => merchant.id === id.merchantId
    ).length;

    console.log(id, "merchantId");
    if (count > 1) {
      const formattedName = getUrlSlug(id.merchantInfo?.name);
      history.push(`/brands-category/${formattedName}/${id.merchantId}`);
    } else {
      let [video] = getAllVideos.filter(
        (item: any) => item?.brand.id === id.merchantId
      );
      const formattedName = getUrlSlug(video?.CreatorDetails?.Name); // Replace spaces with "-"
      const formattedTitle = getUrlSlug(video?.VideoTitle); // Replace spaces with "-"
      // history.push(`/video/${formattedName}/${formattedTitle}/${video?._id}`);
      history.push(`/${formattedName}/${formattedTitle}`);
    }
  }

  return (
    <ExploreByBrandStyled>
      <div className="explore-brand-header">
        <h2>Explore by Brand’s</h2>
        {merchants.length > 8 && (
          <span onClick={() => history.push("/brands")}>View All</span>
        )}
      </div>
      {!loading ? (
        <div className="explore-brand-cards">
          {merchants.slice(0, 8)?.map(
            (category: any) =>
              category.merchantInfo.name && (
                <div
                  key={`merchant_${category.merchantInfo.name}`}
                  className="category-card"
                >
                  <div>
                    <img
                      src={getBrandLogoUrl(
                        category?.merchantId,
                        category.merchantInfo?.logoUrl
                      )}
                      alt={category.merchantInfo.name || ""}
                      className="category-image"
                    />
                  </div>
                  <div
                    className="browse-button"
                    onClick={() => {
                      hasDuplicateBrand(category);
                    }}
                  >
                    Browse Creators <RightArrow />{" "}
                  </div>
                </div>
              )
          )}
        </div>
      ) : (
        <>{!isMobile && <ImageCardLoader />}</>
      )}
    </ExploreByBrandStyled>
  );
};

export default ExploreByBrand;
