import React from "react";
import { BlackButtonStyled } from "./BlackButtonStyled.styled";

interface BlackButtonProps {
  text: string;
  onClick: () => void;
}

const BlackButton: React.FC<BlackButtonProps> = ({ text, onClick }) => {
  return (
    <BlackButtonStyled>
      <button className="black-button" onClick={onClick}>
        {text}
      </button>
    </BlackButtonStyled>
  );
};

export default BlackButton;
