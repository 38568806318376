import ContentLoader from "react-content-loader";
import styled from "styled-components";

const ImageCardLoader = () => (
  <ImageCardSyled>
    <div className="grid grid-cols-4 gap-4">
      {[...Array(8)].map((_, index) => (
        <div key={index} className="p-4 border rounded-xl">
          <ContentLoader
            speed={2}
            width={250}
            height={200}
            viewBox="0 0 250 200"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            {/* Image Placeholder */}
            <rect x="0" y="0" rx="10" ry="10" width="250" height="120" />

            {/* Text Placeholder */}
            <rect x="60" y="140" rx="4" ry="4" width="130" height="20" />
            <rect x="80" y="170" rx="4" ry="4" width="90" height="15" />
          </ContentLoader>
        </div>
      ))}
    </div>
  </ImageCardSyled>
);

export default ImageCardLoader;

export const ImageCardSyled = styled.div`
  .grid-cols-4 {
    grid-template-columns: repeat(4, 1fr);
    display: grid;
    gap: 24px;
  }
`;
