import styled from "styled-components";

export const BlackButtonStyled = styled.div`
  .black-button {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: var(--lineheight24);
    letter-spacing: 0.002em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 8px 24px;
    border-radius: 60px;
    background: #000;
    color: #fff;
    border: none;
    text-transform: uppercase;
  }
  @media (max-width: 575.98px) {
    .black-button {
      font-size: 12px;
    }
  }
`;
