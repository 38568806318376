import { configureStore } from "@reduxjs/toolkit";

// reducers
import appReducer from "../slices/app/appSlice";
import authReducer from "../slices/auth/authSlice";
import adminReducer from "../slices/Admin/AdminSlice";
// import { persistStore, persistReducer } from 'redux-persist'
// @ts-ignore
// import storage from 'redux-persist/lib/storage' 

// const getConfig = (key: string, whitelist: any) => {
//   const persistConfig = {
//     key: key,
//     storage: storage,
//     whitelist,
//   };
//   return persistConfig;
// };

const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    // auth: persistReducer(getConfig("auth", ["user"]), authReducer),
    admin: adminReducer,
  },
});

// export const persistor = persistStore(store);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
