import styled from "styled-components";

export const UserProfileStyled = styled.div`
  .user-profile-container {
    display: grid;
    grid-template-columns: 24% 74%;
    column-gap: 2%;
    margin-bottom: 82px;
  }
  .account-setting-tab-sec {
    border-radius: 8px;
    border: 1px solid var(--Neutral-200, #dee1e3);
    background: #fff;
  }
  .acount-setting-tabs {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    cursor: pointer;
    .acc-set-label {
      display: flex;
      gap: 12px;
      align-items: center;
      color: var(--Shade-100, #000);
      font-family: "AvenirNextMedium";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .acount-setting-tabs.active {
    background: #faf1ff;

    .acc-set-label {
      color: var(--Videoshops-Red, #762da0);
      svg path {
        fill: #762da0;
      }
    }
    svg path {
      fill: #762da0;
    }
  }
  .profile-back-arrow {
    margin-top: 24px;
    display: flex;
    gap: 8px;
    align-items: center;
    color: var(--Black, #000);
    font-family: "AvenirNextMedium";
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }
  .profile-header {
    margin: 32px auto;

    h2 {
      color: var(--Shade-100, #000);
      font-family: "AvenirNextSemiBold";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px; /* 108.333% */
      margin-bottom: 14px;
    }
    p {
      color: var(--www-videoshops-com-black, var(--color-black-solid, #000));
      font-family: "AvenirNextRegular";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 144.444% */
      width: 434px;
    }
  }
  .account-sett-right-sec {
    border-radius: 8px;
    border: 1px solid var(--Neutral-200, #dee1e3);
    background: #fff;
    padding: 32px;
  }
  .account-settings-sec {
    h2 {
      color: var(--Shade-100, #000);
      font-family: "AvenirNextMedium";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 27px; /* 135% */
    }
  }
  .profile-image-sec {
    text-align: center;
    margin-top: 32px;
    display: flex;
    justify-content: center;
    .profile-img-div {
      position: relative;
      .profile-image {
        border-radius: 24px;
        border: 3px solid var(--Dark-Mode-Secondary-100, #858585);
        width: 80px;
        height: 80px;
        object-fit: cover;
      }
      .camera-icon {
        position: absolute;
        border-radius: 14px;
        background: #212121;
        padding: 8px;
        right: -14px;
        bottom: -12px;
        cursor: pointer;
      }
    }
  }
  .account-form {
    margin-top: 64px;
    row-gap: 16px;
  }
  .account-form .input_field {
    display: flex;
    flex-direction: column;

    label {
      color: var(--Shade-100, #000);
      font-family: "AvenirNextRegular";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
    }
    .email-input {
      color: var(--Neutral-400, #bfc5c9);
      font-family: "AvenirNextRegular";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
      width: 100%;
    }
    input {
      padding: 14px 16px;
      border-radius: 10px;
      color: var(--Shade-100, #000);
      font-family: "AvenirNextRegular";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      border: 1px solid #000;
      width: 100%;
    }
  }
  .account-form .ant-picker {
    padding: 0 12px;
    border-color: #000;
  }
  .account-form .ant-picker input {
    border: none;
  }
  .reset-pass-butt {
    margin-top: 32px;
    button {
      width: 100%;
      color: var(--Videoshops-Red, #762da0);
      font-family: "AvenirNextSemiBold";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.032px;
      border-radius: 15px;
      border: 1px solid var(--Videoshops-Red, #762da0);
      background: #fff;
      padding: 14px 0px;
    }
  }
  .save-changes-but {
    display: flex;
    justify-content: end;
    margin-top: 48px;
  }
  .reset-pass-butt::active,
  .reset-pass-butt::focus {
    background: #fff;
    color: var(--Videoshops-Red, #a83b1a);
    outline: none;
    box-shadow: none;
  }
  .purchases-body .purchases-header {
    color: var(--Shade-100, #000);
    font-family: "AvenirNextMedium";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px; /* 135% */
    margin-bottom: 20px;
  }
  .no-purchases-body {
    padding: 263px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      width: 300px;
      color: #4b5157;
      text-align: center;
      font-feature-settings: "liga" off, "clig" off;
      font-family: "AvenirNextRegular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .purchases-table {
    display: grid;
    grid-template-columns: 40% 25% 17% 18%;
    padding: 20px 24px;
    background: #f9f9f9;
    p {
      margin-bottom: 0px;
      color: var(--Shade-100, #000);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 100% */
    }
  }
  .purchases-data {
    display: grid;
    grid-template-columns: 40% 25% 17% 18%;
    border: 1px solid #eceeef;
    background: #fff;
    p {
      color: #193238;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 162.5% */
      margin-bottom: 0px;
      padding: 20px 0;
    }
    .product-name {
      padding-left: 24px;
    }
  }
  .purchases-results-text {
    color: var(--www-videoshops-com-black, var(--color-black-solid, #000));
    font-family: "AvenirNextRegular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  @media (max-width: 575.98px) {
    .mobile-acc-setting-header {
      box-shadow: 0px 3px 4px 0px #0000000a;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 12px 0px;
      cursor: pointer;
      svg {
        width: 24px;
        height: 24px;
      }
      h2 {
        font-family: "AvenirNextMedium";
        font-size: 16px;
        font-weight: 450;
        line-height: 19.6px;
        text-align: left;
        margin-bottom: 0px;
      }
    }
    .account-settings-sec h2 {
      margin-top: 16px;
    }
    .save-changes-but {
      margin-top: 40px;
      margin-bottom: 48px;
      .primary-button {
        border-radius: 12px;
        font-size: 14px;
        min-width: 173px;
        justify-content: center;
      }
    }
    .account-form .input_field {
      padding: 0px;
    }
    .no-purchases-body {
      padding: 130px 0px;
    }
    .terms-heading {
      margin-top: 20px;
    }
  }
`;
