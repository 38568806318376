import { useState } from "react";
import { FAQStyled } from "./FAQStyled.styled";
import { ReactComponent as AccordianOpen } from "../../images/accordian-plus.svg";
import { ReactComponent as AccordianClose } from "../../images/accordian-minus.svg";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <FAQStyled>
      <div className="faq-header">
        <div className="faq-header-sec">
          <h2>FAQ's</h2>
          <p>Still have questions? reach out to hello@onlydrop.com</p>
        </div>
        <div className="faq-questions">
          <ul>
            <li
              className="accordian-item"
              onClick={() => {
                handleToggle(0);
              }}
            >
              <h2 className="accordian-header">
                What is OnlyDrop?{" "}
                {activeIndex === 0 ? <AccordianClose /> : <AccordianOpen />}
              </h2>
              {activeIndex === 0 && (
                <div className="accordion-content">
                  {" "}
                  OnlyDrops is a marketplace where influencers showcase products
                  through engaging videos. Customers can watch, explore, and
                  shop directly from brand websites via shoppable links.
                </div>
              )}
            </li>
            <li
              onClick={() => {
                handleToggle(1);
              }}
            >
              <h2 className="accordian-header">
                How do I purchase a product I see in a video?{" "}
                {activeIndex === 1 ? <AccordianClose /> : <AccordianOpen />}
              </h2>
              {activeIndex === 1 && (
                <div className="accordion-content">
                  {" "}
                  Simply click on the product tagged in the video. You’ll be
                  redirected to the brand’s website, where you can complete your
                  purchase securely.
                </div>
              )}
            </li>
            <li
              onClick={() => {
                handleToggle(2);
              }}
            >
              <h2 className="accordian-header">
                Can I follow influencers on OnlyDrop?{" "}
                {activeIndex === 2 ? <AccordianClose /> : <AccordianOpen />}
              </h2>
              {activeIndex === 2 && (
                <div className="accordion-content">
                  Yes! You can follow your favorite influencers to stay updated
                  on their latest product reviews, recommendations, and live
                  shopping events.
                </div>
              )}
            </li>
            <li
              onClick={() => {
                handleToggle(3);
              }}
            >
              <h2 className="accordian-header">
                Is there a fee to use OnlyDrop?{" "}
                {activeIndex === 3 ? <AccordianClose /> : <AccordianOpen />}
              </h2>
              {activeIndex === 3 && (
                <div className="accordion-content">
                  {" "}
                  No, OnlyDrops is free for customers. You can browse, watch
                  videos, and shop without any additional charges.
                </div>
              )}
            </li>
            <li
              onClick={() => {
                handleToggle(4);
              }}
            >
              <h2 className="accordian-header">
                How can I join OnlyDrop as an influencer?{" "}
                {activeIndex === 4 ? <AccordianClose /> : <AccordianOpen />}
              </h2>
              {activeIndex === 4 && (
                <div className="accordion-content">
                  {" "}
                  Influencers can sign up on OnlyDrops, set up their profile,
                  and start creating shoppable videos by tagging products from
                  partnered brands.
                </div>
              )}
            </li>
            <li
              onClick={() => {
                handleToggle(5);
              }}
            >
              <h2 className="accordian-header">
                How do I add products to my video?{" "}
                {activeIndex === 5 ? <AccordianClose /> : <AccordianOpen />}
              </h2>
              {activeIndex === 5 && (
                <div className="accordion-content">
                  {" "}
                  While uploading your video, you’ll have the option to tag
                  products. This allows viewers to click on them and make
                  purchases directly from the brand’s site.
                </div>
              )}
            </li>
            <li
              onClick={() => {
                handleToggle(6);
              }}
            >
              <h2 className="accordian-header">
                Can I track the performance of my videos?{" "}
                {activeIndex === 6 ? <AccordianClose /> : <AccordianOpen />}
              </h2>
              {activeIndex === 6 && (
                <div className="accordion-content">
                  {" "}
                  Yes! Influencers have access to analytics, including views,
                  clicks, purchases, and earnings, helping them measure
                  engagement and sales impact.
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </FAQStyled>
  );
};

export default FAQ;
