import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import { useLocation } from "react-router-dom";
import HomePage from "../pages/HomePage/HomePage";
import VideoPdp from "../pages/VideoPdp/VideoPdp";
import Login from "../pages/Login/Login";

import UserProfile from "../pages/UserProfile/UserProfile";
import AboutUs from "../pages/FooterLinks/AboutUs";
import ContactUs from "../pages/FooterLinks/ContactUs";
import PrivacyPoicy from "../pages/FooterLinks/PrivacyPolicy";
import PrivacyPolicyProfile from "../pages/UserProfile/PrivacyPolicy";
import AccontSettings from "../pages/UserProfile/AccountSettings";
import MyPurchases from "../pages/UserProfile/MyPurchases";
import FAQS from "../pages/UserProfile/FAQ";
import TermsAndConditions from "../pages/UserProfile/TermsandConditions";
import Profile from "../pages/Profile/Profile";
import ComingSoon from "../pages/ComingSoon";
import Products from "../pages/Products/Products";
import Brands from "../pages/Brands";
import BrandVideo from "../pages/Brands/Video";
import BrandsCategory from "../pages/BrandsCategory/BrandsCategory";
import AllVideos from "../pages/AllVideos/AllVideos";

const MainRoutes: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const isComingtrue = false;

  return (
    <Switch>
      {isComingtrue ? (
        <Route path="*" component={ComingSoon} exact />
      ) : (
        <>
          <Route path="/" component={HomePage} exact />
          <Route exact path="/home" component={HomePage} />
          <Route path="/video/:brandName" component={BrandVideo} exact />
          <Route path="/video/:fullname/:name/:id" component={VideoPdp} exact />
          <Route path="/login" component={Login} exact />
          <Route path="/sign-up" component={Login} exact />
          <Route path="/profile/:id" component={Profile} exact />
          <Route path="/dashboard" component={Profile} exact />
          <Route path="/add-video" component={Profile} exact />
          <Route path="/user-profile" component={UserProfile} exact />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/account-setting" component={AccontSettings} />
          <Route path="/my-purchases" component={MyPurchases} />
          <Route path="/faq" component={FAQS} />
          <Route path="/terms-conditions" component={TermsAndConditions} />
          <Route path="/terms-conditions" component={TermsAndConditions} />
          <Route path="/privacy-policy" component={PrivacyPoicy} />
          <Route path="/privacypolicy" component={PrivacyPolicyProfile} />
          <Route path="/products/:id" component={Products} />
          <Route path="/brands" component={Brands} />
          <Route
            path="/brands-category/:brand/:id"
            component={BrandsCategory}
          />
          <Route path="/video-libray" component={AllVideos} />
          <Route path="/:fullname/:name" component={VideoPdp} exact />
        </>
      )}
    </Switch>
  );
};

export default MainRoutes;
