import React, { useEffect, useState } from "react";
import VideoCard from "../../TrendingSlides/VideoCard";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCategoriesByBrandsAPI,
  // getAllVideoByUserAPI,
} from "../../../redux/slices/app/appService";
import { useHistory } from "react-router-dom";
import { Input, Select, Space } from "antd";
import { SearchProps } from "antd/es/input";
// import { makeCategoryOptions } from "../../../Utills/utills";

const Videos: React.FC = () => {
  const { user } = useSelector((state: any) => state?.auth);
  const { userVideos = [], categoriesListByBrands } = useSelector(
    (state: any) => state?.app
  );
  const [activeTab, setActiveTab] = useState("Published");
  const [searchText, setSearchText] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [optionsList, setOptionList]: any = useState([]);
  const [categoryId, setCategoruId] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const { Search } = Input;
  useEffect(() => {
    fetchData("Publish");
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const tab = query.get("tab");
    if (tab) {
      setActiveTab(tab);
    }
    window.scroll({ top: 100, behavior: "smooth" });
  }, []);

  useEffect(() => {
    dispatch(getAllCategoriesByBrandsAPI({ ids: "" }));
  }, []);

  useEffect(() => {
    if (!searchText) {
      fetchData(activeTab === "Draft" ? "Draft" : "Publish");
    }
  }, [searchText]);

  useEffect(() => {
    fetchData(activeTab === "Published" ? "Publish" : "Draft");
  }, [activeTab]);

  // useEffect(() => {
  //   const list = [
  //     { label: "All", value: "all" },
  //     ...makeCategoryOptions(categoriesListByBrands),
  //   ];
  //   setOptionList(list);
  // }, [categoriesListByBrands]);

  useEffect(() => {
    let payload: any = {
      status: activeTab === "Draft" ? "Draft" : "Publish",
      searchText: searchText ? searchText : "",
      loginUserId: user?._id,
    };
    if (categoryId !== "all") {
      payload = {
        ...payload,
        category: categoryId,
      };
    }
    // dispatch(getAllVideoByUserAPI(payload));
  }, [categoryId]);

  const fetchData = async (st: string) => {
    const payload = {
      status: st ? st : "Publish",
      loginUserId: user?._id,
    };
    // await dispatch(getAllVideoByUserAPI(payload));
  };

  const onSearch: SearchProps["onSearch"] = async (
    value: any,
    _e: any,
    info: any
  ) => {
    const payload = {
      status: activeTab === "Draft" ? "Draft" : "Publish",
      searchText: value,
      loginUserId: user?._id,
    };
    // await dispatch(getAllVideoByUserAPI(payload));
  };

  return (
    <VideosStyled>
      <div className="top_section">
        <div className="tab-headers">
          <div
            className={`tab-header ${
              activeTab === "Published" ? "active" : ""
            }`}
            onClick={() => {
              setActiveTab("Published");
              history.push(`?tab=Published`);
            }}
          >
            Active
          </div>
          <div
            className={`tab-header ${activeTab === "Draft" ? "active" : ""}`}
            onClick={() => {
              setActiveTab("Draft");
              history.push(`?tab=Draft`);
            }}
          >
            Draft
          </div>
        </div>
        <div className="right_sec">
          <Space.Compact>
            <Search
              placeholder="What can we help you find?"
              // allowClear
              onChange={(e) => setSearchText(e.target.value)}
              onSearch={onSearch}
              value={searchText}
            />
          </Space.Compact>
          <div className="input_wrap">
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select a Category"
              optionFilterProp="label"
              // filterSort={(optionA: any, optionB: any) =>
              //   (optionA?.label ?? "")
              //     .toLowerCase()
              //     .localeCompare((optionB?.label ?? "").toLowerCase())
              // }
              options={optionsList}
              onChange={(val: any) => setCategoruId(val)}
            />
          </div>
        </div>
      </div>

      {userVideos?.length > 0 && (
        <div className="profile-videos-sec">
          {userVideos.map((video: any, index: number) => (
            <VideoCard key={index} video={video} profileVideo={true} />
          ))}
        </div>
      )}
      {userVideos?.length === 0 && (
        <div className="no_data_wrap">
          <div className="no_wrap_inner">
            {activeTab === "Published"
              ? `Looks like you haven’t published any videos yet. Upload your first video to get started.`
              : `No drafts saved yet. Start creating a video, and your progress will be saved here.`}
          </div>
        </div>
      )}
    </VideosStyled>
  );
};

export default Videos;

export const VideosStyled = styled.div`
  .profile-videos-sec {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 24px;
    margin-bottom: 112px;
  }
  .tab-headers {
    display: flex;
    border-bottom: 1px solid #ddd;
    margin-bottom: 30px;
    width: 30%;
  }

  .tab-header {
    flex: 1;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    color: #000;
    transition: background-color 0.3s;
  }

  .tab-header:hover {
    background-color: #f0f0f0;
  }

  .tab-header.active {
    color: var(--Videoshops-Purple, #762da0);
    border-bottom: 2px solid var(--Videoshops-Purple, #762da0);
  }
  .top_section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right_sec {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      .ant-input {
        color: var(--www-target-com-dove-gray, #666);
        font-family: "AvenirNextRegular";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
  }
  .ant-input-wrapper,
  .ant-input,
  .ant-btn {
    height: 40px;
  }
  .ant-space-compact {
    width: 400px;
  }
  .ant-input-group-wrapper .ant-input {
    border-start-start-radius: 40px;
    border-bottom-left-radius: 40px;
  }
  .ant-input {
    color: var(--www-target-com-dove-gray, #666);
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
  }
  .ant-btn {
    border-radius: 0 40px 40px 0 !important;
    width: 40px;
    border-left: 0px;
  }
  .ant-btn:hover {
    border-color: #d9d9d9 !important; /* Remove border color */
  }
  .ant-input {
    border-color: #d9d9d9 !important; /* Remove border color */
  }
  .ant-input-group-addon {
    background: transparent;
  }
  .input_wrap {
    height: 40px;
    .ant-select {
      height: 100%;
    }
    .ant-select-selector {
      background: #faf1ff;
      border-radius: 7px;
      border: 0px;
    }
  }
  .no_data_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 124px;
    padding-bottom: 150px;

    .no_wrap_inner {
      width: 27%;
      text-align: center;
      color: #4b5157;
      text-align: center;
      font-feature-settings: "liga" off, "clig" off;
      font-family: "AvenirNextRegular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
`;
