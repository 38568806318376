import styled from "styled-components";

export const BannerStyled = styled.div`
  .banner-sec {
    display: grid;
    grid-template-columns: 48% 48%;
    height: 514px;
    column-gap: 4%;
  }
  .banner-left-section {
    margin: auto;
    h1 {
      color: #000;
      font-family: "AvenirNextBold";
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }
    p {
      color: var(--videoshops-com-black, var(--color-black-solid, #000));
      font-family: "AvenirNextRegular";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 155.556% */
      margin-top: 16px;
    }
  }
  .banner-right-section {
    height: 514px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .banner-button {
    display: flex;
    gap: 24px;
    .primary-button {
      border-radius: 15px;
      min-width: 137px;
      color: var(--Shade-0, #fff);
      font-family: "AvenirNextSemiBold";
      background: #762da0;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
      letter-spacing: 0.032px;
      text-transform: uppercase;
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }
  @media (max-width: 575.98px) {
    .banner-sec {
      display: flex;
      height: auto;
      flex-direction: column-reverse;
      gap: 16px;
    }
    .banner-right-section {
      height: auto;
    }
    .banner-left-section {
      h1 {
        font-size: 32px;
        line-height: 43px;
      }
      p {
        font-size: 14px;
        line-height: 20px;
      }
      .primary-button {
        font-family: Helvetica Neue;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.002em;
        padding: 8px 24px;
        border-radius: 10px;
      }
    }
  }
`;
