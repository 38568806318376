import { useEffect, useState } from "react";
// import "./filtercanvas.css";
import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getProductByWishList } from "../../redux/slices/app/appService";
import { ReactComponent as DefaultImage } from "../../images/default-img.svg";
import { AddWishListModalStyled } from "../WishList/AddWishlistModalStyled.styled";
import WishListProducts from "./WishListProducts";
import { WishListProductsStyled } from "./WishListProductsStyled.styled";

const FilterCanvas = ({ show, setFilterShow }: any) => {
  const {
    auth: { user },
    app: { getWishList },
  } = useSelector((state: any) => state);

  const dispatch = useDispatch();

  const [productVideoData, setProductVideoData] = useState([]);
  const [updatedProducts, setUpdatedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showProducts, setShoProducts] = useState(false);
  const [title, setTitle] = useState("");
  const [wishlistId, setWishListId] = useState("");

  useEffect(() => {
    // Disable scrolling on the body when the menu is open
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    // Clean up on component unmount
    return () => {
      document.body.style.overflow = "";
    };
  }, [show]);

  useEffect(() => {}, [getWishList]); // Re-run if wishlists change

  const handlegetWislistProductArray = (item: any) => {
    setLoading(true);
    const extractedData = item.productIds.flatMap((product: any) =>
      Array.isArray(product)
        ? product.map((p) => ({
            productId: p.productId,
            videoId: p.videoId,
          }))
        : [
            {
              productId: product.productId,
              videoId: product.videoId,
            },
          ]
    );

    setProductVideoData(extractedData);
  };

  useEffect(() => {
    if (productVideoData?.length) {
      handleProductData();
    }
  }, [productVideoData]);

  const handleProductData = async () => {
    try {
      const productData = productVideoData.map((item: any) => ({
        productIds: item.productId,
        vendorId: user?._id,
        id: item.videoId,
      }));

      // Call API and wait for all responses
      await handleProductApiCall(productData);

      console.log("Product data dispatched and updated.");
    } catch (error) {
      console.error("Error dispatching product data:", error);
    } finally {
      setShoProducts(true);
      setLoading(false);
    }
  };

  const handleProductApiCall = async (productData: any) => {
    try {
      // Create an array of promises for each API call
      const promises = productData.map(async (item: any) => {
        const response: any = await dispatch(getProductByWishList(item));
        if (response?.payload?.products?.length) {
          return response?.payload?.products[0]; // Return the product if available
        }
        return null; // If no products are found, return null
      });

      // Wait for all promises to resolve
      const products = await Promise.all(promises);

      // Filter out any null values (if no products were found for some items)
      const filteredProducts: any = products.filter(
        (product) => product !== null
      );

      // Update state with the successfully fetched products
      setUpdatedProducts(filteredProducts);
    } catch (error) {
      console.error("Error during product API call:", error);
    }
  };

  console.log(showProducts, "setShoProducts");

  return (
    <>
      {show && (
        <div
          className="offcanvas-backdrop"
          onClick={() => setFilterShow(false)}
        ></div>
      )}
      <Drawer
        placement={"right"}
        width={400}
        onClose={() => {
          setFilterShow(!show);
        }}
        open={show}
        closeIcon={null}
        className="pro_filter_canvas"
      >
        <WishListProductsStyled>
          <AddWishListModalStyled>
            <header className="wishlist-header">
              <h2>Wishlist</h2>
            </header>
            {!showProducts && (
              <>
                {getWishList?.length ? (
                  <div className="wishlist-folder-section">
                    {/* <div
                  className="create-sec"
                  onClick={() => {
                    // setShowWishListModal(false);
                    // setShowCreatewishListModal(true);
                  }}
                >
                  <div className="image-container">
                    <AddBlackIcon />
                  </div>
                  <p>Create Wishlist</p>
                </div> */}
                    <div className="w-100">
                      {getWishList.map((item: any, index: number) => (
                        <div
                          key={index}
                          className="wishlist-list"
                          onClick={() => {
                            handlegetWislistProductArray(item);
                            setTitle(item?.wishlistName);
                            setWishListId(item?._id);
                          }}
                        >
                          <div className="wishlist-image-col">
                            <div className="image-container">
                              <DefaultImage />
                            </div>
                            <div className="wishlist-folder-sec">
                              <p>{item?.wishlistName}</p>
                              <span>{item?.productIds?.length} Products</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="no-wishlist-content">
                    <p>
                      Save the products you love for later! Add items to your
                      wishlist and access them here anytime.
                    </p>
                  </div>
                )}
              </>
            )}
            {showProducts && (
              <WishListProducts
                updatedProducts={updatedProducts}
                title={title}
                setShoProducts={setShoProducts}
                wishlistId={wishlistId}
              />
            )}
          </AddWishListModalStyled>
        </WishListProductsStyled>
      </Drawer>
    </>
  );
};

export default FilterCanvas;
