import { useEffect, useState } from "react";
import { ReactComponent as SendIcon } from "../../images/comments-send.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  createComments,
  GetAllComments,
} from "../../redux/slices/app/appService";
import Profile from "../../images/profiles/profile1.png.png";
import "./comments.css";
import DefaultProfileImage from "../../Utills/DefaultProfile";
import { getRelativeTime } from "../../Utills/utils";
import { useParams } from "react-router-dom";
const Comments = () => {
  const {
    auth: { user },
    app: { commmentsByVideoId },
  } = useSelector((state: any) => state);
  const [commentText, setCommentText] = useState("");
  const [loader, setLoader] = useState(false);

  const { id }: any = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    handlegetAllComments();
  }, []);

  const handleCommentSubmit = async () => {
    setLoader(true);
    if (commentText) {
      let josn = {
        videoId: id,
        userId: user?._id,
        text: commentText,
      };
      let res: any = await dispatch(createComments(josn));
      setCommentText("");
      if (res.payload) {
        handlegetAllComments();
      }
    }
    setLoader(false);
  };

  const handlegetAllComments = async () => {
    await dispatch(
      GetAllComments({
        id: id,
      })
    );
  };

  return (
    <>
      {commmentsByVideoId?.length > 0 ? (
        <div className="comments-body">
          {commmentsByVideoId?.map((item: any) => (
            <div className="comments-section">
              <div className="comment-profile">
                {item?.userDetails?.imageUrl ? (
                  <img src={item?.userDetails?.imageUrl} alt="profile" />
                ) : (
                  <DefaultProfileImage
                    userName={user?.userName}
                    fullName={user?.fullName}
                    email={user?.email}
                  />
                )}
                <div className="profile-name">
                  <p>
                    {item?.userDetails?.userName ||
                      item?.userDetails?.fullName ||
                      item?.userDetails?.email}
                  </p>
                  <span>{getRelativeTime(item?.timestamp)}</span>
                </div>
              </div>
              <p className="comment-text">{item?.text}</p>
              {item?.replies?.length > 0 ? (
                <>
                  <div className="reply-comment comment-profile">
                    {item?.replyUserDetails &&
                    item?.replyUserDetails[0]?.imageUrl ? (
                      <img
                        src={item?.replyUserDetails[0]?.imageUrl}
                        alt="profile"
                      />
                    ) : (
                      <DefaultProfileImage
                        userName={
                          item?.replyUserDetails &&
                          item?.replyUserDetails[0]?.userName
                        }
                        fullName={
                          item?.replyUserDetails &&
                          item?.replyUserDetails[0]?.userName?.fullName
                        }
                        email={
                          item?.replyUserDetails &&
                          item?.replyUserDetails[0]?.email
                        }
                      />
                    )}
                    <div className="profile-name">
                      <p>
                        {item?.replyUserDetails[0]?.userName ||
                          item?.replyUserDetails[0]?.fullName ||
                          item?.replyUserDetails[0]?.email}
                        <span className="influencer-text">Influencer</span>
                      </p>
                      <span>
                        {getRelativeTime(item?.replies[0]?.timestamp)}
                      </span>
                    </div>
                  </div>
                  <p className="comment-text ml-5">
                    <span className="reply-to-text">
                      {" "}
                      @
                      {item?.userDetails?.userName ||
                        item?.userDetails?.fullName ||
                        item?.userDetails?.email}
                    </span>
                    {item?.replies[0]?.text}
                  </p>
                </>
              ) : (
                <span className="reply-text" onClick={() => {}}>
                  Reply
                </span>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="no-comments-sec">
          <p>
            No comments yet!Be the first to share your thoughts or ask a
            question about this product. Your input matters!
          </p>
        </div>
      )}

      <div className="comments-send-section">
        <input
          value={commentText}
          type="text"
          placeholder="Write here..."
          onChange={(e: any) => {
            setCommentText(e.target.value.trimStart());
          }}
        />
        <div
          className="send-icon"
          onClick={() => {
            !loader && handleCommentSubmit();
          }}
        >
          <SendIcon />
        </div>
      </div>
    </>
  );
};

export default Comments;
