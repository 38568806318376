import styled from "styled-components";

export const ProfileStyled = styled.div`
  .cover-image-sec {
    position: relative;
    .cover-image-container {
      width: 100%;
      object-fit: cover;
    }
    .profile-image-container {
      position: absolute;
      bottom: -64px;
      left: 64px;
      width: 128px;
      height: 128px;
      border-radius: 112px;
      border: 6px solid var(--Neutral-300, #cfd3d7);
      background: url(<path-to-image>) lightgray -19.403px -2.715px / 118.139%
          177.208% no-repeat,
        url(<path-to-image>) lightgray 50% / cover no-repeat;

      img {
        width: 100%;
        height: 100%;
        border-radius: 112px;
        object-fit: cover;
      }

      .camera_icon_wrapper {
        position: absolute;
        top: 70px;
        height: 48px !important;
        width: 48px;
        border-radius: 18px;
        background: #212121;
        display: flex;
        justify-content: center;
        align-items: center;
        right: -20px;
        cursor: pointer;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .profile-details-sec {
    display: flex;
    margin-top: 32px;
    font-family: "AvenirNextRegular";
    justify-content: space-between;

    .profile-name {
      margin-top: 44px;
      h3 {
        font-size: 32px;
        font-weight: 600;
        line-height: 33px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin-bottom: 12px;
      }
      .email-text {
        font-size: 16px;
        font-weight: 400;
        line-height: var(--lineheight24);
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin-bottom: 24px;
        display: block;
      }
      .followers-sec {
        display: flex;
        align-items: center;
        gap: 10px;
        p {
          font-size: 20px;
          font-weight: 600;
          line-height: var(--lineheight24);
          text-align: center;
          margin-bottom: 0px;
        }
        span {
          font-size: 20px;
          font-weight: 500;
          line-height: var(--lineheight24);
          text-align: left;
          color: #7f8b93;
        }
      }
    }
  }
  .followers-product-sec {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
  }
  .follow-button-sec {
    display: flex;
    gap: 24px;
    align-items: center;
    margin-bottom: 56px;
    button {
      font-family: "AvenirNextSemiBold";
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.002em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      padding: 14px 32px;
      background: #a83b1a;
      border: none;
      border-radius: 34px;
      color: #fff;
      text-transform: uppercase;
    }
    img {
      border: 1px solid #dee1e3;
      border-radius: 50%;
      padding: 8px;
    }
  }
  .profile-connect-sec {
    display: flex;
    gap: 24px;
    align-items: center;
    height: fit-content;
    justify-content: start;
    margin-bottom: 24px;

    .share_wrap {
      border-radius: 21px;
      border: 1px solid var(--Neutral-200, #dee1e3);
      background: var(--Shade-0, #fff);
      width: 52px;
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .ant-btn {
      border-radius: 15px;
      background: var(--Videoshops-Purple, #762da0);
      width: 196px;
      height: 52px;
      color: var(
        --COSMETICS-ON-OFFERS-COLORS-White,
        var(--color-white-solid, #fff)
      );
      font-family: "AvenirNextBold";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
      letter-spacing: 0.032px;
    }
  }
  .profile-tabs {
    .ant-tabs-nav-list {
      padding-bottom: 12px;
    }
    .ant-tabs-tab {
      font-family: Helvetica Neue;
      font-size: 14px;
      font-weight: 500;
      line-height: var(--lineheight24);
      letter-spacing: 0.002em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
    .ant-tabs-tab-active {
      color: var(--Videoshops-Purple, #762da0);
      padding: 8px 24px;
      background: #faf1ff;
      border-bottom: none;
      border-radius: 34px;
      .ant-tabs-tab-btn {
        color: var(--Videoshops-Purple, #762da0) !important;
      }
    }
    .ant-tabs-ink-bar {
      display: none;
    }
  }
  .cover_image_wrapper {
    position: relative;
    height: 277px;
    object-fit: cover;
    .cover-image-container {
      width: 100%;
      height: 100%;
    }
    .cover_camera_icon {
      top: 20px;
      position: absolute;
      right: 20px;
      border-radius: 19px;
      background: rgba(0, 0, 0, 0.15);
      backdrop-filter: blur(4px);
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .latter_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #cf9593;
    border-radius: 50%;
    font-family: "AvenirNextBold";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }
`;
