import styled from "styled-components";

export const TopHeaderStyled = styled.div`
  .header-sec {
    display: flex;
    justify-content: space-between;
    padding: 24px 64px;
    align-items: center;
    box-shadow: 0px 3px 4px 0px #0000000a;
  }
  .logo-section {
    display: flex;
    align-items: center;
    gap: 80px;
    svg {
      cursor: pointer;
      path {
        fill: var(--Videoshops-Purple, #762da0);
      }
    }
  }
  .search-section {
    display: flex;
    align-items: center;
    gap: 24px;
  }
  .search-section.profile-icons {
    gap: 50px;
  }
  .search-input-wrapper {
    display: flex;
    align-items: center;
    width: 410px;
    height: 44px;
  }
  .search-input-wrapper input {
    border-radius: 60px;
    font-family: "AvenirNextRegular";
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    height: 44px;
    padding-left: 24px;
  }
  .search-input-wrapper input:focus {
    outline: none;
    box-shadow: none;
    border-color: #000000;
  }
  .search-input-wrapper svg {
    margin-left: -40px;
  }
  .logo-section span {
    color: var(--Error-Colour-400, #cc1433);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.028px;
    text-transform: uppercase;
    position: relative;
  }
  .logo-section span:before {
    content: " ";
    width: 8px;
    height: 8px;
    background-color: var(--Error-Colour-400, #cc1433);
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    left: -15px;
  }
  .sigin-up-text {
    color: var(--Shade-100, #000);
    font-family: "AvenirNextBold";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.028px;
    text-transform: uppercase;
    cursor: pointer;
  }
  .profile-icons-img svg {
    cursor: pointer;
  }
  .profile-icons-img img {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    object-fit: cover;
    cursor: pointer;
  }
  @media (max-width: 575.98px) {
    /* Styles for extra small devices */
    .header-sec {
      padding: 13px 12px 13px 16px;
    }
    .logo-section svg {
      width: 110px;
    }
    .mobile-search-icon {
      width: 26px;
      path {
        fill: #000;
      }
    }
    .search-section.profile-icons {
      gap: 24px;
    }
    .profile-icons-img .first-letter-text {
      width: 40px;
      height: 40px;
      padding: 8px;
    }
    .header-dropdown {
      width: 40px;
      height: 40px;
      padding: 0px;
      background: #fff;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .header-dropdown:focus,
    .header-dropdown:active,
    .dropdown.show .header-dropdown:focus,
    .dropdown.show .header-dropdown:active {
      background: #fff;
      border: none;
      box-shadow: none !important;
    }
    .dropdown.show > .btn-primary.dropdown-toggle {
      background: #fff;
      border: none;
    }
    .profile-icon-drp-menu {
      padding: 16px;
      width: 90vw;
      height: 100vh;
    }
    .menulist-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0px;
      font-family: "AvenirNextRegular";
      font-size: 16px;
      font-weight: 400;
      line-height: 19.6px;
      text-align: left;
      .menulist {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }
    .profile-icon-drp-menu h2 {
      font-family: "AvenirNextMedium";
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      text-align: left;
    }
    .profile-icon-drp-menu p {
      font-family: "AvenirNextRegular";
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
  }
`;
