import styled from "styled-components";

export const AddWishListModalStyled = styled.div`
  .wishlist-header {
    background: #faf1ff;
    h2 {
      color: #000;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 0px;
    }
  }

  .wishlist-body {
    padding: 68px 10px 120px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      color: #4b5157;
      text-align: center;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }

  .wishlist-items-body {
    align-items: start;
    padding: 24px 16px;
  }
  .create-sec {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    gap: 12px;
    cursor: pointer;
    .image-container {
      padding: 12px;
      border: 1px solid #eeeeee;
      border-radius: 12px;
    }
    p {
      font-family: "AvenirNextMedium";
      font-size: 14px;
      font-weight: 450;
      line-height: 17.15px;
      text-align: left;
      margin-bottom: 0px;
      color: #000;
    }
  }
  .wishlist-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .wishlist-image-col {
      display: flex;
      gap: 12px;
      align-items: center;

      .image-container {
        padding: 12px;
        border: 1px solid #eeeeee;
        border-radius: 12px;
      }
      .wishlist-folder-sec {
        p {
          margin-bottom: 0px;
          font-family: "AvenirNextMedium";
          font-size: 14px;
          font-weight: 450;
          line-height: 17.15px;
          text-align: left;
          color: #000000;
        }
        span {
          font-family: "AvenirNextRegular";
          font-size: 12px;
          font-weight: 400;
          line-height: 14.7px;
          text-align: left;
        }
      }
    }
    .wishlist-input-col {
      input {
        width: 24px;
        height: 24px;
      }
    }
  }
  .add-button {
    display: flex;
    justify-content: end;
    width: 100%;
    .primary-button {
      padding: 8px 16px;
      min-width: 150px;
      justify-content: center;
    }
    .primary-button:disabled {
      background: #eceeef;
      color: #bfc5c9;
    }
  }
  .add-button:disabled {
    background: #eceeef;
    color: #bfc5c9;
  }
`;
