import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const DropDownModalContainer = styled(Modal)`
  .ddwm-headerText {
    width: 100%;
  }
  .ddwm-headerText .mobile_buyer_pdp_dd_header {
    justify-content: space-between;
    margin-top: 15px;
  }

  .buyer_offer_header {
    justify-content: end;
  }
  .modal-content .accept-counter-offer-btn-sec .accept-btn {
    width: 100%;
    height: 48px;
    padding: 0px;
    background-color: #8a0f0f !important;
    color: #fff !important;
  }
  .modal-content .new-btns-action-click {
    gap: 0px;
    .counter-offer {
      width: 45% !important;
      min-width: 45% !important;
    }
    .decline {
      width: 45% !important;
      min-width: 45% !important;
    }
  }

  .byr_cntr_pop_pric_wrp label {
    margin-bottom: 10px;
  }
  .buyer_product_cnt_ofr .byr_cntr_pop_pric_wrp .input-price {
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
    color: #000 !important;
    text-align: center;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    box-shadow: none;
    border: 0px !important;
    border-bottom: 1px solid black !important;
  }
  .buyer_product_cnt_ofr .modal-body .total_amt_price {
    color: var(--Grey, #979797);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.8px;
    margin-top: 10px;
    text-align: center;
  }
  .buyer_product_cnt_ofr .modal-body .total_amt_price span {
    color: var(--Red-Colour, #8a0f0f);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.8px;
  }
  .buyer_product_cnt_ofr .seller_counter_amount_err {
    margin-top: 5px !important;
  }
  .buyer_product_cnt_ofr .mao_optional_msg_wrap {
    .textarea {
      width: 100%;
      height: 100px;
    }
  }
  .dropmodal_pro_mao_pdp {
    .offerreview {
      left: 40px;
    }
    .mobile_buyer_pdp_dd_header {
      .heading-section-offer-model {
        color: #000;
        font-family: Montserrat;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 129.412% */
      }
    }
  }

  .buyer_product_accept_ofr {
    .partision_row {
      margin-bottom: 10px;
      .partision_left {
        color: #333;
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 138.462% */
      }
      .accept-modal-values {
        color: #333;
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 133.333% */
      }
    }
  }

  .myOffer_mobl_dropdown {
    .ddwm-radio-option {
      input[type="radio"] {
        accent-color: #8a0f0f;
      }
      margin-bottom: 15px;
      span {
        color: var(--Gray-100, #19191d);
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 133.333% */
      }
    }
  }

  // Vehicle Make an offer Financial Verifcation

  .buyer_Vehicle_fncl_verify {
    .card-fields {
      .mobile-width-100 {
        width: 100% !important;
        padding-right: 0px !important;
        padding-left: 0px;
      }
    }
    .payment-sec-cont-info {
      margin-top: 15px;
      svg {
        width: 30px;
        height: 30px;
      }
    }
    .terms-sec-cont1 {
      .user_agreement_checkbox {
        width: 20px;
        height: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }

  // Vehicle Make An Offer popup
  .dropmodal_vehicle_mao_pdp {
    .heading-section-offer-model {
      color: #000;
      font-family: Montserrat;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 129.412% */
    }
  }
  .buyer_vicl_cnt_ofr {
    .offerform {
      .dollar-text {
        top: 31px;
      }
      .input-price {
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
        height: 44px;
        background: transparent;
      }
    }
  }

  // myOffer_mobl_dropdown

  .myOffer_mobl_dropdown {
    .ddwm-headerText {
      width: auto;
    }
  }

  // my sales filter model heading//
  .order-mobile-model .ddwm-headerText {
    width: auto;
  }
  .order-mobile-model .ddwm-radio-option {
    accent-color: #8a0f0f;
  }
  .order-mobile-model .ddwm-radio-option input[type="radio"] {
    width: 24px;
    height: 24px;
  }
  .dfs_down-filter-sec .ddwm-radio-option {
    gap: 8px;
  }
  // Seller Vehicle Make an offer

  .seller_side_cntr_offer {
    .counteroffer {
      p {
        color: #333;
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 138.462% */
        span {
          color: #333;
          font-family: Montserrat;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 133.333% */
        }
      }
    }
  }

  // Event Css

  .mobl_create_event_content {
    .event-image-sec-layout {
      width: 100% !important;
      height: 80px;
      border-radius: 4px;
      border: 1px dashed var(--Gray-100, #d9d9d9);
      background: var(--Gray-50, #f8f7fa);
      flex-wrap: wrap;
      justify-content: center;
      display: flex;
      padding: 0px;
      position: relative;
      .event-image-img {
        height: 100%;
        width: 100%;
        max-width: 100%;
        object-fit: cover;
      }
      .camera-icon-btn {
        position: absolute;
        right: 10px;
        top: 7px;
        background: #fff;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
      }
    }
    .first_row {
      width: 100%;
      width: 100% !important;
      margin: 0px !important;
      margin-top: 20px !important;
      .add_cover_text {
        position: relative;
      }
    }
    .modal-content-layout {
      border-radius: 0px !important;
      overflow: hidden;
      .add_cover_text {
        position: relative;
        top: 0px;
        width: 100%;
      }
    }
    .row {
      margin-left: 0px;
      margin-right: 0px;
    }
    .col-md-12 {
      width: 100% !important;
      padding: 0px;
      input {
        width: 100%;
      }
      .location-icon {
        top: 35px;
      }
    }
    .create-event-section {
      padding: 0px;
      width: 100% !important;
      input {
        width: 100% !important;
        padding: 8px;
      }
    }
    .date-time-sec {
      gap: 3%;
      .input {
        width: 100%;
      }
      .create-event-datesection {
        flex: 0 0 48%;
        max-width: 48%;
        position: relative;
      }
      .calendar-icon {
        right: 5px;
        top: 40px;
      }
      .time-icon {
        right: 7px;
        top: 42px;
      }
    }
    .label_event_type {
      padding: 0px;
      margin-bottom: 10px;
      position: relative;
    }
    .radio_wrp {
      width: 100% !important;
    }
    .checkbox-section-radio {
      padding: 0px;
      width: 50% !important;
      position: relative;
      input {
        left: 0px;
        top: 8px;
        width: 150px;
        height: 18px;
      }
      label {
        margin-bottom: 0px !important;
      }
    }
    .create-event-section {
      .textarea {
        width: 100%;
      }
      .content-length-section {
        right: 5px;
        bottom: -18px;
      }
    }
    .label {
      margin-bottom: 7px;
    }
    .react-datepicker-popper {
      position: absolute !important;
      left: -12px !important;
      top: -21px !important;
    }
    .custom_time_picker {
      .react-datepicker-popper {
        position: absolute !important;
        right: 0% !important;
        top: -21px !important;
        display: flex;
        justify-content: end;
      }
    }
    .react-datepicker {
      .react-datepicker__triangle::after {
        display: none;
      }
      .react-datepicker__triangle::before {
        display: none;
      }
    }
  }
  .social_share_popup {
    .share-icons-sec {
      width: 100%;
      display: flex;
      height: 45px;
      align-items: center;
      margin-bottom: 10px;
      img {
        margin-left: 13px;
        margin-right: 13px;
        width: 31px;
        height: 31px;
      }
      svg {
        margin-right: 10px;
      }
    }
  }
  .offers-brand-dropdown.dfs_down-filter-sec {
    .modal-dialog {
      height: 80vh;
    }
  }
`;
