import styled from "styled-components";

export const VideoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
  height: 100%;
  /* aspect-ratio: 1440/618; */
  background-color: black;
  .react-player {
    width: 100% !important;
    height: 100% !important;
    aspect-ratio: 1440/618;
  }
  .play-icon {
    position: absolute;
    cursor: pointer;
    width: 75px !important;
    height: 75px !important;
  }
  .spinner-border {
    position: absolute;
    cursor: pointer;
    width: 40px !important;
    height: 40px !important;
  }
  .progress {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 5px;
    border-radius: 0;
  }
  .progress .progress-bar {
    background-color: #8a0f0f !important;
  }
`;
